import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';

@Component({
  selector: 'app-challenge-filter-row',
  templateUrl: './challenge-filter-row.component.html',
  styleUrls: ['./challenge-filter-row.component.scss']
})
export class ChallengeFilterRowComponent implements OnInit, OnChanges {
  @Input() theme = 'light';
  @Input() challenges = [];
  @Input() selectedFilters: any = {};
  @Output() filtersChanged: EventEmitter<any> = new EventEmitter();
  availableFilters = [];
  constructor() { }

  ngOnChanges() {
    this.updateFilters();
  }
  ngOnInit(): void {
    this.updateFilters();
  }

  updateFilters() {
    this.availableFilters = [
      {
        filterType: 'experience',
        filterValue: 'All',
        showFilter: true,
        selected: Object.keys(this.selectedFilters).length === 0,
        mutallyExclusiveFilterValues: ['Fresher Friendly', 'Refactoring', 'Extension'],
        filter: {}
      },
      {
        filterType: 'experience',
        filterValue: 'Fresher Friendly',
        mutallyExclusiveFilterValues: ['All'],
        selected: this.selectedFilters?.targetedAt === 'FRESHERS',
        showFilter: this.challenges.filter(challenge => challenge?.targetedAt === 'FRESHERS').length,
        filter: { targetedAt: 'FRESHERS' }
      },
      {
        filterType: 'challengeType',
        filterValue: 'Refactoring',
        mutallyExclusiveFilterValues: ['All'],
        selected: this.selectedFilters?.problemMode === 'REFACTORING',
        showFilter: this.challenges.filter(challenge => challenge?.problemMode === 'REFACTORING').length,
        filter: { problemMode: 'REFACTORING' }
      },
      {
        filterType: 'challengeType',
        filterValue: 'Extension',
        mutallyExclusiveFilterValues: ['All'],
        selected: this.selectedFilters?.problemMode === 'EXTENSION',
        showFilter: false,
        // showFilter: this.challenges.filter(challenge => challenge?.problemMode === 'EXTENSION').length,
        filter: { problemMode: 'EXTENSION' }
      }
    ];
  }
  selectFilter(selectedFilter) {
    selectedFilter.selected = Boolean(!selectedFilter.selected);
    if (selectedFilter.mutallyExclusiveFilterValues.length) {
      this.availableFilters.forEach((filter) => {
        if (selectedFilter.mutallyExclusiveFilterValues.includes(filter.filterValue)) {
          filter.selected = false;
        }
      });
    }
    this.filtersChanged.emit(this.availableFilters.filter(filter => filter.selected).reduce((accumulatedFilters, currentFilter) => {
      return { ...accumulatedFilters, ...currentFilter.filter};
    }, {}));
    if (this.availableFilters.filter(filter => filter.selected).length === 0) {
      this.availableFilters.find(({filterValue}) => filterValue === 'All').selected = true;
    }
  }
}
