export const SOCIAL_MEDIA_HANDLE = {
    TWITTER: 'Twitter',
    LINKEDIN: 'LinkedIn',
    INSTAGRAM: 'Instagram',
    FACEBOOK: 'Facebook'
};

export const SOCIAL_MEDIA_ENTITY = {
    FOUNDER: 'Founder',
    INVESTOR: 'Investor',
    GEEKTRUST: 'Geektrust'
};
