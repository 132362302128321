import * as moment from 'moment';
import { UserProfile, WorkLifeExpectation, YrsOfExperience } from '../models/user-profile.model';
import { appendSelectedPropertyToOption } from './dropdown-updation.utility';
import { canFunctionBeReplacedWithAnother } from './function-replaceability-check.utility';
import { compose } from './synchronous-composer.utlity';
import { constructURLWithParameters } from './url.utility';

const getDateDiff = (startDate: number) => (endDate: number) => moment(endDate ?? new Date()).from(startDate ?? new Date(), true).replace('a few seconds', '1 month');

export const getDifferenceBetweenTwoDates = (startDate: number, endDate: number, present: boolean) => {
    return getDateDiff(startDate)(present ? Date.now() : endDate);
};

export const checkIfBasicDetailsExist = (userProfile: Partial<UserProfile>) => {
    const hasPrimarySkill = (userProfile?.primarySkill !== '');
    const hasFilledYearsOfExperience = Number.isInteger(userProfile?.yrsOfExperience?.month)
        || Number.isInteger(userProfile?.yrsOfExperience?.year);
    const hasAtleastOneWellKnownTechnology = (userProfile?.tech?.knowWell?.length >= 1);
    return hasPrimarySkill && hasFilledYearsOfExperience && hasAtleastOneWellKnownTechnology;
};

export const fomatYrsOfExperience = (yrsOfExperience: YrsOfExperience) => {
    return yrsOfExperience.year + (yrsOfExperience.month / 12).toFixed(2);
};

export const formatWorkLifeExpectations = (workLifeExpectations: WorkLifeExpectation[]) => {
    return workLifeExpectations.map((expectation) => {
        return expectation.text + ((expectation.values && expectation.values.length > 0) ? ' ' + expectation.values?.join(', ') : '');
    });
};

export {
    constructURLWithParameters,
    compose,
    canFunctionBeReplacedWithAnother,
    appendSelectedPropertyToOption
};

