import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GACategory } from '../../models/enums';
import { MixPanelService } from './mix-panel.service';

interface Event {
  eventName: string;
  properties: object;
}
@Injectable({
  providedIn: 'root',
})
export class UserActivityTrackingService {
  constructor(private mixpanelService: MixPanelService, private googleAnalyticsService: GoogleAnalyticsService) {}

  initializeMixPanel() {
    this.mixpanelService.init();
  }

  profileValueUnion(values) {
    this.mixpanelService.profileValueUnion(values);
  }
  identifyMixPanelUser(email: string, name: string) {
    this.mixpanelService.identify({ email, name });
  }

  trackMixPanelEvent(event: string, properties: any = {}) {
    this.mixpanelService.track(event, properties);
  }

  trackMixPanelEvents(events: Event[]) {
    events.forEach(({ eventName, properties = {} }) => {
      this.trackMixPanelEvent(eventName, properties || {});
    });
  }

  setMixPanelUserProfileCharacteristics(characteristics: any = {}) {
    this.mixpanelService.modifyUserProfile(characteristics);
  }

  setMixPanelUserProfileOnce(characteristics: any = {}) {
  this.mixpanelService.setOnceInProfile(characteristics);
  }

  registerMixPanelUserProfile(characteristics: any = {}) {
  this.mixpanelService.registerInUserProfile(characteristics);
}

  incrementMixPanelProperty(property) {
    this.mixpanelService.incrementUserProperty(property);
  }
  trackGAAppView({ screen, appName, appId, appVersion, installerId }) {
    this.googleAnalyticsService.appView(screen, appName, appId, appVersion, installerId);
  }

  trackGAEvent(action: string, category?: GACategory, label?: string, value?: number, interaction?: boolean): void {
    this.googleAnalyticsService.event(action, category, label, value, interaction);
  }

  trackGAException({ description, fatal }) {
    this.googleAnalyticsService.exception(description, fatal);
  }
  trackGTag(parameters: any[]) {
    this.googleAnalyticsService.gtag(parameters);
  }
  trackGAPageView({ path, title, location, options }) {
    this.googleAnalyticsService.pageView(path, title, location, options);
  }
  trackGASet(parameters: any[]) {
    this.googleAnalyticsService.set(parameters);
  }
  pageRedirectOnClick(eventname: string, fromPage: string) {
    this.trackMixPanelEvent(eventname, {
      FromPage: fromPage,
    });
  }
}
