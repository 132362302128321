import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private baseURL: string = environment.apiBaseURL;

  private httpOptions = {
    withCredentials: true,
    headers: new HttpHeaders({
      Accept: 'application/json'
    }),
  };

  private httpByPassOptions = {
    withCredentials: true,
    headers: new HttpHeaders({
      Accept: 'application/json',
       'ngsw-bypass': 'true'
    }),
  };

  constructor(private httpClient: HttpClient) {}

  get<T>(url: string): Observable<T> {
    return this.httpClient.get<T>(this.baseURL + url, this.httpByPassOptions);
  }

  post<T>(url: string, body: any): Observable<T> {
    return this.httpClient.post<T>(this.baseURL + url, body, this.httpByPassOptions);
  }

  postWithExtraOptions<T>(url: string, body: any, extraOptions: any = {}) : Observable<HttpEvent<T>>{
    return this.httpClient.post<T>(this.baseURL + url, body, { ...this.httpByPassOptions, ...extraOptions });
  }

  patch<T>(url: string, body: any): Observable<T> {
    return this.httpClient.patch<T>(this.baseURL + url, body, this.httpByPassOptions);
  }

  delete<T>(url: string): Observable<T> {
    return this.httpClient.delete<T>(this.baseURL + url, this.httpByPassOptions);
  }

  put<T>(url: string, body: any): Observable<T> {
    return this.httpClient.put<T>(this.baseURL + url, body, this.httpByPassOptions);
  }
}
