import { Directive, ElementRef, Input } from '@angular/core';
import * as moment from 'moment';

@Directive({
    selector: '[displayDifferenceBetweenDates]'
})
export class DifferenceBetweenDatesDirective {
    @Input() endingDate = '';
    @Input() startingDate = '';
    @Input() startCountDownBefore: { value: number, unit: moment.unitOfTime.Diff }= {
        unit: 'days',
        value: 2
    };
    intervalIDs = [];
    timeoutIDs = [];
    constructor(private el: ElementRef) {

    }
    ngOnInit() {
        const startCountDown = (moment(Date()).diff(new Date(this.startingDate), 'milliseconds') >= 0);
        const countDownHasNotEnded = (moment(new Date(this.endingDate)).diff(Date(), 'milliseconds') >= 0);
        if (startCountDown && countDownHasNotEnded) {
            const countDownThresholdReached = (moment(new Date(this.endingDate)).diff(new Date(), this.startCountDownBefore.unit) <= this.startCountDownBefore.value);
            if (countDownThresholdReached) {
                this.startCountDown();
            } else {
                this.el.nativeElement.textContent = `Ends in ${moment(new Date(this.endingDate)).diff(new Date(), this.startCountDownBefore.unit)} ${this.startCountDownBefore.unit}`;
                this.timeoutIDs.push(setTimeout(() => this.startCountDown(), moment(new Date(this.endingDate)).diff(new Date(), 'milliseconds')));
            }
        }
    }
    startCountDown() {
        let remainingTime = moment(new Date(this.endingDate)).diff(new Date(), 'milliseconds');
        this.intervalIDs.push(setInterval(() => {
            remainingTime -= 1000;
            this.el.nativeElement.textContent = this.convertMsToTime(remainingTime);
        }, 1000));
    }
    convertMsToTime(milliseconds) {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;
        const value =  `Ends in ${this.padTo2Digits(days)} days ${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)}:${this.padTo2Digits(
            seconds,
        )}`
        return value;
    }
    padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    ngOnDestroy() {
        this.timeoutIDs.forEach((timeoutID) => clearTimeout(timeoutID));
        this.timeoutIDs = [];
        this.intervalIDs.forEach((intervalID) => clearInterval(intervalID));
        this.intervalIDs = [];
    }
}
