import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DateDifferenceModule } from 'src/app/core/directives/dragula/directives/date-difference/date-difference.module';
import { ChallengeFilterRowComponent } from './challenge-filter-row/challenge-filter-row.component';
import { CodingChallengeCardLayoutComponent } from './coding-challenge-card-layout.component';
import { CodingChallengeCardComponent } from './coding-challenge-card/coding-challenge-card.component';

const MODULES_DECLARED_AND_USED_INTERNALLY = [];
const MODULES_DECLARED_AND_USED_GLOBALLY = [
CodingChallengeCardComponent, CodingChallengeCardLayoutComponent, ChallengeFilterRowComponent
];
@NgModule({
  imports: [CommonModule, RouterModule, NgxMasonryModule, NgxSkeletonLoaderModule, DateDifferenceModule],
  exports: [...MODULES_DECLARED_AND_USED_GLOBALLY],
  declarations: [...MODULES_DECLARED_AND_USED_GLOBALLY, ...MODULES_DECLARED_AND_USED_INTERNALLY],
})
export class CodingChallengeCardsModule { }
