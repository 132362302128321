import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as io from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { User } from '../models/user.model';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private socket: SocketIOClient.Socket;
  private loggedInUser: Partial<User>;

  constructor(private authService: AuthService) {
    this.socket = io(environment.socketServer);
    this.subscribe('DEVELOPER');
    this.authService.currentUser.subscribe((value) => {
      if (!value?.email && this.loggedInUser) {
        this.unsubscribe(this.loggedInUser.email);
        return;
      }
      if (value?.email) {
        this.loggedInUser = value;
        this.subscribe(this.loggedInUser.email);
      }
    });
  }

  listen(eventName: string): Observable<any> {
    return new Observable((subscriber) => {
      this.socket.off(eventName);
      this.socket.on(eventName, (email: string, data: any) => {
        subscriber.next({ email, eventDetails: data });
      });
    });
  }

  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }

  subscribe(email: string) {
    this.emit('join', email);
  }

  unsubscribe(email: string) {
    this.emit('leave', email);
  }

  off(eventName: string) {
    this.socket.off(eventName);
  }
}
