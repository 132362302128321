<div class="app-l-detailed-wrapper">
  <div class="app-credit-layout2">
    <div class="app-credit-name">
      This coding problem was created by
      <span class="creditProfilename">
        <a *ngIf="creditsForChallenge.contactLink" href={{creditsForChallenge.contactLink}}
          class="profileName">{{creditsForChallenge.name}}</a>
        <span *ngIf="!creditsForChallenge.contactLink" class="profileName">{{creditsForChallenge.name}}</span>
      </span>
    </div>
    <div class="creditProfileimg" *ngIf="creditsForChallenge.imgPath !== null">
      <img src={{creditsForChallenge.imgPath}} alt="" class="profileImg" />
    </div>
    <div class="profileName" *ngIf="creditsForChallenge.imgPath !== null">{{creditsForChallenge.name}}</div>
    <div class="ProfileDesgination" *ngIf="creditsForChallenge.designation !== null">{{creditsForChallenge.designation}}
    </div>
  </div>
  <div class="app-credit-layout1">

    <div class="app-credit-quote">
      <div class="credit-nameQuote">Three cheers to them!</div>
      If you wish to create a coding problem for us, do write to
      <span class="touch-mark">
        <span class="touchlink">help@geektrust.in</span>.
      </span>
    </div>
  </div>
</div>