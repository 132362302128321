import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PopoverService {

  constructor() { }
  closePopover(e){
    if (e.nativeElement){
      this.closePopoverFunction(e.nativeElement);
    } else {
      this.closePopoverFunction(e);
    }
  }
  closePopoverFunction(e){
    const elementId = e.id;
    document.getElementById(elementId).parentNode.removeChild(document.getElementById(elementId));
    document.querySelectorAll('.app-popover-backdrop')[0].parentNode.removeChild(document.querySelectorAll('.app-popover-backdrop')[0]);
    document.querySelector('body').classList.remove('popover-open');
  }
}
