import { Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';

@Directive({
  selector: '[ripple]',
})
export class RippleDirective implements OnInit{

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
   }
  ngOnInit() {

      //  const renderer = this.renderer;
      //  const nativeElement = this.el.nativeElement;

        // generating ripple wrapper
      //  const rippleWrapper = renderer.createElement('span');
      //  renderer.addClass(rippleWrapper, 'ripple-wrapper');
      //  renderer.appendChild(nativeElement, rippleWrapper);
      //   // generating ripple

      //  const rippleDiv = renderer.createElement('span');
      //  renderer.addClass(rippleDiv, 'ripple');
      //  renderer.addClass(rippleDiv, 'animation-paused');
      //  renderer.appendChild(rippleWrapper, rippleDiv);
      //  rippleWrapper.addEventListener('animationEnd', this.onAnimationEnd);
  }
  @HostListener('mousedown', ['$event']) mousedown(e) {

    // defining renderer and clicked element
    // const nativeElement = this.el.nativeElement;

    // e.currentTarget.classList.add('cdk-focused');

    // click position
    // const leftPos = e.clientX - nativeElement.getBoundingClientRect().left;
    // const topPos = e.clientY - nativeElement.getBoundingClientRect().top;
    // const ripple = e.currentTarget.querySelector('.ripple');

    // setting position
    // ripple.style.left = leftPos.toString().concat('px');
    // ripple.style.top = topPos.toString().concat('px');

    // if (ripple.classList.contains('animation-paused') !== -1){
    //   ripple.classList.remove('animation-paused');
    // }
  }
  @HostListener('blur', ['$event']) onBlur(e) {
  //  e.currentTarget.classList.remove('cdk-focused');
  }
  onAnimationEnd(event) {
    // const ripple = event.currentTarget.querySelector('.ripple');
    // ripple.classList.add('animation-paused');
  }
}
