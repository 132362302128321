import { Component, OnInit } from '@angular/core';
import { ToasterToggleService } from './toaster-toggle.service';
import {
  trigger,
  style,
  animate,
  transition,
  state,
} from '@angular/animations';
@Component({
  selector: 'app-toaster',
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
  animations: [
    trigger('trigger', [
      transition(
        ':enter',
        animate('0.4s',
          style({transform: 'translateY(0)', opacity: '1'})
        )
      ),
      transition(':leave', [
        animate('0.4s',
        style({transform: 'translateY(-30px)', opacity: '0'})
      )
      ])
    ])
  ]
})
export class ToasterComponent implements OnInit {

  toaster = [];
  constructor(ToastService: ToasterToggleService) {
    ToastService.toastProps.subscribe((el) => {
      if (Object.keys(el).length !== 0){
        this.toaster.push(el);
      }
    });
   }

  ngOnInit(): void {
  }
  closeToast(e, i){
    this.toaster.splice(i, 1);
  }
  displayEvent(i, event){
    if (this.toaster[i]){
      event.element.classList.add('stay-animation');
      if (this.toaster[i].dismiss){
          setTimeout(() => this.toaster.splice(i, 1), this.toaster[i].dismiss);
      }
    }
  }
}
