import { ALL_CHALLENGE_CONFIGURATIONS } from './../../../shared/data/json/coding-challenges/index';
import { ReplaySubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpService } from '../http.service';
import { buildCodingChallenges } from '../../adapters/coding-challenges/for-coding-card-layout.adapter';

@Injectable({
  providedIn: 'root'
})
export class CodingChallengesService {
  allProblems = [];
  liveProblems = [];
  retiredProblems = [];
  allProblemsSubject = new ReplaySubject();
  frontendCodingChallenges = new ReplaySubject();
  backendCodingChallenges = new ReplaySubject();
  mobileCodingChallenges = new ReplaySubject();
  allProblemsObservable$ = this.allProblemsSubject.asObservable();
  frontendCodingChallengesObservable$ = this.frontendCodingChallenges.asObservable();
  backendCodingChallengesObservable$ = this.backendCodingChallenges.asObservable();
  mobileCodingChallengesObservable$ = this.mobileCodingChallenges.asObservable();

  selectedProblemCode = '';
  selectedSlug = '';

  selectedProblemBySlug = new ReplaySubject();
  selectedProblemBySlugObservable$ = this.selectedProblemBySlug.asObservable();

  selectedProblemByProblemCode = new ReplaySubject();
  selectedProblemByProblemCodeObservable$ = this.selectedProblemByProblemCode.asObservable();

  constructor(private httpService: HttpService) {
    this.getAllProblems();
  }

  async getAllProblems() {
    return await this.httpService.get('v2/codingProblems').subscribe(response => {
      const allProblems = buildCodingChallenges(response, ALL_CHALLENGE_CONFIGURATIONS).sort((a, b) => b.displayOrder - a.displayOrder);
      this.allProblems = allProblems;
      this.allProblemsSubject.next(allProblems);
      const liveProblems  = allProblems.filter(challenge => challenge.liveStatus);
      this.liveProblems = liveProblems;
      this.retiredProblems = allProblems.filter(challenge => !challenge.liveStatus);
      this.frontendCodingChallenges.next(liveProblems.filter(({ type }) => type === 'FRONTEND'));
      this.backendCodingChallenges.next(liveProblems.filter(({ type }) => type === 'BACKEND'));
      this.mobileCodingChallenges.next(liveProblems.filter(({ type }) => type === 'MOBILE'));
      if (this.selectedSlug !== '') {
        const selectedProblem = this.allProblems.find((challenge) => challenge.slug === this.selectedSlug);
        this.selectedProblemBySlug.next(selectedProblem);
      }
      if (this.selectedProblemCode !== '') {
      const selectedProblem = this.allProblems.find((challenge) => challenge.problemId === this.selectedProblemCode);
      this.selectedProblemByProblemCode.next(selectedProblem);
    }
      return allProblems;
    });
  }

  getSpecificChallenge(challengeSlug) {
    this.selectedSlug = challengeSlug;
    if (this.allProblems.length) {
      const selectedProblem = this.allProblems.find((challenge) => challenge.slug === challengeSlug);
      this.selectedProblemBySlug.next(selectedProblem);
    }
    return this.selectedProblemBySlugObservable$;
  }

  getChallengeByProblemCode(problemCode) {
    this.selectedProblemCode = problemCode;
    if (this.allProblems.length) {
      const selectedProblem = this.allProblems.find((challenge) => challenge.problemId === problemCode);
      this.selectedProblemByProblemCode.next(selectedProblem);
    }
    return this.selectedProblemByProblemCodeObservable$;
  }

  getChallengesCurrentlyUnderObservation() {
    return this.allProblems.filter(
      (challenge) => challenge?.observationStatus?.isCurrentlyBeingWatchedByCompanies === true
    ).filter(problem => {
      return (new Date(problem.observationStatus.duration.startsOn).getTime() <= new Date().getTime())
        &&
        (new Date(problem.observationStatus.duration.endsBy).getTime() >= new Date().getTime());
    });
  }
}
