<div class="app-l-detailed-wrapper">
  <div class="app-sample-overviewLeft">
    <div class="app-sample-layout1">
      <ng-container *ngFor="let section of sampleInputOutput?.sampleSections">
        <h2 class="app-heading-h3" *ngIf="section.hasHeading">{{section.heading}}</h2>
        <div *ngFor="let sectionContent of section.content" class="Sample-Content">
          <h2 class="app-heading-h4" *ngIf="sectionContent.hasHeading">{{sectionContent.sectionHeading}}</h2>
          <p *ngIf="sectionContent.isText" [innerText]="sectionContent.textContent"></p>
          <div *ngIf="sectionContent.isTable" class="app-c-table--dynamic">
            <table class="app-c-table-problem-statement">
              <tr *ngFor="let dataRow of sectionContent.tableData" class="app-c-border-cstm">
                <ng-container *ngIf="dataRow.isHeaderRow">
                  <th *ngFor="let header of dataRow.data | keyvalue" width="">{{header.value}}</th>
                </ng-container>
                <ng-container *ngIf="dataRow.isDataRow">
                  <td *ngFor="let data of dataRow.data | keyvalue">{{data.value}}</td>
                </ng-container>
                <ng-container *ngIf="dataRow.isSpacerRow">
                  <td class="less-padding" colspan="5">&nbsp;</td>
                </ng-container>
              </tr>
            </table>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>