const SOCIAL_MEDIA_SHARE_URLS = {
    TWITTER: {
        BASE_URL: 'https://twitter.com/share',
        FIXED_PARAMS: {
            utm_content: 'eval_social',
            utm_source: 'twitter',
            utm_medium: 'social',
            utm_campaign: 'coding_eval_share_email'
        },
        DYNAMIC_PARAMS: {
            url: 'gtShareURL',
            text: 'ogImageDesc'
        }
    },
    FACEBOOK: {
        BASE_URL: 'http://www.facebook.com/sharer.php',
        FIXED_PARAMS: {
            utm_content: 'eval_social',
            utm_source: 'twitter',
            utm_medium: 'social',
            utm_campaign: 'coding_eval_share_email'
        },
        DYNAMIC_PARAMS: {
            u: 'gtShareURL',
            quote: 'ogImageDesc'
        }
    },
    LINKED_IN: {
        BASE_URL: 'http://www.linkedin.com/sharing/share-offsite/',
        FIXED_PARAMS: {
            url: '',
            utm_content: 'eval_social',
            utm_source: 'twitter',
            utm_medium: 'social',
            utm_campaign: 'coding_eval_share_email'
        },
        DYNAMIC_PARAMS: {
            url: 'gtShareURL',
            title: 'ogImageTitle',
            summary: 'ogImageDesc'
        }
    }
};

export {
    SOCIAL_MEDIA_SHARE_URLS
};
