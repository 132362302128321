import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare let fbq: any;

@Injectable({
  providedIn: 'root',
})
export class FacebookPixelService {
  constructor() {}

  init(): void {
    if (!environment.production) {
      return;
    }
    if (typeof fbq !== 'undefined') {
      return;
    }
    const fbqScript = document.createElement('script');
    fbqScript.innerHTML = `!function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '${environment.facebookMetaPixelId}');
    fbq('track', 'PageView');`;
    document.head.prepend(fbqScript);

    const fbqNoScript = document.createElement('noscript');
    fbqNoScript.innerHTML = `<img height="1" width="1" style="display:none"
     src="https://www.facebook.com/tr?id=${environment.facebookMetaPixelId}&ev=PageView&noscript=1"/>`;
    document.body.prepend(fbqNoScript);
  }

  track(event: string, category: string): void {
    if (!environment.production || typeof fbq === 'undefined') {
      return;
    }
    fbq('trackCustom', event, { content_category: category });
  }
}

export const FA_EVENTS = {
  SIGNUP: 'CompleteRegistration',
  OFFLINE: 'AddToCart',
  PROFILE_UPDATE: 'Updated user profile',
  APPLY_JOB: 'SubmitApplication',
  EVENT_REGISTER: 'Registered for event',
  APPLY_ACCESS_SUBMIT: 'Lead',
  APPLY_RECRUITMENT_SUBMIT: 'Recruitment Lead',
};
