<div class="eval-detail-container">
    <div class="eval-detail">
        Coding challenge solved
        <h3 class="eval-detail-value">
            <a href={{report.codingProblemUrl}} target="_blank">{{report.codingProblemName}}</a>
        </h3>
    </div>
    <div class="eval-detail">
        Language
        <h3 class="eval-detail-value">
            {{report.language | titlecase}}
        </h3>
    </div>
</div>

<app-code-report-summary [summary]="summary"></app-code-report-summary>

<ng-container *ngIf="isVerbosityPresent; else noVerbosityReport">
    <div class="badges-section" *ngIf="report.earnedBadges.length">
        <div class="report-section-title">
            EARNED BADGES ({{report.earnedBadges.length}})
        </div>
        <app-code-report-badge *ngFor="let badge of report.earnedBadges" [badgeItem]="badge" earned="true">
        </app-code-report-badge>
    </div>

    <div class="badges-section" *ngIf="report.unEarnedBadges.length">
        <div class="report-section-title">
            UNEARNED BADGES ({{report.unEarnedBadges.length}})
        </div>
        <app-code-report-badge *ngFor="let badge of report.unEarnedBadges" [badgeItem]="badge" earned="false">
        </app-code-report-badge>
    </div>
</ng-container>

<ng-template #noVerbosityReport>
    <app-no-verbosity-report [report]="report"></app-no-verbosity-report>
</ng-template>