<div [ngClass]="{'dark-theme': theme === 'dark'}" class="app-c-card__item" (click)="goToChallenge(item)">
    <div class="app-c-card-inner">
        <div class="app-c-mv-wrapper" [ngClass]="{
        'background-1': (index % 5) === 1,
        'background-2': (index % 5) === 2,
        'background-3': (index % 5) === 3,
        'background-4': (index % 5) === 4,
        'background-5': (index % 5) === 0
    }">
            <div class="app-c-card-header">
                <div class="app-c-logo-wrap transparent-bg">
                    <img [src]="item.iconPath" />
                </div>
            </div>
            <div class="app-c-header-wrap">
                <div class="problem-metadata-container">

                    <div class="problem-complexity-container">
                        <div class="problem-complexity-desc-{{item.difficultyLevel.toLowerCase()}}"
                            *ngIf="item.difficultyLevel === 'EASY'">
                            {{item.difficultyLevel}}
                            &nbsp;&nbsp;
                            {{item.problemDifficultyEmoji}}
                        </div>
                    </div>
                    <div class="problem-complexity-container">
                        <div class="problem-complexity-desc-{{item.problemMode.toLowerCase()}}"
                            *ngIf="item.problemMode === 'REFACTORING'">
                            {{item.problemMode | titlecase}}
                            &nbsp;&nbsp;
                            {{item.problemModeEmoji}}
                        </div>
                    </div>
                </div>
                <h3 class="app-c-card__title-main">
                    {{ item.title }}
                </h3>
            </div>
        </div>
        <div class="app-c-card-body">
            <div class="app-c-decription-container">
                <div class="app-c-card-content">
                    <div class="app-c-card__item-cntnt">
                        <p class="app-c-descrptn">
                            {{item.description}}
                        </p>
                    </div>
                </div>
            </div>
            <div class="app-c-watching-container"
                *ngIf="showCompaniesWatching && item?.observationStatus?.isCurrentlyBeingWatchedByCompanies">
                <div class="app-c-watching-head">
                    <span class="app-c-title">Companies watching</span>

                </div>
                <div class="app-c-watching-body">
                    <div *ngFor="let company of item.observationStatus.watchedBy; let i=index"
                        class="app-c-vanishing-logos-{{i}} app-c-logo-wrap">
                        <img [src]="company.logoURL" [alt]="company.name" />
                    </div>
                </div>
                <div>
                    <span class="app-c-count" displayDifferenceBetweenDates
                        [endingDate]="item.observationStatus.duration.endsBy"
                        [startingDate]="item.observationStatus.duration.startsOn"
                        [startCountDownBefore]="item.observationStatus.duration.startCountDownBy"></span>

                </div>
            </div>
            <div [ngClass]="{'has-developer-count': item.solvedCount > minimumSolvedCount}"
                class="app-c-metadata-container">
                <div class="app-c-card__badges-and-time-taken">
                    <div class="badges">
                        <div class="icon">
                            <img
                                src="https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/v2/problems/common/badges-earned.webp" />
                        </div>
                        <div class="text">
                            {{item?.badgeCodes?.length}} badges
                        </div>
                    </div>
                    <div class="time-taken" *ngIf="item.timeToSolve && item.timeToSolve <= 3">
                        <div class="icon">
                            <img
                                src="https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/v2/problems/common/time-to-solve.webp" />
                        </div>
                        <div class="text">
                            ~ {{item.timeToSolve | number: 2.0-2}} hours
                        </div>
                    </div>
                </div>
            </div>
            <div class="app-developer-count-container" *ngIf="item.solvedCount > minimumSolvedCount">
                <div class="images">
                    <img *ngFor="let item of developerImages" [src]="item" />
                </div>
                Solved by {{item.solvedCount}}+ developers
            </div>
        </div>
        <div class="app-c-card-footer">
            <div class="app-c-hover-show__footer">
                <button class="app-btn app-btn-lg app-btn-primary" (click)="goToChallenge(item)" ripple>Solve</button>
            </div>
        </div>
    </div>
</div>