export const seoSitemap: SitemapTag[] = [
  {
    customUrl: '/jobs',
    title: 'Developer Jobs at Global Enterprises, Product Companies, Startups',
    topic: 'Companies Hiring Now',
    description: `See the top companies hiring now across domains, open roles, tech stack, what's interesting, culture and everything else you need to know here.`,
  },
  {
    customUrl: '/code-challenges',
    title: 'Build your code portfolio: Geektrust Coding Challenges',
    topic: 'Coding Challenges',
    description:
      'Code is the new resume. 100+ companies hire for 1000+ roles from Junior Developers to Heads of Engineering through their code portfolio. Build yours here.',
  },
  {
    customUrl: '/about-us',
    title: `About us | We're solving the tech recruitment problem`,
    topic: 'About Geektrust',
    description: `We are believers in human potential and capability. Read all about how we started, what we believe in, where that's taken us and more here.`,
  },
  {
    customUrl: '/offerings',
    title: 'Our products, services and offerings | Geektrust',
    description: null,
    topic: null
  },
];

export interface SitemapTag {
  customUrl: string;
  title: string | null;
  description: string | null;
  topic: string | null;
}
