export enum APIStatus {
  none = 'none',
  success = 'success',
  failure = 'failure',
  progress = 'progress',
  lazyLoading = 'lazyLoading',
}

export enum JobSearchStatus {
  Active = 'ACTIVE',
  Passive = 'PASSIVE',
  Inactive = 'INACTIVE',
}

export enum RequirementType {
  // ALL='ALL',
  BACKEND = 'BACKEND',
  FRONTEND = 'FRONTEND',
  FULLSTACK = 'FULLSTACK',
  MOBILE = 'MOBILE',
  DESIGN = 'DESIGN',
  DEVOPS = 'DEVOPS',
  QA = 'QA',
  PRODUCT = 'PRODUCT',
  DATASCIENCE = 'DATASCIENCE',
  DATAENGINEER = 'DATAENGINEER',
}

export enum RequirementTypeWebsite {
  // ALL='ALL',
  BACKEND = 'BACKEND',
  FRONTEND = 'FRONTEND',
  FULLSTACK = 'FULLSTACK',
  MOBILE = 'MOBILE',
  DEVOPS = 'DEVOPS',
  QA = 'QA',
  PRODUCT = 'PRODUCT',
  DATASCIENCE = 'DATASCIENCE',
  DATAENGINEER = 'DATAENGINEER',
}

export enum Language {
  JAVA = 'Java',
  PYTHON = 'Python',
  GO = 'Go',
  NODEJS = 'NodeJS',
  CSHARP = 'CSharp',
  RUBY = 'Ruby',
}

export enum ActionType {
  AskToWriteCode = 'ASK_TO_WRITE_CODE',
  UploadResume = 'UPLOAD_RESUME',
  UpdateSalary = 'UPDATE_SALARY',
  UpdateNoticePeriod = 'UPDATE_NOTICE_PERIOD',
}

export const RoleTypeFilterLabelMapping: Record<RequirementType, string> = {
  [RequirementType.BACKEND]: 'Backend',
  [RequirementType.FRONTEND]: 'Frontend',
  [RequirementType.FULLSTACK]: 'Fullstack',
  [RequirementType.MOBILE]: 'Mobile',
  [RequirementType.DESIGN]: 'UI/UX Design',
  [RequirementType.DEVOPS]: 'DevOps',
  [RequirementType.QA]: 'QA',
  [RequirementType.PRODUCT]: 'Product',
  [RequirementType.DATASCIENCE]: 'Data Science',
  [RequirementType.DATAENGINEER]: 'Data Engineering',
};

export const ActionTypeLabelMapping: Record<ActionType, string[]> = {
  [ActionType.AskToWriteCode]: [
    'This company requires you to take up a coding challenge',
    'View challenges',
    '/coding/challenges',
  ],
  [ActionType.UploadResume]: ['Please upload your resume', 'Upload Resume', 'profile/basicdetail'],
  [ActionType.UpdateSalary]: ['Please update your salary', 'Update Salary', '/profile/expectations'],
  [ActionType.UpdateNoticePeriod]: [
    'Please enter your notice period details',
    'Update Notice Period',
    '/profile/expectations',
  ],
};

export enum CompanyType {
  ProductStartup = 'Product Startup',
  ProductCompany = 'Product Company',
  ServicesStartup = 'Services Startup',
  ServicesCompany = 'Services Company',
}

export enum CompanySize {
  Size_1_10 = '1-10',
  Size_11_50 = '11-50',
  Size_50_200 = '51-200',
  Size_201_500 = '201-500',
  Size_501_1000 = '501-1000',
  Size_1001_5000 = '1001-5000',
  Size_5001_to_10000 = '5001-10000',
  Size_10000_Plus = '10000+',
}

export const JoblabelMapping: Record<RequirementType, string> = {
  [RequirementType.BACKEND]: 'Backend Engineer',
  [RequirementType.FRONTEND]: 'Frontend Developer',
  [RequirementType.FULLSTACK]: 'Fullstack Developer',
  [RequirementType.MOBILE]: 'Mobile Developer',
  [RequirementType.DATASCIENCE]: 'Data Scientist',
  [RequirementType.DATAENGINEER]: 'Data Engineer',
  [RequirementType.DESIGN]: 'UI/UX Designer',
  [RequirementType.DEVOPS]: 'DevOps Engineer',
  [RequirementType.QA]: 'QA Engineer',
  [RequirementType.PRODUCT]: 'Product Manager',
};

export enum SocketIOEvents {
  CodeSubmissionRun = 'codeSubmissionRunCompleted',
  ReadabilityCheck = 'readabilityCheckCompleted',
  OopsCheck = 'oopsCheckCompleted',
  BuildEvaluation = 'buildEvaluationCompleted',
  UnitTestsExecution = 'unittestsExecutionCompleted',
  MaintainabilityCheck = 'maintainabilityCheckCompleted',
  CompanyConnectionModified = 'companyConnectionModified',
  EvaluationModified = 'evaluationModified',
  EvaluationIncomplete = 'evaluationIncomplete',
  UIReadablityCheck = 'uiReadabilityCheckCompleted',
  UIModularityCheck = 'uiModularityCheckCompleted',
  UIExtensibilityCheck = 'uiExtensibilityCheckCompleted',
  UIBuildCheck = 'uiBuildCheckCompleted',
  UICodeSubmissionRun = 'uiCodeSubmissionRunCompleted',
  UIMobileFriendlinessCheck = 'uiMobileFriendlinessCheckCompleted',
}
export enum ConnectionStatus {
  CONNECT_INITIATED = 'CONNECT-INITIATED',
  CONNECT_REJECTED = 'CONNECT-REJECTED',
  CONNECT_APPLY_FAILED = 'CONNECT-APPLY-FAILED',
  CONNECT_WITHDRAWN = 'CONNECT-WITHDRAWN',
  CONNECT_ADMIN_DELETED = 'CONNECT-ADMIN-DELETED',
  COMPANY_CODE_REVIEW = 'COMPANY-CODE-REVIEW',
  INTERVIEW_INITIATED = 'INTERVIEW-INITIATED',
  INTERVIEW_FAILED = 'INTERVIEW-FAILED',
  INTERVIEW_PASSED = 'INTERVIEW-PASSED',
  INTERVIEW_WITHDRAWN = 'INTERVIEW-WITHDRAWN',
  OFFER_INITIATED = 'OFFER-INITIATED',
  OFFER_APPROVED = 'OFFER-APPROVED',
  OFFER_ACCEPTED = 'OFFER-ACCEPTED',
  OFFER_REJECTED = 'OFFER-REJECTED',
  OFFER_WITHDRAWN = 'OFFER-WITHDRAWN',
  DEVELOPER_JOINED = 'DEVELOPER-JOINED',
  DEVELOPER_DID_NOT_JOIN = 'DEVELOPER-DID-NOT-JOIN',
}

export enum SignupVia {
  Normal = 'NORMAL',
  Refer = 'REFER',
  Home = 'HOME',
  Jobs = 'JOBS',
  Company = 'COMPANY',
  Events = 'EVENTS',
  CP = 'CP',
  Offering = 'OFFERING',
  Recruiter = 'RECRUITER',
  About = 'ABOUT',
}

export const PageSignupViaMapping = [
  // more specific one should come first eg: /jobs/ before /jobs
  { url: '/jobs/', trackBy: SignupVia.Company },
  { url: '/jobs', trackBy: SignupVia.Jobs },
  { url: '/events', trackBy: SignupVia.Events },
  { url: '/code-challenges', trackBy: SignupVia.CP },
  { url: '/challenge', trackBy: SignupVia.CP },
  { url: '/offerings', trackBy: SignupVia.Offering },
  { url: '/hire', trackBy: SignupVia.Recruiter },
  { url: '/about-us', trackBy: SignupVia.About },
];

export enum GACategory {
  SignUp = 'Signup',
  Login = 'Login',
  Logout = 'Logout',
  UserProfile = 'UserProfile',
  Job = 'Job',
  EventRegistration = 'Event Registration',
  CodeSubmission = 'Code Submission',
  Hire = 'Hire',
  Recruitment = 'Recruitment',
}

export enum ApplyJobStatus {
  fail = 'fail',
  profileUpdate = 'profile-update',
  alreadyApplied = 'already-applied',
}

export enum VerbosityCriteria {
  Readability = 'READABILITY',
  OOPS = 'OOPS',
  UnitTests = 'UNITTESTS',
  Build = 'BUILD',
  IO = 'IO',
  Maintainability = 'MAINTAINABILITY',
  UIReadability = 'UIREADABILITY',
  UIModularity = 'UIMODULARITY',
  UIExtensibility = 'UIEXTENSIBILITY',
  UIBuild = 'UIBUILD',
  UIIO = 'UIIO',
  UIMobileFriendliness = 'UIMOBILEFRIENDLINESS',
}

export enum ConnectionState {
  None = '',
  InProgress = 'IN_PROGRESS',
  Withdrawn = 'WITHDRAWN',
  Rejected = 'REJECTED',
}
export enum CookieConsentChoice {
  Allow = 'allow',
  Deny = 'deny',
}

export enum CodeSubmissionResultSubCriteria {
  Encapsulation = 'ENCAPSULATION',
  CompactMethods = 'COMPACT_METHODS',
  MagicNumbers = 'MAGIC_NUMBERS',
  ExpressiveCode = 'EXPRESSIVE_CODE',
  MethodComplexity = 'METHOD_COMPLEXITY',
  DRY = 'DRY',
}

export const SubcriteriaLabelMapping: Record<CodeSubmissionResultSubCriteria, string> = {
  [CodeSubmissionResultSubCriteria.Encapsulation]: 'Encapsulation',
  [CodeSubmissionResultSubCriteria.CompactMethods]: 'Compact Methods',
  [CodeSubmissionResultSubCriteria.MagicNumbers]: 'Magic Numbers',
  [CodeSubmissionResultSubCriteria.ExpressiveCode]: 'Expressive Code',
  [CodeSubmissionResultSubCriteria.MethodComplexity]: 'Method Complexity',
  [CodeSubmissionResultSubCriteria.DRY]: 'DRY',
};

export const LanguageBuildTools: Record<string, any[]> = {
  [Language.JAVA]: [
    { buildTool: 'Maven', fileName: 'java-maven.zip' },
    { buildTool: 'Gradle', fileName: 'java-gradle.zip' },
  ],
  [Language.PYTHON]: [{ buildTool: 'Pip', fileName: 'python-pip.zip' }],
  [Language.GO]: [{ buildTool: 'Go Tool', fileName: 'go-gotool.zip' }],
  [Language.CSHARP]: [{ buildTool: 'Dotnet', fileName: 'csharp-dotnet.zip' }],
  [Language.NODEJS]: [
    { buildTool: 'Npm', fileName: 'nodejs-npm.zip' },
    { buildTool: 'Yarn', fileName: 'nodejs-yarn.zip' },
  ],
  [Language.RUBY]: [
    { buildTool: 'Bundler', fileName: 'ruby-bundler.zip' },
    { buildTool: 'Bundler Rake', fileName: 'ruby-bundler-rake.zip' },
    { buildTool: 'Rake', fileName: 'ruby-rake.zip' },
  ],
};

export enum GTInterviewStatus {
  ClearedInterview = 'Cleared Interview',
  AverageInterview = 'Average Interview',
  NotCleared = 'Not Cleared',
}

export enum GTInterviewType {
  CodePairing = 'Code Pairing',
  TechnicalRound = 'Technical Round',
  OneToOneCodeRound = '1-1 Code Round',
}

export enum GTInterviewRound {
  Round1 = 'Round 1',
  Round2 = 'Round 2',
  Round3 = 'Round 3',
  ClientRound = 'Client Round',
}

export enum PrimarySkill {
  BackendDevelopment = 'Backend Development',
  Design = 'Design',
  DevOps = 'DevOps',
  FrontendDevelopment = 'Frontend Development',
  FullStackDevelopment = 'Full Stack Development',
  MobileAppDevelopment = 'Mobile App Development',
  ProductDevelopment = 'Product Development',
  ProductManagement = 'Product Management',
  UIUXDesign = 'UI/UX Design',
}
export const enum ProficiencyScore {
  Excellent = 2,
  Good = 1,
  Average = 0,
}

export const enum PreferenceParameter {
  PreferredPrimaryRole = 'Preferred primary role',
  PreferredDomains = 'Preferred domain(s)',
  PreferredTechs = 'Preferred tech(s)',
  PreferredLocations = 'Preferred location(s)',
}
export const enum ScreeningParameter {
  PrimaryResponsibilities = 'Primary responsibilities',
  LedATeam = 'Led a team',
  SecondaryResponsibilities = 'Secondary responsibilities',
  BestPracticesKnown = 'Best practices known',
  ReasonForCurrentJobChange = 'Reason for current job change',
  ReasonForPreviousJobChanges = 'Reason for previous job changes',
  NoticePeriod = 'Notice period',
  AnyExistingOffers = 'Any existing offers?',
  WillingToLearnNewTechs = 'Willing to learn new techs?',
}
