import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SharedCommonModules } from '../shared/shared.module';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { CoreHeaderComponent } from './components/core-header/core-header.component';
import { CoreSidebarComponent } from './components/core-sidebar/core-sidebar.component';
import { ToasterComponent } from './components/toaster/toaster.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NumbersOnlyInputDirective } from './directives/dragula/directives/numbers-only-input/numbers-only-input.directive';
import { CookieInterceptor } from './interceptors/cookie-setter.interceptor';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { HomeFooterComponent } from './components/home-footer/home-footer.component';
import { CreateAccountComponent } from './shared-components/create-account/create-account.component';
import { CreatePasswordComponent } from './shared-components/create-account/create-password/create-password.component';
import { SignupHomeComponent } from './shared-components/signup-home/signup-home.component';
import { SocialAuthComponent } from './shared-components/social-auth/social-auth.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { JobPanelComponent } from './components/job-panel/job-panel.component';
import { JobStatusPanelComponent } from './components/job-status-panel/job-status-panel.component';
import { EnumLabelPipe } from './pipes/enum-label.pipe';
import { RegexMatchDirective } from './directives/dragula/directives/regex-match/regex-match.directive';
import { SigninComponent } from './shared-components/signin/signin.component';
import { ApplyJobFormComponent } from './shared-components/apply-job-form/apply-job-form.component';
import { CountDownComponent } from './shared-components/count-down/count-down.component';
import { TranslateModule } from '@ngx-translate/core';
import { InteractionsModule } from './interactions/interactions.module';
import { IconModule } from '../icons/icon.module';
import { BasicProfileGuard, ProfileNeedsUpdateGuard, ShowLaunchAnnouncementGuard } from 'geektrust-route-guards';
import { NotInterestedFormComponent } from './shared-components/not-interested-form/not-interested-form.component';
import { RequestdemoHomeComponent } from './shared-components/requestdemo-home/requestdemo-home.component';
import { RecruiterFormComponent } from './shared-components/recruiter-form/recruiter-form.component';
import { MathOperationsPipe } from './pipes/maths.pipe';
import { DifferenceBetweenDatesDirective } from './directives/dragula/directives/date-difference/date-difference.directive';
import { SafeHtmlPipeModule } from './scam-and-spam/pipes/safe-html/safe-html-pipe.module';
import { NudgeUserComponent } from './components/nudge-user/nudge-user.component';
import { PrimaryskillToRolePipe } from './pipes/primaryskill-to-role.pipe';
import { DateDifferenceModule } from './directives/dragula/directives/date-difference/date-difference.module';
import { NoticePeriodAndSalaryComponent } from './shared-components/application-flow/job-application/notice-period-and-salary/notice-period-and-salary.component';
import { BasicDetailsComponent } from './shared-components/application-flow/job-application/basic-details/basic-details.component';
import { ApplicationSummaryComponent } from './shared-components/application-flow/job-application/application-summary/application-summary.component';
import { ApplicationSucceededComponent } from './shared-components/application-flow/job-application/application-succeeded/application-succeeded.component';
import { ExperienceAndPreferenceComponent } from './shared-components/application-flow/job-application/experience-and-preference/experience-and-preference.component';
import { SkillSummaryComponent } from './shared-components/application-flow/job-application/skill-summary/skill-summary.component';
import { JobApplicationComponent } from './shared-components/application-flow/job-application/job-application.component';
import { ProgressBarComponent } from './shared-components/application-flow/job-application/progress-bar/progress-bar.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { SwiperModule } from 'ngx-swiper-wrapper';

@NgModule({
  imports: [
    HttpClientModule,
    SafeHtmlPipeModule,
    CommonModule,
    SharedCommonModules,
    RouterModule,
    BsDatepickerModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    GooglePlaceModule,
    IconModule,
    InteractionsModule,
    ModalModule.forRoot(),
    TranslateModule,
    DateDifferenceModule,
    NgxFileDropModule,
    SwiperModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    DropdownComponent,
    CoreHeaderComponent,
    CoreSidebarComponent,
    ToasterComponent,
    BreadcrumbsComponent,
    FormsModule,
    ReactiveFormsModule,
    NumbersOnlyInputDirective,
    HomeFooterComponent,
    HomeHeaderComponent,
    CreateAccountComponent,
    JobPanelComponent,
    JobStatusPanelComponent,
    SignupHomeComponent,
    ApplyJobFormComponent,
    NotInterestedFormComponent,
    NoticePeriodAndSalaryComponent,
    BasicDetailsComponent,
    ApplicationSummaryComponent,
    ApplicationSucceededComponent,
    ExperienceAndPreferenceComponent,
    SkillSummaryComponent,
    JobApplicationComponent,
    ProgressBarComponent,
    EnumLabelPipe,
    MathOperationsPipe,
    RegexMatchDirective,
    CountDownComponent,
    NgSelectModule,
    TranslateModule,
    SafeHtmlPipeModule,
    IconModule,
    InteractionsModule,
    SignupHomeComponent,
    RecruiterFormComponent,
    RequestdemoHomeComponent,
    NudgeUserComponent,
    PrimaryskillToRolePipe
  ],
  declarations: [
    HeaderComponent,
    FooterComponent,
    DropdownComponent,
    CoreHeaderComponent,
    CoreSidebarComponent,
    ToasterComponent,
    BreadcrumbsComponent,
    NumbersOnlyInputDirective,
    RegexMatchDirective,
    HomeHeaderComponent,
    HomeFooterComponent,
    CreateAccountComponent,
    SigninComponent,
    CreatePasswordComponent,
    SignupHomeComponent,
    SocialAuthComponent,
    NoticePeriodAndSalaryComponent,
    BasicDetailsComponent,
    ApplicationSummaryComponent,
    ApplicationSucceededComponent,
    ExperienceAndPreferenceComponent,
    SkillSummaryComponent,
    JobApplicationComponent,
    JobPanelComponent,
    JobStatusPanelComponent,
    EnumLabelPipe,
    MathOperationsPipe,
    ApplyJobFormComponent,
    CountDownComponent,
    NotInterestedFormComponent,
    RequestdemoHomeComponent,
    RecruiterFormComponent,
    NudgeUserComponent,
    PrimaryskillToRolePipe,
  ProgressBarComponent],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CookieInterceptor, multi: true },
    BasicProfileGuard,
    ShowLaunchAnnouncementGuard,
    ProfileNeedsUpdateGuard
  ]
})
export class CoreModule {}
