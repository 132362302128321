import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { SocialUser } from '../../../core/models/user.model';
import { GACategory } from '../../models/enums';
import { AuthService } from '../../services/auth.service';
import { FacebookPixelService, FA_EVENTS } from '../../services/user-activity-tracking/facebook-pixel.service';
import { GA_EVENTS } from '../../services/user-activity-tracking/ga-events';
import { GAService } from '../../services/user-activity-tracking/google-analytics.service';
import { MixPanelService } from '../../services/user-activity-tracking/mix-panel.service';
import { MIXPANEL_FIELD_UPDATES } from '../../services/user-profile/user-profile.constants';

@Component({
  selector: 'app-social-auth',
  templateUrl: './social-auth.component.html',
  styleUrls: ['./social-auth.component.scss'],
})
export class SocialAuthComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private mixpanel: MixPanelService,
    private cookieService: CookieService,
    private gaService: GAService,
    private facebookPixelService: FacebookPixelService
  ) {}

  ngOnInit(): void {
    const params = this.activatedRoute.snapshot.queryParams;
    const user: SocialUser = {
      email: params.email,
      name: params.name,
      regNo: Number(params.regNo),
      loginViaSocial: params.loginViaSocial === 'true',
      isSignup: params.signup === 'true',
      isCompanyReferral: params.cr === 'true',
      via: params.via,
      provider: params.provider,
      routeAction: params.action,
      redirectUrl: params.redirect,
    };
    this.clearSocialLoginParams();
    if (user.loginViaSocial) {
      this.authService.saveSocialUser(user.email, user.name);
      this.trackSocialAuth(user);
    }
    if (user.redirectUrl) {
      this.router.navigateByUrl(user.redirectUrl);
    } else {
      const redirect = user.isSignup ? '/user-select' : '/dashboard';
      this.router.navigateByUrl(redirect);
    }
  }

  trackSocialAuth(user: SocialUser) {
    if (user.isSignup) {
      this.gaService.setValues({
        user_id: user.regNo?.toString() || '',
      });
      this.mixpanel.track(MIXPANEL_FIELD_UPDATES.MESSAGES.GENERAL_USER.NEW_USER_SIGN_UP, {
        email: user.email,
        name: user.name,
        via: user.via,
        provider: user.provider,
      });
      this.gaService.trackEvent(GA_EVENTS.SOCIAL_SIGNUP, GACategory.SignUp);
      this.facebookPixelService.track(FA_EVENTS.SIGNUP, GACategory.SignUp);
      switch (true) {
        case user.redirectUrl === '/dashboard' && user.isCompanyReferral:
          this.mixpanel.track('Social Sign up for GT-Company Referral');
          break;
        case user.routeAction === 'SubmitCode':
        case user.routeAction === 'CodingProblemUpload':
        case user.routeAction && user.routeAction.indexOf('downloadCP') >= -1:
        case user.routeAction === 'showEventsDevForm':
        case user.redirectUrl &&
          user.redirectUrl.indexOf('/jobs') > -1 &&
          user.routeAction &&
          user.routeAction.indexOf('ApplyToCompany') >= -1:
        case user.redirectUrl &&
          user.redirectUrl.indexOf('/jobs/') > -1 &&
          user.routeAction &&
          user.routeAction.indexOf('ApplyToCompany') >= -1:
      }
    } else {
      this.gaService.setValues({
        user_id: user.regNo?.toString() || '',
      });
      this.mixpanel.identify(user);
      this.mixpanel.track(MIXPANEL_FIELD_UPDATES.MESSAGES.LOGGED_IN, {
        email: user.email,
        name: user.name,
        provider: user.provider,
      });
      this.gaService.trackEvent(GA_EVENTS.SOCIAL_SIGNIN, GACategory.Login);
    }
  }

  clearSocialLoginParams() {
    this.cookieService.remove('emailCampaign');
    this.cookieService.remove('loginCallbackAction');
    this.cookieService.remove('redirectURL');
    const queryParams: Params = {};
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams,
    });
  }
}
