import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { DragulaModule } from 'ng2-dragula';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CoreModule } from 'src/app/core/core.module';
import { SharedCommonModules } from '../shared.module';
import { UserBasicDetailsFormComponent } from './user-basic-details-form/user-basic-details-form.component';
import { UserVitalStatsFormComponent } from './user-vital-stats-form/user-vital-stats-form.component';
import { UserWorkAndEducationFormComponent } from './user-work-and-education-form/user-work-and-education-form.component';

@NgModule({
  declarations: [UserBasicDetailsFormComponent, UserVitalStatsFormComponent, UserWorkAndEducationFormComponent],
  imports: [
    CommonModule,
    CoreModule,
    SharedCommonModules,
    DragulaModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    GooglePlaceModule,
    NgSelectModule,
  ],
  exports: [
    UserBasicDetailsFormComponent,
    UserVitalStatsFormComponent,
    UserWorkAndEducationFormComponent
  ]

})
export class ProfileFormsModule { }
