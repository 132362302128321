import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { GLOBAL_URLS } from '../../constants/urls';
import { HttpService } from '../../services/http.service';
import { NudgePopupConfiguration, Nudges } from './nudge-user.interfaces';

const EXCLUDED_ROUTES = [
  '/hire',
  '/tellus/',
  '/passive-user',
  '/create-account',
  '/sign-in',
  '/job/',
  '/pitch',
  '/user-select',
  '/create-password',
  '/forgot-password',
  '/coding/evaluation/upload-code',
  '/xponential',
  '/platinum'
];
@Injectable({
  providedIn: 'root',
})
export class NudgeUserService {
  nudgeProps: Subject<NudgePopupConfiguration> = new Subject<NudgePopupConfiguration>();
  showPopupGlobally = true;
  constructor(private httpService: HttpService, private router: Router) { }

  getNudges() {
    return this.httpService.get(GLOBAL_URLS.NUDGE_URL).pipe(
      map((nudges: Nudges[]) => {
        return nudges.map((nudge) => ({
          ...nudge,
          dismiss: 8000,
          showPopup: true,
          title: nudge.title.toUpperCase(),
          icon: {
            source: `https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/v2/nudges/${nudge.type}.png`,
            alt: nudge.type
              .split('_')
              .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
              .join(' '),
          },
        }));
      })
    );
  }
  nudgeUser(obj) {
    this.nudgeProps.next(obj);
  }

  startNudgingUser() {
    this.getNudges().subscribe((nudges) => {
      timer(8000, 48000)
        .pipe(
          map((e) => {
            const selectedNudge = nudges[e % nudges?.length];
            return {
              ...selectedNudge,
              showPopup: true,
              time: moment(new Date(selectedNudge?.updatedAt)).from(new Date()),
            };
          })
        )
        .subscribe((response: NudgePopupConfiguration) => {
          const popup = { ...response };
          popup.showPopup =
            popup.title &&
            this.showPopupGlobally &&
            popup.showPopup &&
            EXCLUDED_ROUTES.every((excludedRoute) => this.router.url.startsWith(excludedRoute) === false);
          this.nudgeUser(popup);
        });
    });
  }
}
