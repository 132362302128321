import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MixPanelService {
  constructor(@Inject(PLATFORM_ID) private platformID: object) {}

  init(): void {
    if (isPlatformBrowser(this.platformID)) {
      mixpanel.init(environment.mixpanelToken, { ignore_dnt: true });
    }
  }

  optOutTracking(): void {
    if (isPlatformBrowser(this.platformID)) {
      mixpanel.opt_out_tracking();
    }
  }

  optInTracking(): void {
    if (isPlatformBrowser(this.platformID)) {
      mixpanel.opt_in_tracking();
    }
  }

  identify({ email, name }: { email: string; name?: string }): void {
    if (isPlatformBrowser(this.platformID)) {
      mixpanel.identify(email);
      mixpanel.people.set({
        email,
        name,
        $email: email,
        $name: name,
      });
    }
  }

  reset(): void {
    if (isPlatformBrowser(this.platformID)) {
      mixpanel.reset();
    }
  }

  modifyUserProfile(properties = {}) {
    mixpanel.people.set(properties);
    mixpanel.identify();
  }

  setOnceInProfile(properties) {
    mixpanel.people.set_once(properties);
    mixpanel.identify();
  }

  registerInUserProfile(properties) {
    mixpanel.register(properties);
  }
  profileValueUnion(values) {
    mixpanel.people.union(values);
  }

  track(event: string, properties: any = {}): void {
    if (isPlatformBrowser(this.platformID)) {
      mixpanel.track(event, properties);
    }
  }

  incrementUserProperty(property) {
    mixpanel.people.increment(property);
    mixpanel.identify();
  }
}
