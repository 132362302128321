import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { MixPanelService } from 'src/app/core/services/user-activity-tracking/mix-panel.service';
import {
    SubBadgeHelpTextMessages, SubBadgeNamesMap
  } from '../../features/dashboard-workflow/coding/constants/rich-feedback.constants';

@Component({
  selector: 'app-new-report-model',
  templateUrl: './new-report-model.component.html',
  styleUrls: ['./new-report-model.component.scss']
})
export class NewReportModelComponent implements OnInit {
  @Input() hasVerbosityFeedback = false;
  @Input() negativeFeedbackIcon = 'red-cross';
  @Input() badgesForEvaluation = false;
  @Input() codeFeedback = false;
  @Input() hideMasterBadgeAndScoresForSubBadge = false;
  @Input() hideScores = false;
  @Input() mixpanelProperties: any;

  constructor(
    private mixpanel: MixPanelService,
    private modalService: BsModalService
  ) { }

  viewExampleModalRef: BsModalRef;
  selectedSubCriteria: string;
  selectedGenericCriteria: string;
  selectedNegativeFinding: any;
  noOfViolations: number;
  sbNames = SubBadgeNamesMap;
  sbHelpTextMsgs = SubBadgeHelpTextMessages;

  ngOnInit(): void {
  }

  openViewExample(viewExampleTemplate: TemplateRef<any>, subCriteria: string, negativeFinding: any, noOfViolations: number) {
    const mixpanelProps = this.getMixpanelProperties();
    mixpanelProps.subBadge = subCriteria;
    this.mixpanel.track('User Clicked on View Examples', mixpanelProps);
    this.selectedSubCriteria = subCriteria;
    this.selectedNegativeFinding = negativeFinding;
    this.noOfViolations = noOfViolations;
    this.viewExampleModalRef = this.modalService.show(viewExampleTemplate, {
      class: 'modal-dialog-centered verbosity-findings-modal modal-lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  closeExampleModal(event: any) {
    if (event) {
      this.selectedSubCriteria = undefined;
      this.selectedGenericCriteria = undefined;
      this.noOfViolations = undefined;
      this.selectedNegativeFinding = undefined;
    }
  }

  openExamplesModalForGenericFinding( badFeedbackIndex: number, badgeInfo: any, viewExampleTemplate: TemplateRef<any>) {
    const badFeedbackKey: string = badgeInfo?.badFeedbacks[badFeedbackIndex];
    const negativeFinding = badgeInfo?.genericNegativeFindings[badFeedbackKey];
    const noOfViolations = badgeInfo?.noOfGenericViolations[badFeedbackKey];

    const mixpanelProps = this.getMixpanelProperties();
    mixpanelProps.oopsGenericSubBadge = badFeedbackKey;
    this.mixpanel.track('User Clicked on View Examples', mixpanelProps);

    this.selectedGenericCriteria = badFeedbackKey;
    this.selectedNegativeFinding = negativeFinding;
    this.noOfViolations = noOfViolations;
    this.viewExampleModalRef = this.modalService.show(viewExampleTemplate, {
      class: 'modal-dialog-centered verbosity-findings-modal modal-lg',
      backdrop: 'static',
      keyboard: false,
    });
  }

  getMixpanelProperties() {
    const props: any = {};
    (props.problemName = this.mixpanelProperties.problemName),
      (props.problemType = this.mixpanelProperties.problemType),
      (props.email = this.mixpanelProperties.email);
    switch (this.mixpanelProperties.problemType.toLowerCase()) {
      case 'backend':
        props.language = this.mixpanelProperties.language;
        props.build = this.mixpanelProperties.buildTool;
        break;
      case 'frontend':
        props.framework = this.mixpanelProperties.language;
        break;
      default:
        props.language = this.mixpanelProperties.language;
        props.build = this.mixpanelProperties.buildTool;
        break;
    }
    return props;
  }
}
