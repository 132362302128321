import { Injectable, Renderer2 } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToasterToggleService {
  toastProps: Subject<object> = new BehaviorSubject<object>([]);
  toastPropsObserver = this.toastProps.asObservable();
  constructor() {}
  openToast(obj) {
    this.toastProps.next(obj);
  }
}
