<div *ngIf="hasVerbosityFeedback; else overallFeedback" class="badges-section">
    <div class="badges-consolidation-section">
        <!-- <div class="title">
            EARNED BADGES ({{badgesForEvaluation.earnedCount || 0}})
        </div> -->
        <div class="content">
            <ng-container
                *ngIf="badgesForEvaluation.earnedCount && badgesForEvaluation.earned?.length > 0; else earnedNoBadgesSection">
                <ng-container *ngFor="let earnedBadge of badgesForEvaluation.earned">
                    <div class="badge-card-section" *ngIf="earnedBadge.badges.length > 0">
                        <div class="badge-card-content">
                            <div class="individual-badge" *ngFor="let individualBadge of earnedBadge.badges">
                                <div class="badge-left">
                                    <div *ngIf="hideMasterBadgeAndScoresForSubBadge === false || individualBadge.subBadges.length === 0"
                                        class="master-badge-tag">MASTER BADGE</div>
                                    <div class="badge-name">
                                        {{individualBadge.name}}
                                    </div>
                                </div>
                                <div class="badge-right">
                                    <div class="master-badge-section">
                                        <div class="master-badge-header-section">
                                            <div class="master-badge-tag-container">
                                                <div *ngIf="hideMasterBadgeAndScoresForSubBadge === false"
                                                    class="master-badge-tag">MASTER BADGE</div>

                                                <div class="badge-name">
                                                    {{individualBadge.name}}
                                                </div>

                                            </div>
                                            <ng-container
                                                *ngIf="hideMasterBadgeAndScoresForSubBadge === false || individualBadge.subBadges.length === 0">

                                                <div *ngIf="hideScores === false" class="master-badge-score-container">
                                                    <div class="master-badge-score">
                                                        <div class="label">Score</div>
                                                        <div class="value">{{individualBadge.score | number}} / 100
                                                        </div>
                                                    </div>
                                                    <div *ngIf='["Readability", "Functional / OO Modelling", "Maintainability"].includes(individualBadge.name)'
                                                        class="badge-name-tt tooltip">
                                                        <img
                                                            src="../../../../../../assets/images/questionMarkFabIcon.svg" />
                                                        <span class="tooltiptext">
                                                            <ng-container
                                                                *ngIf="'Readability' === individualBadge.name">
                                                                Readability score (out of 100) <br />= <br />
                                                                Readability ML score (out of 60)<br />
                                                                + Compact methods (out of 13)<br />
                                                                + Magic numbers (out of 14)<br />
                                                                + expressive code (out of 13)<br />
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="'Functional / OO Modelling' === individualBadge.name">
                                                                Object Modelling score (out of 100) <br />=<br />
                                                                OOPs ML (out of 60)<br />
                                                                + Encapsulation (out of 40)<br />
                                                            </ng-container>
                                                            <ng-container
                                                                *ngIf="'Maintainability' === individualBadge.name">
                                                                Maintainability score (out of 100) <br />= <br />
                                                                General Maintainability Check (out of 90) <br />
                                                                + Code Duplication Check (out of 10)
                                                            </ng-container>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div class="master-badge-result-container">
                                                    <div class="master-badge-result">
                                                        <div class="label">Result</div>
                                                        <div class="value">
                                                            <div class="app-c-icon-wrapper  green-bgc">
                                                                <app-svg-icon name="tick-circle" title="tick"
                                                                    className="icon-svg icon-size-18" ariaHidden="false"
                                                                    focusable="false"></app-svg-icon>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <ng-container
                                            *ngIf="hideMasterBadgeAndScoresForSubBadge === false || individualBadge.subBadges.length === 0">
                                            <div class="master-badge-feedback-section"
                                                *ngIf="individualBadge?.feedback?.bad?.length > 0 || individualBadge?.feedback?.good.length > 0">
                                                <div *ngFor="let feedback of individualBadge.feedback.good">
                                                    <div class="feedback-statement">
                                                        <div class="feedback-icon">
                                                            <app-svg-icon name="tick" title="tick-circle"
                                                                className="icon-svg icon-size-12" ariaHidden="false"
                                                                focusable="false">
                                                            </app-svg-icon>
                                                        </div>
                                                        <div class="feedback-text">{{feedback}}</div>
                                                    </div>
                                                </div>
                                                <div
                                                    *ngFor="let feedback of individualBadge.feedback.bad;let index = index">
                                                    <div class="feedback-statement">
                                                        <div class="feedback-icon">
                                                            <app-svg-icon [(name)]="negativeFeedbackIcon" title="cross-small"
                                                                className="icon-svg icon-size-12" ariaHidden="false"
                                                                focusable="false">
                                                            </app-svg-icon>
                                                        </div>
                                                        <div class="feedback-text">{{feedback}}
                                                            <!-- If there is a negative finding, only then we show the view more button-->
                                                            <span class="view-example-text"
                                                                *ngIf="individualBadge?.genericNegativeFindings &&individualBadge?.genericNegativeFindings[individualBadge.badFeedbacks[index]]"
                                                                (click)="openExamplesModalForGenericFinding(index, individualBadge,viewExampleTemplate)">View
                                                                More</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="maintainability-help-docs"
                                                    *ngIf="individualBadge?.feedback?.bad?.length && 'Maintainability' === individualBadge.name">
                                                    Your code
                                                    is not maintainable.Do go through the
                                                    <a href="https://help.geektrust.com/article/90-earning-maintainability-badge"
                                                        target="_blank">help docs</a> to learn more
                                                    about earning a Maintainability badge.
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <div class="sub-badges-section" *ngIf="individualBadge?.subBadges && individualBadge.subBadges.length > 0">
                                        <div *ngIf="hideMasterBadgeAndScoresForSubBadge === false"
                                            class="sub-badges-header-section">
                                            <div class="sub-badges-header">
                                                <div class="sub-badges-header-tag">
                                                    SUB BADGES
                                                </div>
                                                <div class="separator"></div>
                                            </div>
                                        </div>
                                        <div class="sub-badges-content-section">
                                            <div *ngFor="let subBadge of individualBadge.subBadges"
                                                class="individual-sub-badge-section">
                                                <div class="individual-sub-badge-header-section">
                                                    <div class="individual-sub-badge-title-container">
                                                        <div class="sub-badge-name">
                                                            {{subBadge.name == "DRY" ? "CODE DUPLICATION" :
                                                            subBadge.name}}
                                                        </div>
                                                    </div>
                                                    <ng-container>

                                                        <div *ngIf="hideScores === false"
                                                            class="individual-sub-badge-score-container">
                                                            <div class="sub-badge-score">
                                                                <div class="label">Score</div>
                                                                <div class="value">{{subBadge.score | number}} /
                                                                    {{subBadge.maxScore}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="individual-sub-badge-result-container">
                                                            <div class="sub-badge-result">
                                                                <div class="label">Result</div>
                                                                <div class="value">
                                                                    <div class="app-c-icon-wrapper  green-bgc"
                                                                        [ngClass]="{'red-bgc': subBadge.earned === false, 'green-bgc': subBadge.earned === true}">
                                                                        <app-svg-icon
                                                                            name="{{subBadge.earned ? 'tick-circle': 'cross-circle'}}"
                                                                            title="tick-circle"
                                                                            className="icon-svg icon-size-10"
                                                                            ariaHidden="false" focusable="false">
                                                                        </app-svg-icon>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                                <div class="individual-sub-badge-content-section">
                                                    <div *ngIf=" subBadge?.feedback?.showOOMLEarned">
                                                        <div class="feedback-statement">
                                                            <div class="feedback-icon">
                                                                <app-svg-icon name="tick" title="tick-circle"
                                                                    className="icon-svg icon-size-12" ariaHidden="false"
                                                                    focusable="false">
                                                                </app-svg-icon>
                                                            </div>
                                                            <div class="feedback-text">By comparing your code with our
                                                                past
                                                                submissions, our <a class="link" href="https://codu.ai/"
                                                                    target="_blank">AI assessment tool</a> assesses that
                                                                your code is well modelled.</div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf=" subBadge?.feedback?.showOOMLNotEarned">
                                                        <div class="feedback-statement">
                                                            <div class="feedback-icon">
                                                                <app-svg-icon name="red-cross" title="cross-small"
                                                                    className="icon-svg icon-size-10" ariaHidden="false"
                                                                    focusable="false">
                                                                </app-svg-icon>
                                                            </div>
                                                            <div class="feedback-text">
                                                                By comparing your code with our past submissions, our <a
                                                                    href="https://codu.ai/" class="link"
                                                                    target="_blank">AI
                                                                    assessment tool</a> assesses that your code is not
                                                                well
                                                                modelled. Do go through the <a target="_blank"
                                                                    class="link"
                                                                    href="https://help.geektrust.com/article/4-earning-oops-badge">help
                                                                    documentation</a> to
                                                                improve your code.</div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf=" subBadge?.feedback?.showReadabilityMLEarned">
                                                        <div class="feedback-statement">
                                                            <div class="feedback-icon">
                                                                <app-svg-icon name="tick" title="tick-circle"
                                                                    className="icon-svg icon-size-12" ariaHidden="false"
                                                                    focusable="false">
                                                                </app-svg-icon>
                                                            </div>
                                                            <div class="feedback-text">By comparing your code with our
                                                                past
                                                                submissions, our <a class="link" href="https://codu.ai/"
                                                                    target="_blank">AI assessment tool</a> assesses that
                                                                your code is readable</div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf=" subBadge?.feedback?.showReadabilityMLNotEarned">
                                                        <div class="feedback-statement">
                                                            <div class="feedback-icon">
                                                                <app-svg-icon name="red-cross" title="cross-small"
                                                                    className="icon-svg icon-size-10" ariaHidden="false"
                                                                    focusable="false">
                                                                </app-svg-icon>
                                                            </div>
                                                            <div class="feedback-text">By comparing your code with our
                                                                past
                                                                submissions, our <a class="link" href="https://codu.ai/"
                                                                    target="_blank">AI assessment tool</a> assesses that
                                                                your code is not readable. Do go through the <a
                                                                    class="link" target="_blank"
                                                                    href="https://help.geektrust.com/article/36-earning-readability-badge">help
                                                                    documentation</a> to improve your code.</div>
                                                        </div>
                                                    </div>
                                                    <ng-container *ngIf=" subBadge?.feedback?.good">
                                                        <div *ngFor="let feedback of subBadge.feedback.good">
                                                            <div class="feedback-statement">
                                                                <div class="feedback-icon">
                                                                    <app-svg-icon name="tick" title="tick-circle"
                                                                        className="icon-svg icon-size-12"
                                                                        ariaHidden="false" focusable="false">
                                                                    </app-svg-icon>
                                                                </div>
                                                                <div class="feedback-text">{{feedback}}</div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf=" subBadge?.feedback?.bad">
                                                        <div *ngFor="let feedback of subBadge.feedback.bad">
                                                            <div class="feedback-statement">
                                                                <div class="feedback-icon">
                                                                    <app-svg-icon name="red-cross" title="cross-small"
                                                                        className="icon-svg icon-size-10"
                                                                        ariaHidden="false" focusable="false">
                                                                    </app-svg-icon>
                                                                </div>
                                                                <div class="feedback-text">{{feedback}} <span
                                                                        *ngIf="subBadge.noOfViolations"
                                                                        class="view-example-text"
                                                                        (click)="openViewExample(viewExampleTemplate, subBadge.subCriteria, subBadge.negativeFinding, subBadge.noOfViolations)">
                                                                        View More </span></div>
                                                            </div>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>
            <ng-container *ngFor="let earnedBadge of badgesForEvaluation.unearned">
                <div class="badge-card-section" *ngIf="earnedBadge.badges.length > 0">
                    <div class="badge-card-content">
                        <div class="individual-badge" *ngFor="let individualBadge of earnedBadge.badges">
                            <div class="badge-left">
                                <div *ngIf="hideMasterBadgeAndScoresForSubBadge === false" class="master-badge-tag">
                                    MASTER BADGE</div>

                                <div class="badge-name">
                                    {{individualBadge.name}}
                                </div>
                            </div>
                            <div class="badge-right">
                                <div class="master-badge-section">
                                    <div class="master-badge-header-section">
                                        <div class="master-badge-tag-container">
                                            <div *ngIf="hideMasterBadgeAndScoresForSubBadge === false"
                                                class="master-badge-tag">MASTER BADGE</div>

                                            <div class="badge-name">
                                                {{individualBadge.name}}
                                            </div>
                                        </div>
                                        <ng-container
                                            *ngIf="hideMasterBadgeAndScoresForSubBadge === false || individualBadge.subBadges.length === 0">
                                            <div *ngIf="hideScores === false" class="master-badge-score-container">
                                                <div class="master-badge-score">
                                                    <div class="label">Score</div>
                                                    <div class="value">{{individualBadge.score| number }} / 100</div>
                                                </div>

                                                <div *ngIf='["Readability", "Functional / OO Modelling", "Maintainability"].includes(individualBadge.name)'
                                                    class="badge-name-tt tooltip">
                                                    <span class="tooltiptext">
                                                        <ng-container *ngIf="'Readability' === individualBadge.name">
                                                            Readability score (out of 100)
                                                            <br />
                                                            = <br />
                                                            Readability ML score (out of 60)<br />
                                                            + Compact methods (out of 13)<br />
                                                            + Magic numbers (out of 14)<br />
                                                            + expressive code (out of 13)<br />
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="'Functional / OO Modelling' === individualBadge.name">
                                                            Object Modelling score (out of 100)<br /> =<br />
                                                            OOPs ML (out of 60)<br />
                                                            + Encapsulation (out of 40)<br />
                                                        </ng-container>
                                                        <ng-container
                                                            *ngIf="'Maintainability' === individualBadge.name">
                                                            Maintainability score (out of 100) <br />= <br />
                                                            General Maintainability Check (out of 90) <br />
                                                            + Code Duplication Check (out of 10)
                                                        </ng-container>
                                                    </span>
                                                    <img
                                                        src="../../../../../../assets/images/questionMarkFabIcon.svg" />
                                                </div>
                                            </div>
                                            <div class="master-badge-result-container">
                                                <div class="master-badge-result">
                                                    <div class="label">Result</div>
                                                    <div class="value">
                                                        <div class="app-c-icon-wrapper  red-bgc">
                                                            <app-svg-icon name="cross-circle" title="cross"
                                                                className="icon-svg icon-size-10" ariaHidden="false"
                                                                focusable="false">
                                                            </app-svg-icon>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <ng-container
                                        *ngIf="hideMasterBadgeAndScoresForSubBadge === false || individualBadge.subBadges.length === 0">
                                        <div class="master-badge-feedback-section"
                                            *ngIf="individualBadge.feedback.bad.length > 0 || individualBadge.feedback.good.length > 0">
                                            <div *ngFor="let feedback of individualBadge?.feedback?.good">
                                                <div class="feedback-statement">
                                                    <div class="feedback-icon">
                                                        <app-svg-icon name="tick" title="tick-circle"
                                                            className="icon-svg icon-size-12" ariaHidden="false"
                                                            focusable="false">
                                                        </app-svg-icon>
                                                    </div>
                                                    <div class="feedback-text">{{feedback}}</div>
                                                </div>
                                            </div>
                                            <div *ngFor="let feedback of individualBadge.feedback.bad">
                                                <div class="feedback-statement">
                                                    <div class="feedback-icon">
                                                        <app-svg-icon [(name)]="negativeFeedbackIcon" title="cross-small"
                                                            className="icon-svg icon-size-12" ariaHidden="false"
                                                            focusable="false">
                                                        </app-svg-icon>
                                                    </div>
                                                    <div class="feedback-text">{{feedback}}</div>


                                                    <div></div>
                                                </div>
                                            </div>

                                            <div class="maintainability-help-docs"
                                                *ngIf="individualBadge?.feedback?.bad?.length && 'Maintainability' === individualBadge.name">
                                                Your code
                                                is not maintainable. Do go through the
                                                <a href="https://help.geektrust.com/article/90-earning-maintainability-badge"
                                                    target="_blank">help docs</a> to learn more
                                                about earning a Maintainability badge.
                                            </div>

                                        </div>
                                    </ng-container>
                                </div>
                                <div class="sub-badges-section" *ngIf="individualBadge?.subBadges &&individualBadge.subBadges.length > 0">
                                    <div *ngIf="hideMasterBadgeAndScoresForSubBadge === false"
                                        class="sub-badges-header-section">
                                        <div class="sub-badges-header">
                                            <div class="sub-badges-header-tag">
                                                SUB BADGES
                                            </div>
                                            <div class="separator"></div>
                                        </div>
                                    </div>
                                    <div class="sub-badges-content-section">
                                        <div *ngFor="let subBadge of individualBadge.subBadges"
                                            class="individual-sub-badge-section">
                                            <div class="individual-sub-badge-header-section">
                                                <div class="individual-sub-badge-title-container">
                                                    <div class="sub-badge-name">
                                                        {{subBadge.name == "DRY" ? "CODE DUPLICATION" :
                                                        subBadge.name}}
                                                    </div>
                                                </div>
                                                <ng-container>

                                                    <div *ngIf="hideScores === false"
                                                        class="individual-sub-badge-score-container">
                                                        <div class="sub-badge-score">
                                                            <div class="label">Score</div>
                                                            <div class="value">{{subBadge.score | number}} /
                                                                {{subBadge.maxScore}}</div>
                                                        </div>
                                                    </div>
                                                    <div class="individual-sub-badge-result-container">
                                                        <div class="sub-badge-result">
                                                            <div class="label">Result</div>
                                                            <div class="value">
                                                                <div class="app-c-icon-wrapper  green-bgc"
                                                                    [ngClass]="{'red-bgc': subBadge.earned === false, 'green-bgc': subBadge.earned === true}">
                                                                    <app-svg-icon
                                                                        name="{{subBadge.earned ? 'tick-circle': 'cross-circle'}}"
                                                                        title="tick-circle"
                                                                        className="icon-svg icon-size-10"
                                                                        ariaHidden="false" focusable="false">
                                                                    </app-svg-icon>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <div class="individual-sub-badge-content-section">
                                                <div *ngIf=" subBadge?.feedback?.showOOMLEarned">
                                                    <div class="feedback-statement">
                                                        <div class="feedback-icon">
                                                            <app-svg-icon name="tick" title="tick-circle"
                                                                className="icon-svg icon-size-12" ariaHidden="false"
                                                                focusable="false">
                                                            </app-svg-icon>
                                                        </div>
                                                        <div class="feedback-text">By comparing your code with our past
                                                            submissions, our <a class="link" href="https://codu.ai/"
                                                                target="_blank">AI assessment tool</a> assesses that
                                                            your code is well modelled.</div>
                                                    </div>
                                                </div>
                                                <div *ngIf=" subBadge?.feedback?.showOOMLNotEarned">
                                                    <div class="feedback-statement">
                                                        <div class="feedback-icon">
                                                            <app-svg-icon name="red-cross" title="cross-small"
                                                                className="icon-svg icon-size-10" ariaHidden="false"
                                                                focusable="false">
                                                            </app-svg-icon>
                                                        </div>
                                                        <div class="feedback-text">
                                                            By comparing your code with our past submissions, our <a
                                                                href="https://codu.ai/" class="link" target="_blank">AI
                                                                assessment tool</a> assesses that your code is not well
                                                            modelled. Do go through the <a target="_blank" class="link"
                                                                href="https://help.geektrust.com/article/4-earning-oops-badge">help
                                                                documentation</a> to
                                                            improve your code.</div>
                                                    </div>
                                                </div>
                                                <div *ngIf=" subBadge?.feedback?.showReadabilityMLEarned">
                                                    <div class="feedback-statement">
                                                        <div class="feedback-icon">
                                                            <app-svg-icon name="tick" title="tick-circle"
                                                                className="icon-svg icon-size-12" ariaHidden="false"
                                                                focusable="false">
                                                            </app-svg-icon>
                                                        </div>
                                                        <div class="feedback-text">By comparing your code with our past
                                                            submissions, our <a class="link" href="https://codu.ai/"
                                                                target="_blank">AI assessment tool</a> assesses that
                                                            your code is readable</div>
                                                    </div>
                                                </div>
                                                <div *ngIf=" subBadge?.feedback?.showReadabilityMLNotEarned">
                                                    <div class="feedback-statement">
                                                        <div class="feedback-icon">
                                                            <app-svg-icon name="red-cross" title="cross-small"
                                                                className="icon-svg icon-size-10" ariaHidden="false"
                                                                focusable="false">
                                                            </app-svg-icon>
                                                        </div>
                                                        <div class="feedback-text">By comparing your code with our past
                                                            submissions, our <a class="link" href="https://codu.ai/"
                                                                target="_blank">AI assessment tool</a> assesses that
                                                            your code is not readable. Do go through the <a class="link"
                                                                target="_blank"
                                                                href="https://help.geektrust.com/article/36-earning-readability-badge">help
                                                                documentation</a> to improve your code.</div>
                                                    </div>
                                                </div>
                                                <ng-container *ngIf=" subBadge?.feedback?.good">
                                                    <div *ngFor="let feedback of subBadge.feedback.good">
                                                        <div class="feedback-statement">
                                                            <div class="feedback-icon">
                                                                <app-svg-icon name="tick" title="tick-circle"
                                                                    className="icon-svg icon-size-14" ariaHidden="false"
                                                                    focusable="false">
                                                                </app-svg-icon>
                                                            </div>
                                                            <div class="feedback-text">{{feedback}}</div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf=" subBadge?.feedback?.bad">
                                                    <div *ngFor="let feedback of subBadge.feedback.bad">
                                                        <div class="feedback-statement">
                                                            <div class="feedback-icon">
                                                                <app-svg-icon name="red-cross" title="cross-small"
                                                                    className="icon-svg icon-size-10" ariaHidden="false"
                                                                    focusable="false">
                                                                </app-svg-icon>
                                                            </div>
                                                            <div class="feedback-text">{{feedback}} <span
                                                                    *ngIf="subBadge.noOfViolations"
                                                                    class="view-example-text"
                                                                    (click)="openViewExample(viewExampleTemplate, subBadge.subCriteria, subBadge.negativeFinding, subBadge.noOfViolations)">
                                                                    View More </span></div>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
        <ng-template #earnedNoBadgesSection>
            <ng-container *ngIf="badgesForEvaluation.unearned.length === 0">
                You have not earned any badges.
            </ng-container>
        </ng-template>
    </div>
</div>
<ng-template #overallFeedback>
    <div class="lite-badges-section">
        <div class="lite-badges-container">

            <div class="badges-section">
                <div class="earned-badges-section">
                    <!-- <div class="title">EARNED BADGES ({{badgesForEvaluation.earnedCount || 0}})</div> -->
                    <div class="content">
                        <ng-container *ngIf="badgesForEvaluation.earned.length > 0; else earnedNoBadgesSection">

                            <ng-container *ngFor="let individualBadge of badgesForEvaluation.earned; let first = first">
                                <div class="badge-card-section">
                                    <div class="badge-card-content">
                                        <div class="individual-badge">
                                            <div class="badge-top">
                                                <div class="badge-name">
                                                    {{individualBadge.name}}
                                                </div>
                                                <div class="value">
                                                    <div class="app-c-icon-wrapper  green-bgc">
                                                        <app-svg-icon name="tick-circle" title="tick"
                                                            className="icon-svg icon-size-14" ariaHidden="false"
                                                            focusable="false">
                                                        </app-svg-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="hideScores === false" class="badge-bottom">
                                                <div class="badge-score">
                                                    <div class="label">Score</div>
                                                    <div class="value">{{individualBadge.score| number }} / 100</div>
                                                </div>
                                            </div>
                                            <div class="badge-result">
                                                <div class="value">
                                                    <div class="app-c-icon-wrapper  green-bgc">
                                                        <app-svg-icon name="tick-circle" title="tick"
                                                            className="icon-svg icon-size-10" ariaHidden="false"
                                                            focusable="false"></app-svg-icon>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="!first" class="separator"></div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngFor="let individualBadge of badgesForEvaluation.unearned; let first= first">
                            <div class="badge-card-section">
                                <div class="badge-card-content">
                                    <div class="individual-badge">
                                        <div class="badge-top">
                                            <div class="badge-name">
                                                {{individualBadge.name}}
                                            </div>
                                            <div class="value">
                                                <div class="app-c-icon-wrapper  red-bgc">
                                                    <app-svg-icon name="cross-circle" title="cross"
                                                        className="icon-svg icon-size-10" ariaHidden="false"
                                                        focusable="false"></app-svg-icon>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngIf="hideScores === false" class="badge-bottom">
                                            <div class="badge-score">
                                                <div class="label">Score</div>
                                                <div class="value">{{individualBadge.score | number}} / 100</div>
                                            </div>
                                        </div>
                                        <div class="badge-result">
                                            <div class="value">
                                                <div class="app-c-icon-wrapper  red-bgc">
                                                    <app-svg-icon name="cross-circle" title="cross"
                                                        className="icon-svg icon-size-10" ariaHidden="false"
                                                        focusable="false"></app-svg-icon>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="separator"></div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <ng-template #earnedNoBadgesSection>
                        <ng-container *ngIf="badgesForEvaluation.unearned.length === 0">
                            You have not earned any badges.
                        </ng-container>
                    </ng-template>
                </div>
                <!-- <div class="unearned-badges-section" *ngIf="badgesForEvaluation.unearned.length > 0">
                    <div class="title">UNEARNED BADGES ({{badgesForEvaluation.unearnedCount || 0}})</div>
                    <div class="content" *ngIf="badgesForEvaluation.unearned.length > 0">

                    </div>
                </div> -->
            </div>
            <div class="feedback-section" *ngIf="codeFeedback.length > 0">
                <div class="feedback">
                    <div class="header">
                        Overall Feedback
                    </div>
                    <div class="content">
                        {{codeFeedback}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #viewExampleTemplate>
    <app-view-example-modal [selectedSubCriteria]="selectedSubCriteria"
        [selectedGenericCriteria]="selectedGenericCriteria" [selectedNegativeFinding]="selectedNegativeFinding"
        (closedModal)="closeExampleModal($event)" [noOfViolations]="noOfViolations"
        [viewExampleModalRef]="viewExampleModalRef"></app-view-example-modal>
</ng-template>