import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxMasonryModule } from 'ngx-masonry';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CodePortfolioBadgeComponent } from '../core/components/code-portfolio-badge/code-portfolio-badge.component';
import { SafeHtmlPipeModule } from '../core/scam-and-spam/pipes/safe-html/safe-html-pipe.module';
import { IconModule } from '../icons/icon.module';
import { CardSkeletonLoaderComponent } from './components/card-skeleton-loader/card-skeleton-loader.component';
import { CodeReportBadgeComponent } from './components/code-report/code-report-badge/code-report-badge.component';
import { CodeReportSummaryComponent } from './components/code-report/code-report-summary/code-report-summary.component';
import { CodeReportComponent } from './components/code-report/code-report.component';
import { NoVerbosityReportComponent } from './components/code-report/no-verbosity-report/no-verbosity-report.component';
import { SubcriteriaLabelPipe } from './components/code-report/subcriteria-label.pipe';
import { CodingChallengeCardsModule } from './components/coding-challenge-card-layout/coding-challenge-cards-layout.module';
import { GeektrustBadgesScoreComponent } from './components/coding-challenges/badges-score/badges-score.component';
import { GeektrustCreditsComponent } from './components/coding-challenges/credits/credits.component';
import { GeektrustHelpCenterComponent } from './components/coding-challenges/help-center/help-center.component';
import { ExistingProblemStatementComponent } from './components/coding-challenges/problem-statement/existing/existing-problem-statement/existing-problem-statement.component';
import { ExperimentalProblemStatementComponent } from './components/coding-challenges/problem-statement/experimental/experimental-problem-statement/experimental-problem-statement.component';
import { GeektrustProblemStatementComponent } from './components/coding-challenges/problem-statement/problem-statement.component';
import { ExistingSampleIoComponent } from './components/coding-challenges/sample-io/existing/existing-sample-io/existing-sample-io.component';
import { ExperimentalSampleIoComponent } from './components/coding-challenges/sample-io/experimental/experimental-sample-io/experimental-sample-io.component';
import { GeektrustSampleIoComponent } from './components/coding-challenges/sample-io/sample-io.component';
import { LoaderComponent } from './components/loader/loader.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { ResumeUploaderComponent } from './components/resume-uploader/resume-uploader.component';
import { ViewExampleModalComponent } from './components/view-example-modal/view-example-modal.component';
import { NewReportModelComponent } from './new-report-model/new-report-model.component';

const MODULES_DECLARED_AND_USED_INTERNALLY = [
  CodeReportBadgeComponent,
  CodeReportSummaryComponent,
  NoVerbosityReportComponent,
  SubcriteriaLabelPipe
];
const MODULES_DECLARED_AND_USED_GLOBALLY = [
  ResumeUploaderComponent,
  GeektrustSampleIoComponent,
  GeektrustProblemStatementComponent,
  GeektrustBadgesScoreComponent,
  GeektrustHelpCenterComponent,
  GeektrustCreditsComponent,
  CodePortfolioBadgeComponent,
  CardSkeletonLoaderComponent,
  ExistingProblemStatementComponent,
  ExperimentalProblemStatementComponent,
  ExistingSampleIoComponent,
  ExperimentalSampleIoComponent,
  LoaderComponent,
  NewReportModelComponent,
  ViewExampleModalComponent,
  CodeReportComponent,
  PaginationComponent,
];
@NgModule({
  imports: [CommonModule, NgxMasonryModule, NgxSkeletonLoaderModule, IconModule, SafeHtmlPipeModule, CodingChallengeCardsModule],
  exports: [...MODULES_DECLARED_AND_USED_GLOBALLY, CodingChallengeCardsModule],
  declarations: [...MODULES_DECLARED_AND_USED_GLOBALLY, ...MODULES_DECLARED_AND_USED_INTERNALLY],
})
export class SharedCommonModules { }
