<div class="help-text">
    <div class="title" *ngIf="selectedSubCriteria && !selectedGenericCriteria">
        {{sbNames[selectedSubCriteria]}}
    </div>
    <div class="text" *ngIf="selectedSubCriteria && !selectedGenericCriteria">
        {{sbHelpTextMsgs[selectedSubCriteria]}}
    </div>
    <div class="title" *ngIf="selectedGenericCriteria && !selectedSubCriteria">
        {{genericCriteriaNames[selectedGenericCriteria]}}
    </div>
    <div class="text" *ngIf="selectedGenericCriteria  && !selectedSubCriteria">
        {{genericHelpTextMsgs[selectedGenericCriteria]}}
    </div>
</div>


<div [ngSwitch]="selectedSubCriteria" *ngIf="selectedSubCriteria && !selectedGenericCriteria">
    <ng-container *ngSwitchCase="'MAGIC_NUMBERS'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of magic numbers in your code.</div>
            <div class="detail" *ngIf="selectedNegativeFinding.fileName && selectedNegativeFinding.value">
                Here's an example -
                <span class="code-excerpt">{{selectedNegativeFinding.fileName}}</span>
                has magic value:
                <span class="code-excerpt">'{{selectedNegativeFinding.value}}'</span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'COMPACT_METHODS'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of methods that are too long in your code.</div>
            <div class="detail" *ngIf="selectedNegativeFinding.fileName && selectedNegativeFinding.value">
                Here's an example -
                <span class="code-excerpt">{{selectedNegativeFinding.fileName}}</span> has following method that is too
                long:
                <span class="code-excerpt">{{selectedNegativeFinding.value}}()</span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'EXPRESSIVE_CODE'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of names that are non expressive.</div>
            <div class="detail" *ngIf="selectedNegativeFinding.fileName && selectedNegativeFinding.value">
                Here's an example -
                <span class="code-excerpt">{{selectedNegativeFinding.fileName}}</span> has following name that is non
                expressive:
                <span class="code-excerpt">{{selectedNegativeFinding.value}}</span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'ENCAPSULATION'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of classes that break encapsulation.</div>
            <div class="detail" *ngIf="selectedNegativeFinding.value">
                Here's an example -
                The following class breaks encapsulation:
                <span class="code-excerpt">{{selectedNegativeFinding.value}}</span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'DRY'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of code duplication.</div>
            <div class="detail" *ngIf="selectedNegativeFinding.fileName && selectedNegativeFinding.value">
                Here's an example - Below code snippet from
                <span class="code-excerpt">{{selectedNegativeFinding.fileName}}</span> is duplicated.
                <span class="code-excerpt">{{selectedNegativeFinding.value}}</span>
            </div>
        </div>
    </ng-container>
</div>

<div [ngSwitch]="selectedGenericCriteria" *ngIf="selectedGenericCriteria  && !selectedSubCriteria">
    <ng-container *ngSwitchCase="'noOfEmptySubClasses'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of 'too many empty subclasses' in your code.
            </div>
            <div class="detail" *ngIf="selectedNegativeFinding.fileName && selectedNegativeFinding.value">
                Here's an example -
                <span class="code-excerpt">{{selectedNegativeFinding.fileName}}</span>
                has class:
                <span class="code-excerpt">'{{selectedNegativeFinding.value}}'</span>
                which has empty subclasses which don't implement any method of their own.
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'noOfMethods'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of 'too many methods in a class' in your code.
            </div>
            <div class="detail" *ngIf="selectedNegativeFinding.fileName && selectedNegativeFinding.value">
                Here's an example -
                <span class="code-excerpt">{{selectedNegativeFinding.fileName}}</span>
                has class:
                <span class="code-excerpt">'{{selectedNegativeFinding.value}}'</span>
                which has too many behavioural methods.
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'noOfParameters'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of 'too many parameters to a method' in your
                code.</div>
            <div class="detail" *ngIf="selectedNegativeFinding.fileName && selectedNegativeFinding.value">
                Here's an example -
                <span class="code-excerpt">{{selectedNegativeFinding.fileName}}</span>
                has method:
                <span class="code-excerpt">'{{selectedNegativeFinding.value}}'</span>
                which has too many parameters.
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'noOfLocalVariables'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of 'too many local variables' in your code.
            </div>
            <div class="detail" *ngIf="selectedNegativeFinding.fileName && selectedNegativeFinding.value">
                Here's an example -
                <span class="code-excerpt">{{selectedNegativeFinding.fileName}}</span>
                has class:
                <span class="code-excerpt">'{{selectedNegativeFinding.value}}'</span>
                which has too many local variables declared.
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'noOfFields'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of 'too many fields in a class' in your code.
            </div>
            <div class="detail" *ngIf="selectedNegativeFinding.fileName && selectedNegativeFinding.value">
                Here's an example -
                <span class="code-excerpt">{{selectedNegativeFinding.fileName}}</span>
                has class:
                <span class="code-excerpt">'{{selectedNegativeFinding.value}}'</span>
                which has too many fields.
            </div>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'dit'">
        <div class="findings-container">
            <div class="detail">We found {{noOfViolations}} instance(s) of 'deep inheritance hierarchy' in your code.
            </div>
            <div class="detail" *ngIf="selectedNegativeFinding.fileName && selectedNegativeFinding.value">
                Here's an example -
                <span class="code-excerpt">{{selectedNegativeFinding.fileName}}</span>
                has class:
                <span class="code-excerpt">'{{selectedNegativeFinding.value}}'</span>
                which has deep inheritance heiranchies.
            </div>
        </div>
    </ng-container>
</div>
<div><button class="close-btn" (click)="closeViewExampleModal()">Close</button></div>