<div class="no-verbosity-report">
    <div class="badges-sections-container">
        <div class="badges-section" *ngIf="report.earnedBadges.length">
            <div class="report-section-title">
                EARNED BADGES ({{report.earnedBadges.length}})
            </div>
            <div class="hr-separator"></div>
            <div class="no-verbosity-badges">
                <div class="no-feedback-badge" *ngFor="let badge of report.earnedBadges">
                    <app-svg-icon name="tick-circle-dark" className="icon-svg icon-size-18" class="result-icon"
                        ariaHidden="false" focusable="false"></app-svg-icon>
                    <div class="badge-title">
                        {{badge.badge.name}}
                    </div>
                </div>
            </div>
        </div>

        <div class="badges-section" *ngIf="report.unEarnedBadges.length">
            <div class="report-section-title">
                UNEARNED BADGES ({{report.unEarnedBadges.length}})
            </div>
            <div class="hr-separator"></div>
            <div class="no-verbosity-badges">
                <div class="no-feedback-badge" *ngFor="let badge of report.unEarnedBadges">
                    <app-svg-icon name="cross-circle" className="icon-svg icon-size-18" class="result-icon"
                        ariaHidden="false" focusable="false"></app-svg-icon>
                    <div class="badge-title">
                        {{badge.badge.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="comments" *ngIf="report.comments.improvements">
        <div class="report-section-title">OVERALL FEEDBACK</div>
        <div class="hr-separator"></div>
        <div class="feedback-text" [innerHTML]="report.comments.improvements"></div>
    </div> -->
</div>