import { Component, Input, OnInit } from '@angular/core';
import { EvaluationReport, VerbosityBadge } from 'src/app/core/models/common.models';


interface BadgeGroup {
  imageName: string;
  earnedCount: string;
  earnedBadgeNames: string[];
  unearnedBadgeNames: string[];
  complexity: string;
  total: number;
}

export interface ReportSummary {
  earnedCount: number;
  unEarnedCount: number;
  badgeTypes: BadgeGroup[];
}


@Component({
  selector: 'app-code-report',
  templateUrl: './code-report.component.html',
  styleUrls: ['./code-report.component.scss']
})
export class CodeReportComponent implements OnInit {
  @Input() report: EvaluationReport;
  summary: ReportSummary;
  badgeComplexityImages = {
    Advanced: 'GoldAdvanceBadge.png',
    Intermediate: 'SilverIntermediateBadge.png',
    Basic: 'BronzeBasicBadge.png'
  };
  isVerbosityPresent = false;


  constructor() { }


  ngOnInit(): void {
    this.report.earnedBadges = this.sortBadges(this.report.earnedBadges);
    this.report.unEarnedBadges = this.sortBadges(this.report.unEarnedBadges);
    this.isVerbosityPresent = this.report.earnedBadges.some(b => !!b.verbosity) || this.report.unEarnedBadges.some(b => !!b.verbosity);
    this.buildSummary();
  }

  private buildSummary() {
    const advanced = this.getSummary('Advanced');
    const intermediate = this.getSummary('Intermediate');
    const basic = this.getSummary('Basic');
    const badgeTypes = [];
    if (advanced.total) {
      badgeTypes.push(advanced);
    }
    if (intermediate.total) {
      badgeTypes.push(intermediate);
    }
    if (basic.total) {
      badgeTypes.push(basic);
    }
    this.summary = {
      earnedCount: this.report.earnedBadges.length,
      unEarnedCount: this.report.unEarnedBadges.length,
      badgeTypes
    };
  }

  private getSummary(complexity: string) {
    const earnedBadgeNames = this.report.earnedBadges.filter((b) => b.badge.complexity === complexity).map((b) => b.badge.name);
    const unearnedBadgeNames = this.report.unEarnedBadges.filter((b) => b.badge.complexity === complexity).map((b) => b.badge.name);
    const result: BadgeGroup = {
      imageName: this.badgeComplexityImages[complexity],
      earnedCount: earnedBadgeNames.length.toString().padStart(2, '0'),
      earnedBadgeNames,
      unearnedBadgeNames,
      complexity: complexity.toLowerCase(),
      total: earnedBadgeNames.length + unearnedBadgeNames.length,
    };
    return result;
  }

  private sortBadges(badges: VerbosityBadge[]) {
    const badgeSortOrder = { Advanced: 1, Intermediate: 2, Basic: 3, };
    return badges.sort((a, b) => badgeSortOrder[a.badge.complexity] - badgeSortOrder[b.badge.complexity]);
  }
}
