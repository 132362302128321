const USER_PROBLEM_URLS = Object.freeze({
  GET_UI_BUILD_CHECK_ERROR_LOGS: `v2/user/{{user_email}}/evaluations/frontend/build/logs/{{submission_id}}`,
  UPLOAD_CODE: `upload/`, // POST, Dynamic Attribute - User Email ID "/upload/{{user_email}}"
  SUBMIT_CODE: `submit/code/user/`, // POST, Dynamic Attribute - User Email ID "submit/code/user/{{user_email}}"
  EVALUATE_CODE: `code/run/tests/`, // POST, Dynamic Attribute - User Email ID "/code/run/tests/{{user_email}}"
  LOAD_SUBMISSION_RUNS: `ace/user/{{user_email}}/fileName/`,
  GET_COMPLETED_EVALUATIONS: `v2/{{user_email}}/evaluations/complete`,
  GET_PORTFOLIOED_EVALUATIONS: `v2/{{user_email}}/evaluations/complete?addedToPortfolio=true`,
  GET_INCOMPLETE_EVALUATIONS: `v2/{{user_email}}/evaluations/incomplete`,
  GET_PORTFOLIO_SUMMARY: `v2/{{user_email}}/codeportfolio/summary`,
  GET_PROBLEM_ATTEMPTED_STATUS: `v2/{{user_email}}/problems/{{problem_code}}/status`,
  DELETE_FROM_PORTFOLIO: `v2/{{user_email}}/codeportfolio/evaluations/{{evaluation_id}}`,
  ADD_TO_PORTFOLIO: `v2/{{user_email}}/codeportfolio/evaluations/{{evaluation_id}}`,
  GET_EVALUATION: `v2/user/{{user_email}}/evaluation/{{evaluation_id}}`,
  DOWNLOAD_CODE: `v2/user/{{user_email}}/download/code/{{fileName}}`,
  RUN_TEST_FEEDBACK: `v2/user/{{user_email}}/runtest/{{fileName}}/feedback/{{criteria}}`,
  CODE_RECOMMENDATION: `v2/user/{{user_email}}/recommendation/{{submission_id}}`,
});

export {
  USER_PROBLEM_URLS
};
