// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseURL: 'https://qa.geektrust.in/api/',
  mixpanelToken: '09a31dd4296852cbc5b796f9123eeae3',
  gaTrackingCode: 'UA-59450480-4',
  googleTagManagerId: '',
  facebookMetaPixelId: '',
  digitalOcean: 'https://geektrust-test.sgp1.digitaloceanspaces.com',
  hmr: false,
  socketServer: 'https://qa.geektrust.in'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
