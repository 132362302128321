const REFERRAL_TERMS_AND_CONDITIONS: string[] = [
    'To participate, only valid Geektrust referral email ids will be considered (no mailinator, spambot, fakeinbox, etc.)',
    'The referred candidate must be new (and not a returning or existing) Geektrust candidate.',
    'Referral links should only be used for personal and non-commercial purposes, publishing them anywhere is not allowed.',
    'We may terminate your eligibility to earn referral gifts or terminate your account if you open multiple accounts in order to generate additional referral credits.',
    // 'Definition of a complete sign up profile – valid personal data (name, email id, phone number), relevant years of experience (1 year and above), no false details and an active profile',
    'The incentive for code submissions will only be received when the referred candidate solves a Geektrust coding challenge (with build package) on their own (no plagiarism allowed) and submits it on the website',
    'The incentives apply only on one code submission per referred candidate. Resubmissions or multiple code submissions from the same candidate will not be counted.',
    'Please note that we follow a monthly cycle for all our payouts/incentives. Hence, if you qualify for one or more incentives in a given month, your incentive will be processed within the first 10 days of the next month.',
    'The referral bonus of Rs 25000 will only be provided only once the referee has spent 3 months at the new job.',
    'The referral bonus will be Rs 25,000 or 30% of the finders fee paid by the company to Geektrust, whichever is lower.',
];

export { REFERRAL_TERMS_AND_CONDITIONS };
