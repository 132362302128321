const badgeCriterias = [
  {
    code: 'PA-READABILITY',
    type: 'readability',
    name: 'Readability',
    complexity: 'Advanced',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-rm.png',
    subBadges: ['READABILITY ML', 'MAGIC NUMBERS', 'COMPACT METHODS', 'EXPRESSIVE CODE'],
  },
  {
    code: 'PA-MAINTAINABILITY',
    type: 'maintainability',
    name: 'Maintainability',
    complexity: 'Advanced',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-maintainability.png',
    subBadges: ['CODE DUPLICATION'],
  },
  {
    code: 'PA-OO',
    type: 'oo',
    name: 'Functional / OO Modelling',
    complexity: 'Advanced',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-oml.png',
    subBadges: ['OOPS ML', 'ENCAPSULATION'],
  },
  {
    code: 'PA-MODULARITY',
    type: 'modularity',
    name: 'Modularity',
    complexity: 'Advanced',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-modularity.png',
    subBadges: [],
  },
  {
    code: 'PA-CORRECTNESS',
    type: 'correctness',
    name: 'Correctness',
    subBadges: [],
    complexity: 'Intermediate',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-correctness.png',
  },
  {
    code: 'PA-TESTS',
    type: 'tests',
    subBadges: [],
    name: 'Tests',
    complexity: 'Intermediate',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-test.png',
  },
  {
    code: 'PA-EXTENSIBILITY',
    type: 'extensibility',
    subBadges: [],
    name: 'Extensible',
    complexity: 'Advanced',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-ui-extensibility.png',
  },
  {
    code: 'PA-BUILD',
    type: 'build',
    name: 'Build',
    subBadges: [],
    complexity: 'Basic',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-build.png',
  },
  {
    code: 'PA-DS',
    type: 'ds',
    subBadges: [],
    name: 'Data Structures',
    complexity: 'Intermediate',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-ds.png',
  },
  {
    code: 'PA-MOBILEFRIENDLINESS',
    type: 'mobileFriendliness',
    subBadges: [],
    name: 'Mobile Responsiveness',
    complexity: 'Intermediate',
    badgeLogo:
      'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-mobileFriendliness.png',
  },
  {
    code: 'PA-SP',
    type: 'performance',
    subBadges: [],
    name: 'Performant',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-performant.png',
  },
  {
    code: 'PA-PERFORMANCE',
    type: 'performance',
    subBadges: [],
    name: 'Performant',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-performant.png',
  },
  {
    code: 'PA-SD',
    type: 'sd',
    subBadges: [],
    name: 'Service Design',
    badgeLogo: 'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-service-design.png',
  },
  {
    code: 'PA-SS',
    type: 'ss',
    subBadges: [],
    name: 'Service Semantics',
    badgeLogo:
      'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/images/badges/badge-para-service-semantics.png',
  },
];
const emojiMap = {
  difficultyLevels: {
    EASY: '🤘',
  },
  modes: {
    BUILDING: '',
    EXTENSION: '',
    REFACTORING: '🛠',
  },
};
export const buildCodingChallenges = (API_RESPONSE, DEFAULT_PROBLEM_CONFIGURATIONS) => {
  const returnValue = API_RESPONSE.filter((challenge) =>
    DEFAULT_PROBLEM_CONFIGURATIONS.map((problem) => problem.problemId).includes(challenge.code)
  ).map((challenge) => {
    let badgeConfigurations = [...badgeCriterias];
    let complexityPercentageCutoff = {
      Advanced: ['Contributes to 60% of final score'],
      Intermediate: ['Contributes to 30% of final score'],
      Basic: ['Contributes to 10% of final score'],
    };
    if (challenge.type !== 'BACKEND') {
      badgeConfigurations = badgeConfigurations.map((config) => ({ ...config, subBadges: [] }));
    }
    if (['BD-PS18-1', 'BD-PS19-1'].includes(challenge.code)) {
      const badgeComplexitiesMap = {
        'PA-READABILITY': 'Advanced',
        'PA-CORRECTNESS': 'Intermediate',
        'PA-OO': 'Advanced',
      };
      badgeConfigurations = badgeConfigurations.map((config) => ({
        ...config,
        complexity: badgeComplexitiesMap[config.code],
      }));
      complexityPercentageCutoff = {
        Advanced: [
          'Readability contributes to 60% of final score',
          'Object Modelling contributes to 10% of final score',
        ],
        Intermediate: ['Contributes to 30% of final score'],
        Basic: ['Contributes to 0% of final score'],
      };
    }
    return {
      ...DEFAULT_PROBLEM_CONFIGURATIONS.find((problem) => problem.problemId === challenge.code),
      ...challenge,
      problemDifficultyEmoji: emojiMap.difficultyLevels[challenge.difficultyLevel],
      problemModeEmoji: emojiMap.modes[challenge.problemMode],
      observationStatus: {
        isCurrentlyBeingWatchedByCompanies: challenge.hasOwnProperty('companiesWatching'),
        watchedBy: challenge.companiesWatching?.watchedBy,
        duration: {
          startsOn: challenge.companiesWatching?.startDate,
          endsBy: challenge.companiesWatching?.endDate,
          startCountDownBy: {
            unit: 'days',
            value: 30,
          },
        },
      },
      badgesAndScoring: {
        badges: {
          descriptionLines: [
            'This problem is scored out of 100. You need to score a minimum of 30/100 to have your profile and code forwarded to most companies on Geektrust.',
            'There are multiple badges you can earn with your solution. We (and the companies that partner with us) value some badges more than others, and hence they contribute to a larger part of your score.',
            'Pro tip: Aim to get readability and Input/Output, the rest can be improved upon later!',
          ],
          tableData: [
            {
              skillLevel: 'Advanced',
              description: complexityPercentageCutoff.Advanced,
              badges: badgeConfigurations
                .filter((badge) => badge.complexity === 'Advanced' && challenge.badgeCodes?.includes(badge.code))
                .map((badge) => ({
                  ...badge,
                  badgeName: badge.name,
                })),
            },
            {
              skillLevel: 'Intermediate',
              description: complexityPercentageCutoff.Intermediate,
              badges: badgeConfigurations
                .filter((badge) => badge.complexity === 'Intermediate' && challenge.badgeCodes?.includes(badge.code))
                .map((badge) => ({
                  ...badge,
                  badgeName: badge.name,
                })),
            },
            {
              skillLevel: 'Basic',
              description: complexityPercentageCutoff.Basic,
              badges: badgeConfigurations
                .filter((badge) => badge.complexity === 'Basic' && challenge.badgeCodes?.includes(badge.code))
                .map((badge) => ({
                  ...badge,
                  badgeName: badge.name,
                })),
            },
          ],
        },
      },
    };
  });
  return returnValue;
};
