const NEW_APPLICATION_FLOW_URLS = {
    UPSERT_APLLICATION: 'v2/user/{{user_email}}/application/upsert',
    RETREIVE_LATEST_INCOMPLETE_APPLICATION: 'v2/user/{{user_email}}/application/incomplete',
    CHECK_EXISTING_APPLICATION: 'v2/user/{{user_email}}/application/complete/', // {{regNo}}
    GET_MANDATORY_MISSING_FIELDS: 'v2/user/{{user_email}}/application/fields/mandatory/', // {{jobRoleId}}
    GET_OUTDATED_FIELDS: 'v2/user/profile/{{user_email}}/fields/outdated/',
};

export {
    NEW_APPLICATION_FLOW_URLS
};

