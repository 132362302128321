import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import {
  SubBadgeHelpTextMessages,
  SubBadgeNamesMap,
  GenericCriteriaHelpTextMessages,
  GenericCriteriaMap
} from 'src/app/features/dashboard-workflow/coding/constants/rich-feedback.constants';

@Component({
  selector: 'app-view-example-modal',
  templateUrl: './view-example-modal.component.html',
  styleUrls: ['./view-example-modal.component.scss'],
})
export class ViewExampleModalComponent implements OnInit {

  constructor() {}

  @Input() selectedSubCriteria: string;
  @Input() selectedNegativeFinding: any;
  @Input() selectedGenericCriteria: string;
  @Input() noOfViolations: number;
  @Input() viewExampleModalRef: BsModalRef;
  @Output() closedModal = new EventEmitter();

  sbHelpTextMsgs = SubBadgeHelpTextMessages;
  sbNames = SubBadgeNamesMap;
  genericHelpTextMsgs = GenericCriteriaHelpTextMessages;
  genericCriteriaNames = GenericCriteriaMap;

  ngOnInit(): void {
  }

  closeViewExampleModal() {
    this.viewExampleModalRef.hide();
    this.closedModal.emit(true);
  }
}
