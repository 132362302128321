import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RippleDirective } from './rippleEffect/ripple.directive';
import { PopoverDirective } from './popover/popover.directive';
import { DropdownMenuDirective } from './dropdown-menu/dropdown-menu.directive';

@NgModule({
  declarations: [RippleDirective, PopoverDirective, DropdownMenuDirective],
  imports: [
    CommonModule,
  ],
  exports: [
    RippleDirective,
    PopoverDirective,
    DropdownMenuDirective,
  ]
})
export class InteractionsModule {}
