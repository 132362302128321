import { NgModule } from '@angular/core';
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
  imports: [
    // dep modules
  ],
  declarations: [ 
    SafeHtmlPipe
  ],
  exports: [
    SafeHtmlPipe
  ]
})
export class SafeHtmlPipeModule {}