<div class="app-l-content">
  <router-outlet #outlet="outlet"></router-outlet>
</div>

<!-- svgs -->
<app-icons></app-icons>
<app-toaster class="toaster"></app-toaster>
<geektrust-nudge-user *ngIf="false" class="nudge-user-popup"></geektrust-nudge-user>
<!-- Modal -->
<ng-template #dashboardRedirectionModal>
  <div class="modal-body dashboard-redirection-modal">
    <div class="redirection-image-container">
      <lottie-player class="redirection-image" src="https://assets5.lottiefiles.com/packages/lf20_xbf1be8x.json"
        background="transparent" speed="1" loop autoplay></lottie-player>
    </div>
    <!-- <h3 class="redirection-title">Finding personalised job recommendations...</h3>
    <p class="app-l-description">Thank you for creating your profile. We'll show you the curated jobs soon.</p>
    -->
    <h3 class="redirection-title">
      Preparing your customised job dashboard...
    </h3>

    <div class="app-l-description description-text">
      We'll show you curated jobs soon.
    </div>
    <div class="public-preference" *ngIf="showPrompt">
      <div class="while-you-wait">While you wait,</div>
      <div class="public-preference-text-container">
        <div class="public-preference-text">
          Would you like to automatically share your profile with all matching
          opportunities/companies?
        </div>

        <div class="toggle-container">
          <geektrust-switch [inputFormControl]="shareProfileWithCompanies" (toggle)="onTooglePublicPreference($event)"
            class="toggle-bar"></geektrust-switch>
        </div>
      </div>
      <div class="public-preference-subtext-container" *ngIf="!shareProfileWithCompanies.value; else elseBlock">
        <div>
          <img src="https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets%2Fimages%2FLight-bulb.webp" alt=""
            class="light-bulb">
        </div>
        <div class="public-preference-subtext">
          Turning on this setting increases your chances of finding a job by upto <span class="percentage">50%.</span>
        </div>
      </div>
      <ng-template #elseBlock>
        <div class="public-preference-subtext-container">
          <div>
            <img src="https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets%2Fimages%2FThumb.webp" alt=""
              class="light-bulb">
          </div>
          <div class="public-preference-subtext">
            Great! We'll start sharing your profile with amazing companies soon.
          </div>
        </div>
      </ng-template>

    </div>
    <!-- <p class="app-l-description redirection-counter">You’ll be redirected to the dashboard in <b class="bold"
        id="redirection-countdown">00:05</b> seconds.</p> -->
    <div class="app-l-btn-group">
    </div>
  </div>
</ng-template>