export const ALLOWED_EXTENSIONS = [
    {
        activity: 'UPLOAD_RESUME',
        fileTypes: [
            'application/doc',
            'application/ms-doc',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/pdf',
            'text/plain',
            'application/rtf',
            'text/rtf'
        ]
    }
];
