import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MatcherRerunningGuard } from './core/guards/matcher-rerunning.guard';
import { SocialAuthComponent } from './core/shared-components/social-auth/social-auth.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./features/home/home.module').then((m) => m.HomeModule),
    canActivate: [MatcherRerunningGuard],
  },
  {
    path: 'ui-components',
    loadChildren: () =>
      import('./ui-components/ui-components.module').then(
        (m) => m.UiComponentsModule
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/signup/signup.module').then((m) => m.SignupModule),
    canActivate: [MatcherRerunningGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./features/dashboard-workflow/dashboard-workflow.module').then(
        (m) => m.DashboardWorkflowModule
      ),
    canActivate: [MatcherRerunningGuard],

  },
  {
    path: '',
    loadChildren: () => import('./features/isolated-components/isolated-components.module').then((m) => m.IsolatedComponentsModule),
  },
  { path: 'social-auth', component: SocialAuthComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
