<div
  class="toaster-wrapper toaster-container toaster-info show-toast toast-middle"
  *ngFor="let item of toaster; let i = index"
  [@trigger]
  (@trigger.done)="displayEvent(i, $event)"
  [ngClass]="{
    'toaster-info': item.state == 'info',
    'toaster-warning': item.state == 'warning',
    'toaster-success': item.state == 'success',
    'toaster-error': item.state == 'error'
  }"
>
  <div class="toaster-level-m" [ngSwitch]="item.state">
    <app-svg-icon
      *ngSwitchCase="'success'"
      name="success"
      title="success"
      className="icon-svg icon-size-20"
      ariaHidden="false"
      focusable="false"
    ></app-svg-icon>
  </div>
  <div class="toaster-level-m toaster-message">
    <div class="toaster-message--title">{{ item.title }}</div>
  </div>
  <div class="toaster-action">
    <button
      ripple
      class="app-btn toaster-action--btn"
      (click)="closeToast($event, i)"
      title="close"
    >
      <app-svg-icon
        name="close"
        title="close"
        className="icon-svg icon-size-20"
        ariaHidden="false"
        focusable="false"
      ></app-svg-icon>
    </button>
  </div>
</div>
