import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GTEvent, UserEvent } from '../models/user.model';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(
    private httpService: HttpService,
    private authService: AuthService
  ) {}

  getAllUpcomingEvents(): Observable<GTEvent[]> {
    return this.httpService.get<GTEvent[]>('v2/events');
  }

  getUserEventRegistrations(): Observable<UserEvent> {
    const email = this.authService.loggedInUser.email;
    return this.httpService.get<UserEvent>(`user/events/signup/${email}`);
  }

  updateUserEvent(userEvent: UserEvent): Observable<any> {
    const email = this.authService.loggedInUser.email;
    return this.httpService.post<any>(`user/events/signup/${email}`, userEvent);
  }

  sendEventRegistrationEmail(eventName: string) {
    const email = this.authService.loggedInUser.email;
    return this.httpService.put<any>(
      `events/${eventName}/send/mail/${email}`,
      {}
    );
  }
}
