import * as moment from 'moment';
import { omit } from 'ramda';
import { fomatYrsOfExperience, formatWorkLifeExpectations } from '../../../core/utilities';
import { UserProfile } from '../../models/user-profile.model';
import { MIXPANEL_FIELD_UPDATES } from './user-profile.constants';

// TODO: Implement funciton to check wheather the profile has changed by comparing it with old profile before raising mix panel events

const getMixPanelPropertiesToBeTracked = (values: Partial<UserProfile>, userProfile: Partial<UserProfile>) => {
  const properties = [];
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      switch (key) {
        case 'tech':
          for (const nestedKey in values.tech) {
            if (values.tech.hasOwnProperty(nestedKey)) {
              switch (nestedKey) {
                case 'knowWell':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.tech.knowWell]:
                      values?.tech?.knowWell?.map((technology) => ({
                        name: technology?.name,
                        skillLevel: technology?.skillLevel,
                        yearsOfExperience: technology?.yrsOfExp?.year + technology?.yrsOfExp?.month / 12
                      })),
                  });
                  break;
                case 'additional':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.tech.additional]:
                      values?.tech?.additional?.map(({ name }) => ({ name })),
                  });
                  break;
                case 'wantToWork':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.tech.wantToWork]:
                      values?.tech?.wantToWork?.map(({ name }) => ({ name })),
                  });
                  break;
              }
            }
          }
          break;
        case 'urls':
          for (const nestedKey in values.urls) {
            if (values.urls.hasOwnProperty(nestedKey)) {
              switch (nestedKey) {
                case 'linkedIn':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.urls.linkedIn]:
                      values.urls.linkedIn,
                  });
                  break;
                case 'opensource':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.urls.opensource]:
                      values.urls.opensource,
                  });
                  break;
                case 'others':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.urls.others]:
                      values?.urls?.others?.map(({ type, link }) => ({ type, link })),
                  });
                  break;
              }
            }
          }
          break;
        case 'domain':
          for (const nestedKey in values.domain) {
            if (values.domain.hasOwnProperty(nestedKey)) {
              switch (nestedKey) {
                case 'wantToWork':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.domain.wantToWork]:
                      values?.domain?.wantToWork?.map(({ name }) => ({ name })),
                  });
                  break;
              }
            }
          }
          break;
        case 'employmentTimeline':
          properties.push({
            [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.employmentTimeline]: values?.employmentTimeline?.map((
              { employer, role, present, durationStart, durationEnd }) => ({
                employer,
                role,
                present,
                durationStart: omit(['_id'], durationStart),
                durationEnd: omit(['_id'], durationEnd)
              }))
          });
          break;
        case 'educationTimeline':
          properties.push({
            [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.educationTimeline]: values?.educationTimeline?.map((
              { university, courseType, courseName, durationStart, durationEnd }) => ({
                university,
                courseType,
                courseName,
                durationStart: omit(['_id'], durationStart),
                durationEnd: omit(['_id'], durationEnd)
              }))
          });
          break;
        case 'yrsOfExperience':
          properties.push({
            [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.yrsOfExperience]: fomatYrsOfExperience(values?.yrsOfExperience)
          });
          break;
        case 'preferredLocations':
          properties.push({
            [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.preferredLocations]: values[key]?.map(item => ({ name: item.name }))
          });
          break;
        case 'workLifeExpectations':
          properties.push({
            [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.workLifeExpectations]: formatWorkLifeExpectations(values?.workLifeExpectations)
          });
          break;
        case 'noticePeriodInfo':
          for (const nestedKey in values?.noticePeriodInfo) {
            if (values.noticePeriodInfo.hasOwnProperty(nestedKey)) {
              switch (nestedKey) {
                case 'noticePeriod':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.noticePeriodInfo.noticePeriod]:
                      values?.noticePeriodInfo?.noticePeriod,
                  });
                  break;
                case 'earliestJoiningDate':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.noticePeriodInfo.earliestJoiningDate]:
                      moment(values?.noticePeriodInfo?.earliestJoiningDate).format('LL'),
                  });
                  break;
                case 'servingNoticePeriod':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.noticePeriodInfo.servingNoticePeriod]:
                      values?.noticePeriodInfo?.servingNoticePeriod,
                  });
                  break;
              }
            }
          }
          break;
        case 'salary':
          for (const nestedKey in values?.salary) {
            if (values?.salary?.hasOwnProperty(nestedKey)) {
              switch (nestedKey) {
                case 'currentVariable':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.salary.currentVariable]:
                      values?.salary?.currentVariable ? values?.salary?.currentVariable + ' LPA' : ''
                  });
                  break;
                case 'current':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.salary.current]:
                      values?.salary?.current ? values?.salary?.current + ' LPA' : ''
                  });
                  break;
                case 'expected':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.salary.expected]:
                      values?.salary?.expected ? values?.salary?.expected + ' LPA' : '',
                  });
                  break;
                case 'remarks':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.salary.remarks]:
                      values?.salary?.remarks,
                  });
              }
            }
          }
          break;
        case 'communicationChannel':
          for (const nestedKey in values.communicationChannel) {
            if (values.communicationChannel.hasOwnProperty(nestedKey)) {
              switch (nestedKey) {
                case 'preferredChannel':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.communicationChannel.preferredChannel]:
                      values?.communicationChannel?.preferredChannel
                  });
                  break;
                case 'mobileNumber':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.communicationChannel.mobileNumber]:
                      values?.communicationChannel?.mobileNumber
                  });
                  break;
                case 'countryCode':
                  properties.push({
                    [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.communicationChannel.countryCode]:
                      values?.communicationChannel?.countryCode
                  });
                  break;
              }

            }
          }
          break;
        default:
          /**
           * String / Number properties including
           *  --> email, remoteWork, outsideIndiaWork, membership, active, publicProfile, jobSearchStatus, fullName, primarySkill
           *  --> location, phoneCountryCode, phoneNumber, preferredLocation, preferredPrimaryRole
           */
          properties.push({
            [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS[key]]: values[key],
          });
          break;
      }
    }
  }
  properties.push({ 'User Email ID': userProfile?.email });
  properties.push({ 'User Job Search Status': userProfile?.jobSearchStatus });
  return properties.reduce((accumulatedValues, currentValue) => ({ ...accumulatedValues, ...currentValue }), {});
};

export { getMixPanelPropertiesToBeTracked };
