import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnChanges {
  @Input() pageSize: number;
  @Input() totalItems: number;
  @Input() selectedPage: number;
  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  totalPages = 0;
  rest = '...';
  showPagination: boolean;
  constructor() { }

  get pagesToShow() {
    if (this.totalPages <= 7) {
      return Array.from({ length: this.totalPages }, (_, i) => i + 1);
    }

    const paginationSize = 5;
    let pages;
    if (this.selectedPage < paginationSize) {
      pages = [
        ...Array.from({ length: paginationSize }, (_, i) => i + 1),
        this.rest,
        this.totalPages
      ];
    } else if (this.selectedPage > (this.totalPages - paginationSize + 1)) {
      pages = [
        1,
        this.rest,
        ...Array.from({ length: 5 }, (_, i) => (this.totalPages - paginationSize) + i + 1)
      ];
    } else {
      pages = [
        1,
        this.rest,
        this.selectedPage - 1,
        this.selectedPage,
        this.selectedPage + 1,
        this.rest,
        this.totalPages,
      ];
    }
    return pages;
  }

  ngOnInit(): void {
    this.calculateTotalPage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.calculateTotalPage();
  }

  calculateTotalPage() {
    this.totalPages = Math.ceil(this.totalItems / this.pageSize) || 0;
    this.showPagination = this.totalPages > 1;
  }

  isPreviousDisabled() {
    return this.selectedPage <= 1;
  }

  isNextDisabled() {
    return this.selectedPage >= this.totalPages;
  }

  isActive(pageNo: number) {
    return this.selectedPage === pageNo;
  }

  selectPage(pageNo) {
    if (pageNo === this.rest) {
      return;
    }
    this.pageChange.emit(pageNo);
  }

  next() {
    this.pageChange.emit(this.selectedPage + 1);
  }

  previous() {
    this.pageChange.emit(this.selectedPage - 1);
  }
}
