import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { GACategory } from '../../models/enums';

@Injectable({
  providedIn: 'root',
})
export class GAService {
  optOutGA = 'ga-disable-' + environment.gaTrackingCode;
  constructor(private gaService: GoogleAnalyticsService, private cookieService: CookieService) {
    if (this.cookieService.hasKey(this.optOutGA) && this.cookieService.get(this.optOutGA) === 'true') {
      window[this.optOutGA] = true;
    }
  }

  setupGoogleTagManger() {
    if (!environment.googleTagManagerId) {
      return;
    }
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${environment.googleTagManagerId}');`;
    document.head.prepend(gtmScript);

    const gtmNoScript = document.createElement('noscript');
    gtmNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.googleTagManagerId}"
    height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.prepend(gtmNoScript);
  }

  trackAppView(screen: string, appName: string, appId?: string, appVersion?: string, installerId?: string): void {
    this.gaService.appView(screen, appName, appId, appVersion, installerId);
  }

  trackEvent(action: string, category?: GACategory, label?: string, value?: number, interaction?: boolean): void {
    this.gaService.event(action, category, label, value, interaction);
  }

  trackPageView(path: string, title?: string, location?: string, options?: any): void {
    this.gaService.pageView(path, title, location, options);
  }

  trackException(description?: string, fatal?: boolean): void {
    this.gaService.exception(description, fatal);
  }

  GTag(...parameters: any[]): void {
    this.gaService.gtag(parameters);
  }

  setValues(...parameters: any[]): void {
    this.gaService.set(parameters);
    this.gaService.gtag('config', 'USER_ID', parameters);
  }

  optOutTracking(): void {
    this.cookieService.put(this.optOutGA, 'true');
    window[this.optOutGA] = true;
  }

  optInTracking(): void {
    this.cookieService.put(this.optOutGA, 'false');
    window[this.optOutGA] = false;
  }
}
