<ngx-masonry class="app-c-card__maincontainer" [options]="masonryOptions">
    <div ngxMasonryItem *ngFor="let item of [1,2,3,4,5,6,7,8]" class="app-c-ngx-masonry-wrapper">
      <div class="app-c-card__item">
        <div class="app-c-card-inner">
          <div class="app-c-card-header">
            <div class="app-c-logo-wrap">
              <ngx-skeleton-loader appearance="circle" [theme]="{ width: '55px', height: '55px', 'border-radius': '8px' }">
              </ngx-skeleton-loader>
            </div>
            <div class="app-c-header-wrap">
              <ngx-skeleton-loader [theme]="{ width: '120px', 'border-radius': '0', height: '15px' }">
              </ngx-skeleton-loader>
              <ngx-skeleton-loader [theme]="{ width: '90px', 'border-radius': '0', height: '15px' }">
              </ngx-skeleton-loader>
            </div>
          </div>
          <div class="app-c-card-body">
            <ngx-skeleton-loader [theme]="{ width: '120px', 'border-radius': '0', height: '15px' }">
            </ngx-skeleton-loader>
            <div class="app-c-card__item-details">
              <ngx-skeleton-loader [theme]="{ width: '30%', 'border-radius': '0', height: '15px' }">
              </ngx-skeleton-loader>
              <ngx-skeleton-loader [theme]="{ width: '40%', 'margin-left': '5%', 'border-radius': '0', height: '15px' }">
              </ngx-skeleton-loader>
            </div>
          </div>
          <ngx-skeleton-loader [theme]="{ 'margin-top': '5%', 'border-radius': '0', height: '15px' }">
          </ngx-skeleton-loader>
        </div>
      </div>
    </div>
  </ngx-masonry>