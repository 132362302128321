import { Pipe, PipeTransform } from '@angular/core';
import { SubcriteriaLabelMapping } from 'src/app/core/models/enums';

@Pipe({
  name: 'subcriteriaLabel'
})
export class SubcriteriaLabelPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return SubcriteriaLabelMapping[value];
  }

}
