import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserProfile } from '../models/user-profile.model';
import { UserProfileService } from '../services/user-profile/user-profile.service';
import { isMildOnboardingRequired } from '../utilities/user-profile-checks.utlity';

@Injectable()
export class ShowLaunchAnnouncementGuard implements CanActivate {
  constructor(private router: Router, private userProfileService: UserProfileService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userProfileService
      .getUserProfile(this.userProfileService.loggedInUser.email)
      .pipe(
        map((userProfile: UserProfile) => {
          const userNeedsToBeOnboardingToTheNewExperience = isMildOnboardingRequired(userProfile);
          if (userNeedsToBeOnboardingToTheNewExperience) {
                this.userProfileService.redirectAfterCollectingDetails = state.url
                this.router.navigateByUrl('show-launch-announcement');
                return false;
              }
              return true;
        })
      );
  }
}
