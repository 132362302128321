import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PassiveCompletionPageComponent } from './passive-completion-page/passive-completion-page.component';
import { ChooseWorkflowComponent } from './choose-workflow/choose-workflow.component';
import { RouterModule } from '@angular/router';
import { SharedCommonModules } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PassiveUserBasicsComponent } from './user-basics/user-basics.component';
import { CoreModule } from 'src/app/core/core.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProfileFormsModule } from 'src/app/shared/profile-forms/profile-forms.module';

@NgModule({
  declarations: [
    PassiveCompletionPageComponent,
    ChooseWorkflowComponent,
    PassiveUserBasicsComponent,
  ],
  imports: [
    CommonModule,
    SharedCommonModules,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    CoreModule,
    NgSelectModule,
    ProfileFormsModule,
  ],
  exports: [
    PassiveCompletionPageComponent,
    PassiveUserBasicsComponent,
    ChooseWorkflowComponent,
    ReactiveFormsModule,
    FormsModule,
  ],
})
export class SharedModule {}
