<div class="app-batch-and-score">
  <div class="app-batch-layout-content">
    <div class="batch-content">
      <div class="batch-content-detail">
        <div [ngClass]="{'content-portfolio': !first}"
          *ngFor="let line of badgesAndScoring?.badges?.descriptionLines; let first = first;">
          {{line}}
        </div>
      </div>
    </div>
  </div>
  <div class="app-batch-layout-score">
    <div class="score-heading">Score</div>
    <div class="app-c-table--dynamic">
      <table class="app-c-table-problem-statement">
        <tr>
          <th> Badge </th>
          <th> Contributions toward Final Score </th>
        </tr>
        <ng-container *ngFor="let badgeData of badgesAndScoring?.badges?.tableData">

          <tr *ngIf="badgeData?.badges?.length" class="app-c-border-cstm">
            <td class="badge-cell">
              <div class="badge-flex">
                <div class="flex advancedset">
                  <div *ngFor="let badge of badgeData?.badges" class="badge-container">
                    <div class="master-badge">
                      <img [src]="badge.badgeLogo">
                    </div>
                    <div class="sub-badges-container" *ngIf="badge.subBadges?.length">
                      <div class="sub-badge {{badge.complexity}}" *ngFor="let subBadge of badge.subBadges">
                        {{subBadge}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="data-cell">
              <p class="description" *ngFor="let descLines of badgeData?.description">
                {{descLines}}
              </p>
            </td>
          </tr>
        </ng-container>
      </table>

    </div>
    <div class="score-heading" *ngIf="badgesAndScoring?.badges?.hasNotes">
      <div *ngFor="let note of badgesAndScoring?.badges?.notes">
        {{note}}
      </div>
    </div>
  </div>
</div>