<div [ngClass]="{'dark-theme': theme === 'dark'}" class="app-l-tab-slider--nav">
    <ul class="app-l-tab-slider--tabs">
        <li class="app-l-tab-slider--trigger" [class.active]="viewMode == 'tab1'" rel="tab1"
            (click)="switchingData('tab1', {}, true)">
            <span class="app-l-menu-item"> Backend</span>
            <span class="app-l-item-number">{{itemCount(masonryItemsBackend.length)}}</span>
        </li>
        <li class="app-l-tab-slider--trigger" [class.active]="viewMode == 'tab2'" rel="tab2"
            (click)="switchingData('tab2', {}, true)">
            <span class="app-l-menu-item"> Frontend</span>
            <span class="app-l-item-number">{{itemCount(masonryItemsFrontend.length)}}</span>
        </li>
        <li class="app-l-tab-slider--trigger" [class.active]="viewMode == 'tab3'" rel="tab3"
            (click)="switchingData('tab3', {}, true)">
            <span class="app-l-menu-item"> Mobile</span>
            <span class="app-l-item-number">
                {{itemCount(masonryItemsMobile.length)}}
            </span>
        </li>
    </ul>
</div>
<div class="line-separator" [ngClass]="{'dark-theme': theme === 'dark'}"></div>
<div class="app-l-tab-slider--container" [ngSwitch]="viewMode">
    <!----------------------------------------------Listing section----------------------------------------------->
    <div id="tab1" class="app-l-tab-slider--body">
        <app-challenge-filter-row [selectedFilters]="filters" [challenges]="tabToCommonListMapping[viewMode]"
            [theme]="theme" (filtersChanged)="onFiltersChanged($event)">
        </app-challenge-filter-row>
        <div class="app-l-card-outer__wrapper">

            <ngx-masonry [ngClass]="{'dark-theme': theme === 'dark'}" class="app-c-card__maincontainer"
                *ngIf="commonList.length > 0" [options]="masonryOptions" [updateLayout]="updateMasonryLayout"
                [ordered]="true" (itemsLoaded)="itemsLoaded()">
                <div ngxMasonryItem *ngFor="let item of commonList; let i = index;" [ngClass]="layoutClasses"
                    class="app-c-ngx-masonry-wrapper app-c-masonary-code app-l-width--onebyfour"
                    (mouseover)="reloadMasonryLayout()" (mouseout)="reloadMasonryLayout()">
                    <app-coding-challenge-card [detailsPath]="detailsPath" [theme]="theme" [index]="i" [item]="item">
                    </app-coding-challenge-card>
                    <div (mouseenter)="onMouseEnter($event)" (mouseleave)="onMouseLeave($event)"
                        class="extra-notes-container"
                        *ngIf="item.code === indexOfCodingChallengeCardShowingNote && item.extraNote && indexOfCodingChallengeCardShowingNote !== -1">
                        <div class="extra-notes-template">
                            <div class="extra-notes-content">
                                <div class="extra-notes-icon">
                                    <img src="https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/v2/problems/common/extra-note.webp"
                                        alt="extra-note-significance" />
                                </div>
                                <div class="extra-notes-text">
                                    {{commonList[i].extraNote}}
                                </div>
                            </div>
                        </div>
                        <div class="extra-notes-progress-bar">
                            <div class="extra-notes-progress-thumb"></div>
                        </div>
                    </div>
                </div>
            </ngx-masonry>
            <div class="app-l-empty__data" *ngIf="commonList.length === 0">
                <div class="app-l-empty__indication">
                    <img src="../../../../assets/images/preloader.gif" alt="loader" class="preloader">
                </div>
                <h3>Loading Coding Challenges</h3>
                <p>This may take a minute</p>
            </div>
        </div>
    </div>
</div>