import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ProblemStatement } from 'src/app/core/models/coding-challenges.model';
import { UserActivityTrackingService } from 'src/app/core/services/user-activity-tracking/user-activity-tracking.service';

@Component({
  selector: 'geektrust-experimental-problem-statement',
  templateUrl: './experimental-problem-statement.component.html',
  styleUrls: ['./experimental-problem-statement.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExperimentalProblemStatementComponent implements OnInit {
  @Input() problemStatement: Partial<ProblemStatement> = {};
  @Input() allowDownloadStarterKit = true;
  @Input() detailedChallenge: Partial<any> = {};
  isExperimenting = false;
  constructor(private userActivityTrackingService: UserActivityTrackingService) {}

  ngOnInit(): void {}

  buttonClickAction(url): void {
    this.userActivityTrackingService.trackMixPanelEvent('User downloaded refactoring problem.', {
      'Coding Challenge': this.detailedChallenge.challengeSlug,
      'Coding Challenge Title': this.detailedChallenge.title,
      'Coding Challenge Type': this.detailedChallenge.type,
      challenge: this.detailedChallenge,
      ...(this.detailedChallenge.observationStatus?.isCurrentlyBeingWatchedByCompanies
        ? {
            experimentName: this.detailedChallenge.observationStatus?.experiment,
          }
        : {}),
    });
    window.open(url, '_blank');
  }
}
