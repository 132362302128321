import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { LoginCredentials, User } from '../models/user.model';
import { AUTH_URLS, USER_PROFILE_DATA_URLS } from '../constants/urls';
import { HttpService } from './http.service';
import { MixPanelService } from './user-activity-tracking/mix-panel.service';
import { environment } from 'src/environments/environment';
import { GACategory, SignupVia } from '../models/enums';
import { FacebookPixelService, FA_EVENTS } from './user-activity-tracking/facebook-pixel.service';
import { GA_EVENTS } from './user-activity-tracking/ga-events';
import { GAService } from './user-activity-tracking/google-analytics.service';
import { MIXPANEL_FIELD_UPDATES } from './user-profile/user-profile.constants';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<Partial<User>> = new BehaviorSubject({});
  public currentUser: Observable<Partial<User>>;
  public loginRedirectURL: string;
  public signupVia = '';
  private baseURL: string = environment.apiBaseURL;
  userTriedToDownloadStarterKit = false;
  constructor(
    private httpService: HttpService,
    private mixpanel: MixPanelService,
    private gaService: GAService,
    private facebookPixelService: FacebookPixelService,
    @Inject(PLATFORM_ID) private platformID: object,
    public cookieService: CookieService,
    private router: Router,
    private title: Title
  ) {
    this.mixpanel.init();
    const user = this.cookieService.getObject('authenticatedUser') as User;
    this.currentUserSubject = new BehaviorSubject(user);
    this.currentUser = this.currentUserSubject.asObservable();
    if (user?.email) {
      this.mixpanel.identify(user);
      this.gaService.setValues({
        user_id: user.regNo?.toString() || '',
      });
    }
  }

  public get isLoggedIn(): boolean {
    return this.currentUserSubject.value != null;
  }

  public get loggedInUser(): Partial<User> {
    return this.currentUserSubject.value;
  }

  public get lastLogedInUser(): Partial<User> {
    return this.cookieService.getObject('lastLoggedInUser') as User;
  }

  public saveInCookies(key: string, data: any) {
    let domainOptions = {};
    if (environment.apiBaseURL.includes('qa')) {
      domainOptions = { path: '/', domain: '.geektrust.in' };
    }
    this.cookieService.putObject(key, data, domainOptions);
  }

  login(credentials: LoginCredentials) {
    return this.httpService.post<User>(AUTH_URLS.SIGN_IN, credentials).pipe(
      tap((response) => {
        const user = response;
        this.saveInCookies('authenticatedUser', user);
        this.saveInCookies('lastLoggedInUser', user);
        this.currentUserSubject.next(user);
        this.mixpanel.identify(user);
        this.mixpanel.track(MIXPANEL_FIELD_UPDATES.MESSAGES.LOGGED_IN, { email: user.email, provider: 'local' });
        this.gaService.setValues({
          user_id: user.regNo?.toString() || '',
        });
        this.gaService.trackPageView(this.router.url, this.title.getTitle(), window.location.pathname, {
          user_id: user.regNo?.toString() || '',
        });
        this.gaService.trackEvent(GA_EVENTS.LOGIN, GACategory.Login);
      })
    );
  }

  consumerlogin(authConsumer: string) {
    return this.httpService.get<any>(`${AUTH_URLS.CONSUMER_AUTH}?auth_consumer=${authConsumer}`);
  }

  isAuthenticated() {
    return this.httpService.post<User>(AUTH_URLS.IS_LOGGED_IN, {}).pipe(
      tap((user) => {
        this.gaService.setValues({
          user_id: user.regNo?.toString() || '',
        });
      })
    );
  }

  verify(email: string) {
    this.isAuthenticated().subscribe(
      (response) => {
        if (response.email !== email) {
          this.router.navigateByUrl('');
          return;
        }
        const user = response;
        this.saveInCookies('authenticatedUser', user);
        this.saveInCookies('lastLoggedInUser', user);
        this.currentUserSubject.next(user);
        this.mixpanel.identify(user);
        this.mixpanel.track('Verified email');
      },
      (err) => {
        this.router.navigateByUrl('');
      }
    );
  }

  signup(request: any) {
    request.via = this.signupVia || SignupVia.Normal;
    const refToken = this.cookieService.get('refToken');
    if (refToken) {
      request.refToken = refToken;
      request.via = SignupVia.Refer;
    }
    const newsLetters = request.newsLetters;
    delete request.newsLetters;
    const communicationPreferences = {
      newsLetters,
      eventAnnouncements: true,
      companyRecommendations: true,
      surveysAndFeedbacks: true,
      codingChallenges: true,
      referralProgram: true,
    };
    return this.httpService.post(AUTH_URLS.SIGN_UP, request).pipe(
      tap(() => {
        const user = { name: request.name, email: request.email };
        this.saveInCookies('authenticatedUser', user);
        this.saveInCookies('lastLoggedInUser', user);
        this.currentUserSubject.next(user);
        this.mixpanel.identify(user);
        this.httpService
          .post(`${USER_PROFILE_DATA_URLS.COMMUNICATION_PREFERENCES_CREATE_URL}?email=${this.loggedInUser.email}`, {
            communicationPreferences,
            communicationChannel: {
              whatsapp: 'Off',
              preferredChannel: '',
              countryCode: '',
              mobileNumber: '',
            },
          })
          .pipe(retry(2))
          .subscribe(() => {});
        this.mixpanel.track(MIXPANEL_FIELD_UPDATES.MESSAGES.GENERAL_USER.NEW_USER_SIGN_UP, {
          email: user.email,
          via: request.via,
        });
        this.isAuthenticated().subscribe(() => {
          this.gaService.trackEvent(GA_EVENTS.SIGNUP + request.via, GACategory.SignUp);
          this.facebookPixelService.track(FA_EVENTS.SIGNUP, GACategory.SignUp);
        });
      })
    );
  }

  logout() {
    return this.httpService.get(AUTH_URLS.SIGN_OUT).pipe(
      tap(() => {
        this.mixpanel.track('User logged out');
        this.mixpanel.reset();
        this.gaService.trackEvent(GA_EVENTS.LOGOUT, GACategory.Logout);
        if (isPlatformBrowser(this.platformID)) {
          localStorage.clear();
        }
        const lastUser = this.lastLogedInUser;
        this.cookieService.remove('authenticatedUser');
        this.cookieService.removeAll();
        this.saveInCookies('lastLoggedInUser', lastUser);
        this.currentUserSubject.next(null);
      })
    );
  }

  googleLogin(authConsumer?: string) {
    this.socialAuth(AUTH_URLS.GOOGLE_AUTH, authConsumer);
  }

  githubLogin(authConsumer?: string) {
    this.socialAuth(AUTH_URLS.GITHUB_AUTH, authConsumer);
  }

  socialAuth(authUrl: string, authConsumer?: string) {
    const signUpVia = this.signupVia || SignupVia.Normal;
    const redirect = authConsumer ? `consumer:${authConsumer}` : this.loginRedirectURL || '/dashboard';
    const queryParams =
      '?refToken=' +
      this.cookieService.get('refToken') +
      '&emailCampaign=' +
      this.cookieService.get('emailCampaign') +
      '&via=' +
      signUpVia +
      '&action=' +
      this.cookieService.get('loginCallbackAction') +
      '&redirect=' +
      redirect;
    window.open(this.baseURL + authUrl + queryParams, '_self');
  }

  saveSocialUser(email: string, name: string) {
    const user = { email, name };
    this.saveInCookies('authenticatedUser', user);
    this.saveInCookies('lastLoggedInUser', user);
    this.currentUserSubject.next(user);
    this.mixpanel.identify(user);
  }
}
