<div class="app-l-detailed-wrapper">
  <div class="app-sample-overviewLeft">
    <div class="app-c-content--descrp">
      <div class="coding-challenge-section input-output-section"
        *ngFor="let section of sampleInputOutput?.contentSections">
        <div class="heading" *ngIf="section.hasHeading">{{section.heading}}</div>
        <div class="sub-section" *ngFor="let subSection of section.subSections">
          <h2 class="heading" *ngIf="subSection.hasHeading">
            {{subSection.heading}}
          </h2>
          <h2 class="sub-heading" *ngIf="subSection.hasSubsectionHeading">
            {{subSection.subSectionHeading}}
          </h2>
          <div class="app-c-text text-container" *ngIf="subSection.isStatement">
            <p class="text-statement" [ngClass]="subSection.hasGlobalClass? subSection.globalClass : []"
              *ngIf="subSection.statement !== null">
              <ng-container *ngIf="!subSection.isHyperLink; else hyperlinkTemplate">
                {{subSection.statement}}
              </ng-container>
              <ng-template #hyperlinkTemplate>
                <a [href]="subSection.hyperlink" target="_blank">
                  {{subSection.statement}}
                </a>
              </ng-template>
            </p>
          </div>
          <div class="app-c-text text-container" *ngIf="subSection.isArrayOfWords">
            <p class="text-statement" [ngClass]="subSection.hasGlobalClass? subSection.globalClass : []">
              <ng-container>
                <span class="space-word" [ngClass]="part.wordClass"
                  *ngFor="let part of subSection.words">{{part.word}}&nbsp;</span>
              </ng-container>
            </p>
          </div>
          <div class="ordered-list-container" *ngIf="subSection.hasOrderedList">
            <ol class="ordered-list">
              <li *ngFor="let listItem of subSection.orderedList;">
                <span>
                  {{listItem}}
                </span>
              </li>
            </ol>
          </div>
          <div class="hyperlink-container" *ngIf="subSection.hasHyperLink">
            <a class="app-btn app-btn-lg app-btn-secondary" target="_blank"
              [href]="subSection.hyperLink">{{subSection.hyperLinkText}}</a>
          </div>
          <div class="unordered-list-container" *ngIf="subSection.hasUnorderedList">
            <ul>
              <li *ngFor="let listItem of subSection.unOrderedList;">
                <span>
                  {{listItem}}
                </span>
              </li>
            </ul>
          </div>
          <div class="nested-list-container" *ngIf="subSection.hasNestedList">
            <ol class="ordered-list">
              <li *ngFor="let listItem of subSection.listItems;">
                <span>{{listItem.text}}</span>
                <ul class="unordered-list" *ngIf="listItem.children">
                  <li *ngFor="let childListItem of listItem.children">
                    <span>
                      {{childListItem.text}}
                    </span>
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div class="table-container" *ngIf="subSection.isTable">
            <div class="table-heading" *ngIf="subSection.hasTableHeading">
              {{subSection.tableHeading}}
            </div>
            <div class="table-description" *ngIf="subSection.hasTableDescription">
              {{subSection.tableDescription}}
            </div>
            <div class="app-c-table--dynamic">
              <table class="app-c-table-problem-statement">
                <tr *ngFor="let dataRow of subSection.tableData" class="app-c-border-cstm">
                  <ng-container *ngIf="dataRow.isHeaderRow">
                    <th *ngFor="let header of dataRow.data | keyvalue" width="">{{header.value}}</th>
                  </ng-container>
                  <ng-container *ngIf="dataRow.isDataRow">
                    <ng-container *ngIf="!dataRow.usesCustomData">
                      <td *ngFor="let data of dataRow.data | keyvalue;" [ngClass]="dataRow?.extraClasses[data.key]">
                        {{data.value}}</td>
                    </ng-container>
                    <ng-container *ngIf="dataRow.usesCustomData">
                      <td *ngFor="let data of dataRow.data | keyvalue;" [ngClass]="dataRow?.extraClasses[data.key]">
                        <span class="space-word" [ngClass]="part.wordClass"
                          *ngFor="let part of data.value">{{part.word}}</span>
                      </td>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="dataRow.isSpacerRow">
                    <td class="less-padding" colspan="5">&nbsp;</td>
                  </ng-container>
                </tr>
              </table>
            </div>
          </div>
          <div class="image-container" *ngIf="subSection.isImage">
            <img [ngClass]="subSection.imageClasses" src={{subSection.imageSource}} alt="" class="challangesPic" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>