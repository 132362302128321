import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieModule } from 'ngx-cookie';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SwitchModule } from './core/components/switch/switch.module';
import cookieConfig from './core/configurations/cookie-consent.configuration';
import { CoreModule } from './core/core.module';
import { SharedModule } from './features/signup/passive-workflow/shared/shared.module';
import { ProfileNotEmptyCheck } from './features/signup/tellus/tellus.route-activation-checks';
import { IconModule } from './icons/icon.module';
import { SharedCommonModules } from './shared/shared.module';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}



@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    RouterModule,
    SharedModule,
    IconModule,
    SharedCommonModules,
    SwitchModule,
    NgcCookieConsentModule.forRoot(cookieConfig as NgcCookieConsentConfig),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
     
    }),
    NgxGoogleAnalyticsModule.forRoot(environment.gaTrackingCode),
    NgxGoogleAnalyticsRouterModule,
    CookieModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  exports: [],
  providers: [Meta, ProfileNotEmptyCheck],
  bootstrap: [AppComponent],
})
export class AppModule { }
