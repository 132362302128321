import { Component, Input, OnInit } from '@angular/core';
import { BadgesAndScoring } from 'src/app/core/models/coding-challenges.model';


@Component({
  selector: 'geektrust-badges-score',
  templateUrl: './badges-score.component.html',
  styleUrls: ['./badges-score.component.scss']
})
export class GeektrustBadgesScoreComponent implements OnInit {
  @Input() badgesAndScoring: Partial<BadgesAndScoring> = {};
  constructor() {}

  ngOnInit(): void {}
}
