import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, UserActivityTrackingService } from 'geektrust-core-services';
import { SidePanelToggleService } from 'src/app/core/interactions/sidepanel-toggle/sidepanel-toggle.service';
import { ProblemStatement } from 'src/app/core/models/coding-challenges.model';

@Component({
  selector: 'geektrust-existing-problem-statement',
  templateUrl: './existing-problem-statement.component.html',
  styleUrls: ['./existing-problem-statement.component.scss']
})
export class ExistingProblemStatementComponent implements OnInit {

  @Input() problemStatement: Partial<ProblemStatement> = {};
  @Input() allowDownloadStarterKit = true;
  @Input() detailedChallenge: Partial<any> = {};
  isExperimenting = false;
  constructor() {
  }

  ngOnInit(): void {
  }
}
