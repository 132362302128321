import * as AdminUIChallenge from './admin-ui.json';
import * as DoReMiChallenge from './doremi.json';
import * as ExpensesManagement from './expenses-management.json';
import * as Family from './family.json';
import * as FindingFalconeMobile from './finding-falcone.json';
import * as GeekdemyChallenge from './geekdemy.json';
import * as IntuitLMSChallenge from './intuit-lms.json';
import * as LedgerCompanyChallenge from './ledger-company.json';
import * as MakeSpaceChallenge from './make-space.json';
import * as MyMoneyChallenge from './my-money.json';
import * as PortfolioOverlapChallenge from './portfolio-overlap.json';
import * as PowerOfGMan from './power-of-g-man.json';
import * as RacetrackManagement from './racetrack-management.json';
import * as SpaceChallenge from './space.json';
import * as StationeryShopChallenge from './stationery-shop.json';
import * as TameOfThrones from './tame-of-thrones.json';
import * as TeeRexStoreChallenge from './teerex-store.json';
import * as TollCalculator from './toll-calculation.json';
import * as MetroCard from './metro-card.json';
// Retired Problems
import * as Traffic from './traffic.json';
import * as TrainChallenge from './train.json';
import * as War from './war.json';
import * as WaterManagementChallenge from './water-management.json';
import * as RideSharingChallenge from './ride-sharing.json';

export const ALL_CHALLENGE_CONFIGURATIONS = [
  AdminUIChallenge,
  DoReMiChallenge,
  ExpensesManagement,
  StationeryShopChallenge,
  TollCalculator,
  MetroCard,
  Family,
  FindingFalconeMobile,
  IntuitLMSChallenge,
  LedgerCompanyChallenge,
  MakeSpaceChallenge,
  MyMoneyChallenge,
  PortfolioOverlapChallenge,
  PowerOfGMan,
  GeekdemyChallenge,
  RacetrackManagement,
  SpaceChallenge,
  TameOfThrones,
  TeeRexStoreChallenge,
  Traffic,
  TrainChallenge,
  War,
  WaterManagementChallenge,
  RideSharingChallenge,
].map((x) => x.default);

export enum ChallengeTypes {
  FRONT_END = 'Frontend',
  BACK_END = 'Backend',
  MOBILE = 'Mobile',
}




