const PROFILE_URLS = Object.freeze({
  UPDATE_PROFILE: 'user/profile/update',
  GET_USER_PROFILE: 'user/profile/',
});

const USER_PROFILE_DATA_URLS = Object.freeze({
  USER_SIGN_UP: `auth/signup`, // POST, No Dynamic Attributes
  USER_SIGN_IN: `auth/signin`, // POST, No Dynamic Attributes
  UPDATE_USER_BASIC_PROFILE: `user/profile/update`, // POST, No Dynamic Attributes
  GET_USER_PROFILE: `v2/user/profile/`, // GET, Dynamic Attribute - User Email ID "v2/user/profile/{{user_email}}"
  UPLOAD_USER_RESUME: `user/profile/upload/resume/`, // POST, Dynamic Attribute - User Email ID "/user/profile/upload/resume/{{user_email}}"
  // DELETE, Dynamic Attribute - User Email ID "/user/profile/resume/delete/{{user_email}}"
  DELETE_USER_RESUME: `user/profile/resume/delete/`,
  // POST, Dynamic Attribute - User Email ID "v2/user/profile/{{user_email}}/upload/attachment"
  USER_UPLOAD_ATTACHMENT: `v2/user/profile/{{user_email}}/upload/attachment`,
  // // DELETE, Dynamic Attribute - User Email ID "/v2/user/profile/{{user_email}}/delete/attachment/badge",
  USER_DELETE_ATTACHMENT: `v2/user/profile/{{user_email}}/delete/attachment`,
  USER_FETCH_PREFERENCES: `v2/user/jobPreferences`,
  USER_FORGOT_PASSWORD: `auth/forgot`,
  USER_RESET_PASSWORD: `auth/reset/`,
  USER_VALIDATE_EMAIL: `auth/validateEmail/`,
  USER_UPLOAD_PROFILE_IMAGE: `v2/user/profile-image/upload/{{user_email}}`,
  GET_REFERRAL_LINK: `referrer/link/{{user_email}}`,
  CREATE_REFERRER: `referrer/create`,
  UPDATE_LAST_LAUNCH_DATE: `v2/user/profile/{{user_email}}/lastLaunchTime`,
  COMMUNICATION_PREFERENCES_CREATE_URL: 'v2/createUserPreferences',
  COMMUNICATION_PREFERENCES_UPDATE_URL: 'v2/updateUserPreferences',
  COMMUNICATION_PREFERENCES_RETRIEVE_URL: 'v2/getUserPreferences',
  RUN_MATCHER_ON_DEMAND : 'v2/user/{email}/{regNo}/matcher/run/'
});

const USER_PROBLEM_URLS = Object.freeze({
  UPLOAD_CODE: `upload/`, // POST, Dynamic Attribute - User Email ID "/upload/{{user_email}}"
  SUBMIT_CODE: `submit/code/user/`, // POST, Dynamic Attribute - User Email ID "submit/code/user/{{user_email}}"
  EVALUATE_CODE: `code/run/tests/`, // POST, Dynamic Attribute - User Email ID "/code/run/tests/{{user_email}}"
  LOAD_SUBMISSION_RUNS: `ace/user/{{user_email}}/fileName/`,
});

const COMMON_URLS = Object.freeze({
  GET_TECHNOLOGIES_FOR_USER_ROLE: `v2/technologies/role/`, // GET, Dynamic Attribute - User Role, "v2/technologies/role/{{user_role}}"
  GET_ALL_EMPLOYERS: `v2/companies/employers/all`, // GET, No Dynamic Attributes
  GET_STATS: `v2/stats`, // GET, No Dynamic Attributes
});

export {
    PROFILE_URLS,
    USER_PROFILE_DATA_URLS,
    USER_PROBLEM_URLS
};
