import { Component, Input, OnInit } from '@angular/core';
import { ReportSummary } from '../code-report.component';

@Component({
  selector: 'app-code-report-summary',
  templateUrl: './code-report-summary.component.html',
  styleUrls: ['./code-report-summary.component.scss']
})
export class CodeReportSummaryComponent implements OnInit {
  @Input() summary: ReportSummary;

  constructor() { }

  ngOnInit(): void {
  }

}
