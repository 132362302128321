import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconsComponent } from './icons.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';

@NgModule({
  imports: [CommonModule],
  exports: [IconsComponent, SvgIconComponent],
  declarations: [IconsComponent, SvgIconComponent],
})
export class IconModule {}
