import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MouseflowService {
    constructor() { }

    init(): void {
        const mouseflowScript = document.createElement('script');
        mouseflowScript.innerHTML = `
            window._mfq = window._mfq || [];
            (function () {
            var mf = document.createElement("script");
            mf.type = "text/javascript"; mf.defer = true;
            mf.src = "//cdn.mouseflow.com/projects/0c780971-acd1-4270-b974-0379ac51746f.js";
            document.getElementsByTagName("head")[0].appendChild(mf);
            })();`;
        document.body.appendChild(mouseflowScript);
    }

}
