<svg
  (click)="onClickedSvgIcon($event)"
  [attr.class]="className"
  [attr.aria-hidden]="ariaHidden"
  [attr.focusable]="focusable"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <!-- <title>{{ title }}</title> -->
  <use [attr.xlink:href]="'#icon-' + name"></use>
</svg>
