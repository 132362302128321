import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable, Optional, Inject } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';

@Injectable()
export class CookieInterceptor implements HttpInterceptor {
  constructor(@Optional() @Inject(REQUEST) private httpRequest) 
  {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (this.httpRequest) //If optional request is provided, we are server side
    {
      req = req.clone(
      { 
        setHeaders: 
        {
            Cookie: this.httpRequest.headers.cookie
        }
      });
    }
    return next.handle(req);
  }
}