import { CodingChallengesService } from './../../../core/services/coding-challenges/coding-challenges.service';
import { takeUntil } from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UserActivityTrackingService, UserProfileService } from 'geektrust-core-services';
import { NgxMasonryComponent, NgxMasonryOptions } from 'ngx-masonry';
import { Router } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { CodingChallengeConfigurationModel } from 'src/app/core/models/coding-challenges.model';

@Component({
  selector: 'app-coding-challenge-card-layout',
  templateUrl: './coding-challenge-card-layout.component.html',
  styleUrls: ['./coding-challenge-card-layout.component.scss'],
})
export class CodingChallengeCardLayoutComponent implements OnInit, OnDestroy {
  @ViewChild(NgxMasonryComponent) masonry: NgxMasonryComponent;
  @Input() theme = 'light';
  @Input() numberOfCardsInLayout = 3;
  @Input() detailsPath = 'coding/detailed';
  @Input() viewMode = 'tab1';
  commonList = [];
  filters = {};
  masonryItemsBackend = [];
  masonryItemsFrontend = [];
  masonryItemsMobile = [];
  public masonryOptions: NgxMasonryOptions = {
    gutter: 0,
    horizontalOrder: true,
  };
  updateMasonryLayout = false;
  indexOfCodingChallengeCardShowingNote = -1;
  timeOuts = [];
  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
    private userActivityService: UserActivityTrackingService,
    private codingChallengesService: CodingChallengesService
  ) {
    combineLatest([this.codingChallengesService.backendCodingChallengesObservable$,
    this.codingChallengesService.frontendCodingChallengesObservable$,
    this.codingChallengesService.mobileCodingChallengesObservable$
    ])
      .subscribe(([backendChallenges, frontendChallenges, mobileChallenges]:
        [
          Array<CodingChallengeConfigurationModel>,
          Array<CodingChallengeConfigurationModel>,
          Array<CodingChallengeConfigurationModel>
        ]) => {
        this.masonryItemsBackend = backendChallenges.filter((challenge) => challenge.targetedAt !== 'PRIVATE_DRIVE');
        this.tabToCommonListMapping.tab1 = backendChallenges.filter((challenge) => challenge?.targetedAt !== 'PRIVATE_DRIVE');
        this.masonryItemsFrontend = frontendChallenges.filter((challenge) => challenge.targetedAt !== 'PRIVATE_DRIVE');
        this.tabToCommonListMapping.tab2 = frontendChallenges.filter((challenge) => challenge?.targetedAt !== 'PRIVATE_DRIVE');
        this.masonryItemsMobile = mobileChallenges.filter((challenge) => challenge.targetedAt !== 'PRIVATE_DRIVE');
        this.tabToCommonListMapping.tab3 = mobileChallenges.filter((challenge) => challenge?.targetedAt !== 'PRIVATE_DRIVE');
        this.switchingData('tab1', {});
        this.populateValuesFromUserProfile();
    });
  }
  tabToCommonListMapping = {
    tab1: this.masonryItemsBackend,
    tab2: this.masonryItemsFrontend,
    tab3: this.masonryItemsMobile,
  };
  private ngUnsubscribe$: Subject<undefined> = new Subject();
  layoutClasses = [];
  ngOnInit(): void {
    if (Number(this.numberOfCardsInLayout) === 3) {
      this.layoutClasses.push('app-l-width--onebythird');
    } else if (Number(this.numberOfCardsInLayout) === 4) {
      this.layoutClasses.push('app-l-width--onebyfour');
    }
  }

  populateValuesFromUserProfile() {
    this.userProfileService.userProfileObservable$.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((userProfile) => {
      if (userProfile?.email) {
        if (userProfile.codeSubmitted === true) {
        } else {
        }
        if (userProfile?.primarySkill === 'Frontend Development') {
          this.switchingData('tab2', {});
        } else if (userProfile?.primarySkill === 'Mobile App Development') {
          this.switchingData('tab3', {});
        } else {
          this.switchingData('tab1', {});
        }
      }
      setTimeout(() => this.itemsLoaded(), 2000);
    });
  }

  itemsLoaded() {
    this.reloadMasonryLayout();
  }
  switchingData(e, filters = {}, fromUserEvent = false) {
    this.viewMode = e;
    this.commonList = [];
    this.filters = filters;
    if (fromUserEvent) {
      this.userActivityService.trackMixPanelEvent(`User clicked on ${{ tab1: 'Backend', tab2: 'Frontend', tab3: 'Mobile'}[e]} tab.`);
    }
    switch (e) {
      case 'tab1':
        if (Object.keys(filters).length) {
          this.commonList = this.masonryItemsBackend.filter(
            (challenge) => Object.entries(this.filters).filter(([key, value]) => challenge[key] === value).length
          );
        } else {
          this.commonList = this.masonryItemsBackend;
        }
        break;
      case 'tab2':
        if (Object.keys(filters).length) {
          this.commonList = this.masonryItemsFrontend.filter(
            (challenge) => Object.entries(this.filters).filter(([key, value]) => challenge[key] === value).length
          );
        } else {
          this.commonList = this.masonryItemsFrontend;
        }
        break;
      case 'tab3':
        if (Object.keys(filters).length) {
          this.commonList = this.masonryItemsMobile.filter(
            (challenge) => Object.entries(this.filters).filter(([key, value]) => challenge[key] === value).length
          );
        } else {
          this.commonList = this.masonryItemsMobile;
        }
        break;
    }
    this.startShowingExtraNotes(this.commonList);
  }

  startShowingExtraNotes(commonList) {
    Array.from(document.getElementsByClassName('app-c-card__item')).forEach((element) =>
      element.classList.remove('hover')
    );
    this.indexOfCodingChallengeCardShowingNote =
      commonList.find((challenge) => {
        return ['', null, undefined, 0, false].includes(challenge.extraNote) === false;
      })?.code || -1;
    if (this.indexOfCodingChallengeCardShowingNote !== -1) {
      this.timeOuts.push(
        setTimeout(() => {
          if (this.indexOfCodingChallengeCardShowingNote === this.commonList[this.commonList.length - 1]?.code) {
            this.startShowingExtraNotes(this.commonList);
          } else {
            this.startShowingExtraNotes(
              this.commonList.slice(
                this.commonList.findIndex(({ code }) => code === this.indexOfCodingChallengeCardShowingNote) + 1
              )
            );
          }
        }, 12000)
      );
    } else {
      if (this.commonList.filter((challenge) => challenge.extraNote).length > 0) {
        this.startShowingExtraNotes(this.commonList);
      }
    }
  }

  itemCount(el) {
    return (el < 10 ? '0' : '') + el;
  }

  reloadMasonryLayout() {
    if (this.masonry !== undefined) {
      this.masonry.reloadItems();
      this.masonry.layout();
    }
  }

  onFiltersChanged(filter) {
    this.userActivityService.trackMixPanelEvent('User applied filter', {
      ...filter,
      tab: {
        tab1: 'Backend',
        tab2: 'Fronted',
        tab3: 'Mobile'
      }
    });
    this.switchingData(this.viewMode, filter);
  }

  onMouseEnter(event) {
    event.target.parentElement.childNodes[0].childNodes[0].classList.add('hover');
  }
  onMouseLeave(event) {
    event.target.parentElement.childNodes[0].childNodes[0].classList.remove('hover');
  }
  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
    this.timeOuts.forEach((intervalId) => clearTimeout(intervalId));
  }
}
