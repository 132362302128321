<div class="badges-summary">
    <div class="report-section-title">
        SUMMARY
    </div>
    <div *ngIf="summary.earnedCount" class="summary-text">
        🎉 &nbsp;The candidate has earned
        <span class="badge-total">
            {{summary.earnedCount}}/{{(summary.earnedCount+summary.unEarnedCount)}} badges!
        </span>
    </div>
    <div *ngIf="!summary.earnedCount" class="summary-text">
        This candidate has not earned any badges
    </div>

    <div class="badges-group-container">
        <div *ngFor="let item of summary.badgeTypes" class="badge-category">
            <div class="img-text-group" [ngClass]="{ 'gray-out': item.earnedCount == 0}">
                <div class="text-overlay">{{item.earnedCount}}</div>
                <img src="../../../../../assets/images/{{item.imageName}}" alt="Geektrust" class="badge-category-img" />
            </div>
            <div class="badge-names-group">
                <div *ngFor="let badgeName of item.earnedBadgeNames" class="badge-name {{item.complexity}}">
                    {{badgeName}}
                </div>
                <div *ngFor="let badgeName of item.unearnedBadgeNames" class="badge-name {{item.complexity}} unearned">
                    {{badgeName}}
                </div>
            </div>
        </div>
    </div>
</div>