export const MIXPANEL_EVENTS = {
    PAGE: {
        CLICKS_ABOUT_US: 'Clicks About Us',
        EXPLORES_GEEKTRUST_JOBS: 'Explores Geektrust Jobs'
    },
    SOCIAL_MEDIA_HANDLES: {
        CLICKS_SOCIAL_HANDLE: 'Clicks Social Handle'
    }
};

export const MIXPANEL_USER_PROPERTIES: Partial<MixpanelUserLevelProperties> = {
    COMPANIES_APPLIED_TO: 'Companies applied to',
    PRIMARY_ROLE: 'Primary Role',
    CURRENT_EMPLOYER: 'Current Employer',
    CURRENT_LOCATION: 'Current Location',
    YEARS_OF_EXPERIENCE: 'Years of Experience',
    USER_STATE: 'User State',
    CODE_SUBMITTED: 'Code Submitted',
    LAST_ACTION_TIME: 'Last Action Time',
    CREATED_DATE: 'Created Date',
    SCORE: 'Best Score'
};
export interface MixpanelUserLevelProperties {
    COMPANIES_APPLIED_TO: string;
    PRIMARY_ROLE: string;
    CURRENT_EMPLOYER: string;
    CURRENT_LOCATION: string;
    YEARS_OF_EXPERIENCE: string;
    USER_STATE: string;
    CODE_SUBMITTED: string;
    LAST_ACTION_TIME: string;
    CREATED_DATE: string;
    SCORE: string;
}

