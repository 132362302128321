<div class="user-nudge-container" *ngIf="popupConfiguration.showPopup" [@trigger]
    (@trigger.done)="displayEvent(i, $event)" (click)="goToTargetPage(popupConfiguration)">
    <div class="message-container">
        <div class="title">{{ popupConfiguration.title }}
            <a class="app-c-close" (click)="closeToast()">
                <app-svg-icon name="close" title="Close" className="icon-svg icon-theme2  icon-size-20"
                    ariaHidden="false" focusable="false"></app-svg-icon>
            </a>
        </div>
        <div class="icon-with-text">
            <div class="icon">
                <img class="smallImageIcon" [src]="popupConfiguration.icon.source"
                    [alt]="popupConfiguration.icon.alt" />
            </div>
            <div class="text">
                {{popupConfiguration.description}}
            </div>
        </div>
        <div class="time">
            {{popupConfiguration.time}}
        </div>
    </div>
    <div class="action-container" (click)="goToTargetPage(popupConfiguration.target)">
        View Challenges &gt;
    </div>
    <div class="bg-gradient-1"></div>
    <div class="bg-gradient-2"></div>
    <div class="bg-gradient-3"></div>
</div>