import { UserProfile } from './../../models/user-profile.model';
import { EducationTimeline, EmploymentTimeline, WorkLifeExpectation, YrsOfExperience } from '../../models/user-profile.model';

const isInvalidDate = (date) => {
  if (date === null) {
    return true;
  }
  if (Number.isNaN(new Date())) {
    return true;
  }
  return false;
};

const convertToDateFormat = (date: YrsOfExperience, key) => {
  if (isInvalidDate(date)) {
    return {
      [key]: null
    };
  } else {
    return {
      [key]: new Date(`${(date)?.month}/01/${(date)?.year}`)
    };
  }
};

const adaptUserProfileValueForUI = (values) => {
  const adaptedValues : Partial<UserProfile> = {};
  for (const key in values) {
    if (values.hasOwnProperty(key)) {
      switch (key) {
        case 'employmentTimeline':
          adaptedValues[key] = values[key].map((stint: EmploymentTimeline) => ({
            ...stint,
            ...(convertToDateFormat(stint.durationStart as YrsOfExperience, 'durationStart')),
            ...(convertToDateFormat(stint.durationEnd as YrsOfExperience, 'durationEnd'))
          }));
          break;
        case 'educationTimeline':
          adaptedValues[key] = values[key].map((stint: EducationTimeline) => ({
            ...stint,
            ...(convertToDateFormat(stint.durationEnd as YrsOfExperience, 'durationEnd')),
          }));
          break;
        default:
          adaptedValues[key] = values[key];
          break;
      }
    }
  }
    if (adaptedValues?.employmentTimeline?.find(tenure => tenure?.present)?.employer) {
    adaptedValues.employer = adaptedValues?.employmentTimeline?.find(tenure => tenure?.present)?.employer;
  }
  return adaptedValues;
};

export { adaptUserProfileValueForUI };
