import { Component, Input, OnInit } from '@angular/core';
import { VerbosityBadge } from 'src/app/core/models/common.models';

@Component({
  selector: 'app-code-report-badge',
  templateUrl: './code-report-badge.component.html',
  styleUrls: ['./code-report-badge.component.scss']
})
export class CodeReportBadgeComponent implements OnInit {
  @Input() badgeItem: VerbosityBadge;
  @Input() earned: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
