import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  finalPageSubject: Subject<boolean> = new BehaviorSubject<boolean>(false);
  finalPageObserver = this.finalPageSubject.asObservable();

  constructor() {}
  
  setCurrentWorkFlow(val) {
    this.finalPageSubject.next(val);
  }
}
