import { UserProfile } from './../../models/user-profile.model';
const convertTimelinesToJSON = (experience) => {
  const durationStart = new Date(experience.durationStart);
  const durationEnd = new Date(experience.durationEnd);

  return {
    ...experience,
    ...(Number.isNaN(durationStart)
      ? {}
      : {
        durationStart: {
          year: durationStart.getFullYear(),
          month: durationStart.getMonth() + 1,
        },
      }),
    ...(Number.isNaN(durationEnd)
      ? {}
      : {
        durationEnd: experience.durationEnd
          ? {
            year: durationEnd.getFullYear(),
            month: durationEnd.getMonth() + 1,
          }
          : null,
      }),
  };
};

const keyValueSerialization = (key: string, value: any) => {
  let output = value;
  switch (key) {
    case 'educationTimeline':
      output = value.map(convertTimelinesToJSON);
      break;
    case 'employmentTimeline':
      output = value.map(convertTimelinesToJSON);
      break;
    default:
      output = value;
      break;
  }
  return output;
};
const serializeUserProfileDataForDB = (values, existingValues) => {
  const serializedValues: Partial<UserProfile> = {};
  const nestedKeysThatAreOverriddenByUpdate = ['tech', 'domain', 'urls'];
  for (const key in values) {
    if (nestedKeysThatAreOverriddenByUpdate.includes(key)) {
      serializedValues[key] = { ...existingValues[key], ...values[key] };
    } else {
      serializedValues[key] = keyValueSerialization(key, values[key]);
    }
  }
  if (serializedValues?.employmentTimeline?.find(tenure => tenure?.present)?.employer) {
    serializedValues.employer = serializedValues?.employmentTimeline?.find(tenure => tenure?.present)?.employer;
  }
  return serializedValues;
};

export { serializeUserProfileDataForDB };
