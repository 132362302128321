<div class="badge">
    <div class="badge-title">
        {{badgeItem.badge.name}}
    </div>
    <div class="badge-detail">
        <div class="result-container">
            <div class="result-grid-item">
                <div class="badge-tag">MASTER BADGE</div>
            </div>
            <div class="result-grid-item">
                <div class="result-title">Result</div>
                <ng-container [ngSwitch]="earned">
                    <app-svg-icon *ngSwitchCase="'true'" name="tick-circle-dark" className="icon-svg icon-size-18"
                        ariaHidden="false" focusable="false">
                    </app-svg-icon>
                    <app-svg-icon *ngSwitchCase="'false'" name="cross-circle" className="icon-svg icon-size-18"
                        ariaHidden="false" focusable="false">
                    </app-svg-icon>
                </ng-container>
            </div>
        </div>
        <div class="verbosity" *ngIf="badgeItem.verbosity">
            <ul>
                <ng-container *ngFor="let positive of badgeItem.verbosity?.rawFeedback.good | keyvalue">
                    <li class="list-item" *ngIf="positive.value?.short">
                        <div class="list-bullet">
                            <app-svg-icon name="green-tick" className="icon-svg icon-size-14" ariaHidden="false"
                                focusable="false"></app-svg-icon>
                        </div>
                        {{positive.value.short}}
                    </li>
                </ng-container>

                <ng-container *ngFor="let negative of badgeItem.verbosity?.rawFeedback.bad | keyvalue">
                    <li class="list-item" *ngIf="negative.value?.short">
                        <div class="list-bullet">
                            <app-svg-icon name="red-cross" className="icon-svg icon-size-14" ariaHidden="false"
                                focusable="false"></app-svg-icon>
                        </div>
                        {{negative.value.short}}
                    </li>
                </ng-container>
            </ul>
        </div>

        <!-- Sub criterias section -->
        <div *ngIf="badgeItem.verbosity?.subCriterias && badgeItem.verbosity?.subCriterias.length" class="subcriterias">
            <div class="subbadge-title-divider">
                <div class="title-divider-label">
                    <div class="badge-tag">SUB BADGES</div>
                </div>
                <div class="title-divider-hr right-hr"></div>
            </div>
            <div class="subcriteria-item"
                *ngFor="let subCriteriaItem of badgeItem.verbosity.subCriterias; last as isLast">
                <div class="result-container">
                    <div class="result-grid-item">
                        <div class="badge-title subbadge">
                            {{subCriteriaItem.subCriteria | subcriteriaLabel}}
                        </div>
                    </div>
                    <div class="result-grid-item">
                        <div class="result-title">Result</div>
                        <app-svg-icon *ngIf="subCriteriaItem.subBadge.good.general.short" name="tick-circle-dark"
                            className="icon-svg icon-size-18" ariaHidden="false" focusable="false">
                        </app-svg-icon>
                        <app-svg-icon *ngIf="!subCriteriaItem.subBadge.good.general.short" name="cross-circle"
                            className="icon-svg icon-size-18" ariaHidden="false" focusable="false">
                        </app-svg-icon>
                    </div>
                </div>
                <div class="verbosity with-findings">
                    <ul>
                        <ng-container *ngFor="let feedback of subCriteriaItem.subBadge.good | keyvalue">
                            <li class="list-item" *ngIf="feedback.value?.short">
                                <div class="list-bullet">
                                    <app-svg-icon name="green-tick" className="icon-svg icon-size-14" ariaHidden="false"
                                        focusable="false">
                                    </app-svg-icon>
                                </div>
                                {{feedback.value.short}}
                            </li>
                        </ng-container>
                        <ng-container *ngFor="let feedback of subCriteriaItem.subBadge.bad | keyvalue">
                            <li class="list-item" *ngIf="feedback.value?.short">
                                <div class="list-bullet">
                                    <app-svg-icon name="red-cross" className="icon-svg icon-size-14" ariaHidden="false"
                                        focusable="false">
                                    </app-svg-icon>
                                </div>
                                {{feedback.value.short}}
                            </li>
                        </ng-container>
                    </ul>
                </div>
                <div *ngIf="!isLast" class="separator"></div>
            </div>
        </div>
        <!-- End Sub criterias section -->
    </div>
</div>