
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({
    providedIn: 'root',
})
/* Guard to be used on certain website pages to redirect 
to corresponding dashboard page if user is signed in. 
eg: /jobs to /job-search
*/
export class MatcherRerunningGuard implements CanActivate {
    constructor(
        private router: Router,
        private authService: AuthService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const isMatcherRerunning = false;
        if (!isMatcherRerunning) {
            return true;
        }
        this.router.navigateByUrl('/under-maintenance');
        return false;
    }
}
