const SubBadgeHelpTextMessages = {
    MAGIC_NUMBERS: 'Magic numbers in code are hard coded string or number literals used directly without any information of its context. Magic numbers make the submission harder to read, maintain or make changes to the code.',
    COMPACT_METHODS: 'Methods that are long are hard to understand and maintain, It is also an indication that the method is probably doing many things and violating SRP (Single Responsibility Principle). Breaking down a long method into smaller ones helps improve the readability and maintainability of the code.',
    EXPRESSIVE_CODE: 'Names of variables, methods and classes should be clear and understandable. Names that are not expressive are hard to understand, whereas good naming conventions can go a long way to improve the readability of the code.',
    DRY: 'DRY or Don\'t Repeat Yourself, a programming paradigm that discourages code duplication. If a code snippet appears more than twice in the submission, it is flagged as code duplication.',
    METHOD_COMPLEXITY: 'Methods that are complex are generally hard to understand, maintain and alter, having \'Nested control flows\' are of one of the culprits behind a method being complex. It is advisable to break down complex methods to smaller ones.',
    ENCAPSULATION: 'Encapsulation is an OOP\'s paradigm that encourages bundling of data with the methods that operate on that data, or the restricting of direct access to some of an object\'s components. Breaking encapsulation is considered to be against the object oriented programming principles.'
};

const GenericCriteriaHelpTextMessages = {
    noOfEmptySubClasses: `Empty subclasses are classes that don't add any new properties or methods compared to their superclass. They should be avoided as they introduce code redundancy, complexity, and potential for errors, making the code harder to understand and maintain.`,
    noOfParameters: `It refers to a method having a large number of input parameters. It should be avoided as it leads to code complexity, reduced readability, maintenance difficulties, increased coupling, violated Single Responsibility Principle, and hindered code flexibility and reusability.`,
    noOfLocalVariables: `It refers to having a large number of variables declared within a class method. It should be avoided as it increases code complexity, reduces readability, makes maintenance challenging, and can indicate potential design flaws. Keeping the number of local variables concise improves code clarity and maintainability.`,
    noOfMethods: `When a class has many methods, it means it's doing a lot of different things, which can make it confusing and hard to work with. It's like having a toolbox with too many tools for a single task. It's better to have smaller classes that each have a specific job, making the code easier to manage.`,
    noOfFields: `A large number of fields in a class indicates that the class is storing a lot of different information. It's like having a backpack with too many items, making it heavy and hard to find what you need. It's better to have fewer fields in a class, each representing a specific piece of information, to keep the code organized and easier to work with.`,
    dit: `A deep inheritance hierarchy refers to a chain of classes where each class derives from its parent class. The downfall of a deep hierarchy is increased complexity, tight coupling, reduced maintainability, and difficulty in understanding and modifying the code. It can lead to code fragility and make it harder to introduce new features or fix issues.`,
};
const GenericCriteriaMap = {
    noOfEmptySubClasses: 'Too Many Empty Subclasses',
    noOfParameters: 'Too Many Parameters to A Method',
    noOfLocalVariables: 'Too Many Local Variables',
    noOfMethods: 'No of Methods of A Class',
    noOfFields: 'No of Fields',
    dit: 'Deep Inheritance Hierarchy',
};

const SubBadgeNamesMap = {
    ENCAPSULATION: 'Encapsulation',
    COMPACT_METHODS: 'Compact Methods',
    MAGIC_NUMBERS: 'Magic Numbers',
    EXPRESSIVE_CODE: 'Expressive Code',
    METHOD_COMPLEXITY: 'Method Complexity',
    DRY: 'DRY',
};

export {
    SubBadgeHelpTextMessages,
    SubBadgeNamesMap,
    GenericCriteriaHelpTextMessages,
    GenericCriteriaMap
};
