import { Component, Input, OnInit } from '@angular/core';
import { CreditsForChallenge } from 'src/app/core/models/coding-challenges.model';

@Component({
  selector: 'geektrust-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class GeektrustCreditsComponent implements OnInit {
  @Input() creditsForChallenge: Partial<CreditsForChallenge> = {};

  constructor() { }

  ngOnInit(): void {
  }

}
