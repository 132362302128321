import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    // tslint:disable-next-line: component-selector
    selector: 'geektrust-switch',
    templateUrl: './switch.component.html',
    styleUrls: ['./switch.component.scss']
})
export class SwitchComponent implements OnInit {
    checked = false;
    @Input() inputFormControl: FormControl;

    @Output() toggle: EventEmitter<string> = new EventEmitter();
    constructor() { }

    ngOnInit(): void {
        this.checked = this.inputFormControl.value;
        this.inputFormControl.valueChanges.subscribe(value => {
            this.checked = value;
        });
    }

    onToggleSwitch(event) {
        this.checked = event.target.checked;
        this.toggle.emit(event.target.checked);
    }
}
