import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry, tap } from 'rxjs/operators';
import { adaptUserProfileValueForUI } from '../../adapters/user-profile/user-profile.adapter';
import { ToasterToggleService } from '../../components/toaster/toaster-toggle.service';
import { COMMON_URLS, GLOBAL_URLS, USER_PROFILE_DATA_URLS } from '../../constants/urls';
import { Stats } from '../../models/common.models';
import { GACategory } from '../../models/enums';
import { JobTabsCount, Response } from '../../models/jobs.model';
import { CreateReferrerPayload } from '../../models/referral.models';
import { Technologies } from '../../models/skills.model';
import { UserProfile } from '../../models/user-profile.model';
import { User } from '../../models/user.model';
import { serializeUserProfileDataForDB } from '../../serializers/user-profile/user-profile.serializer';
import { AuthService } from '../auth.service';
import { HttpService } from '../http.service';
import { FacebookPixelService } from '../user-activity-tracking/facebook-pixel.service';
import { GA_EVENTS } from '../user-activity-tracking/ga-events';
import { FA_EVENTS } from '../user-activity-tracking/facebook-pixel.service';
import { MIXPANEL_USER_PROPERTIES } from '../user-activity-tracking/mixpanel-events';
import { UserActivityTrackingService } from '../user-activity-tracking/user-activity-tracking.service';
import { getMixPanelPropertiesToBeTracked } from './user-profile.analytics';
import { MIXPANEL_FIELD_UPDATES } from './user-profile.constants';


@Injectable({ providedIn: 'root' })
export class UserProfileService {
  loggedInUser: Partial<User>;
  userProfile: BehaviorSubject<Partial<UserProfile>> = new BehaviorSubject(
    {}
  );
  userProfileObservable$ = this.userProfile.asObservable();

  redirectionFromMildOnboarding = '/dashboard';
  redirectAfterCollectingDetails = '/dashboard';
  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private toasterService: ToasterToggleService,
    private userActivityTrackingService: UserActivityTrackingService,
    private facebookPixelService: FacebookPixelService,
  ) {
    this.authService.currentUser.subscribe((value) => {
      if (value?.email && value?.email?.trim()) {
        this.loggedInUser = value;
        this.reloadUserProfile(value.email);
      } else {
        this.loggedInUser = {};
        this.userProfile.next({});
      }
    });
  }

  checkIfEmailAlreadyExists(emailAddress) {
    return this.httpService
      .get(`${USER_PROFILE_DATA_URLS.USER_VALIDATE_EMAIL}${emailAddress}`);
  }

  forgotPasswordSendEmail(userDetails) {
    const eventName = MIXPANEL_FIELD_UPDATES.MESSAGES.GENERAL_USER.FORGOT_PASSWORD;
    const properties = {
      [MIXPANEL_FIELD_UPDATES.UPDATE_KEYS.email]: userDetails?.email
    };
    return this.httpService.post(
      USER_PROFILE_DATA_URLS.USER_FORGOT_PASSWORD,
      userDetails
    ).pipe(
      tap({
        next: () => this.userActivityTrackingService.trackMixPanelEvent(eventName, properties),
        error: this.showToastMessageAndLogError
      })
    );
  }

  getNextStepsForUser() {
    return this.httpService.get(COMMON_URLS.NEXT_STEPS_URL.replace(
      '{{user_email}}',
      this.loggedInUser.email
    ));
  }
  resetPassword(userDetails, token: string, type: string) {
    let eventName;
    if (type === 'gtadmin') {
      eventName = MIXPANEL_FIELD_UPDATES.MESSAGES.GENERAL_USER.USER_VIA_GTADMIN_RESETS_THEIR_PASSWORD;
    } else {
      eventName = MIXPANEL_FIELD_UPDATES.MESSAGES.GENERAL_USER.USER_RESETS_THEIR_PASSWORD;
    }

    return this.httpService.post(
      USER_PROFILE_DATA_URLS.USER_RESET_PASSWORD + `${token}/${type}`,
      userDetails
    ).pipe(
      tap({
        next: () => this.userActivityTrackingService.trackMixPanelEvent(eventName),
        error: this.showToastMessageAndLogError
      })
    );
  }

  getTechnologiesForRole(
    userRole: string
  ): Observable<Array<Partial<Technologies>>> {
    return this.httpService.get(
      `${COMMON_URLS.GET_TECHNOLOGIES_FOR_USER_ROLE}${userRole}`
    );
  }

  getPreferredTechnologiesAndDomains(userRole: string) {
    const role = userRole || '';
    const email = this.authService.loggedInUser.email;
    return this.httpService.get(
      `${USER_PROFILE_DATA_URLS.USER_FETCH_PREFERENCES}?email=${encodeURIComponent(email)}&role=${role}`
    );
  }

  getUserProfile(userEmail: string) {
    return this.httpService.get(`${USER_PROFILE_DATA_URLS.GET_USER_PROFILE}${userEmail}`)
      .pipe(retry(2));
  }

  getUserCommunicationPreferences(userEmail = this.loggedInUser.email) {
    const encodedURI = encodeURIComponent(this.loggedInUser.email);
    return this.httpService.get(`${USER_PROFILE_DATA_URLS.COMMUNICATION_PREFERENCES_RETRIEVE_URL}?email=${encodedURI}`)
      .pipe(retry(2));
  }

  updateUserCommunicationPreferences(updateData = {}) {
    const encodedURI = encodeURIComponent(this.loggedInUser.email);
    return this.httpService
      .put(`${USER_PROFILE_DATA_URLS.COMMUNICATION_PREFERENCES_UPDATE_URL}?email=${encodedURI}`, updateData)
      .pipe(retry(2));
  }

  createUserCommunicationPreferences(createData = {}) {
    const encodedURI = encodeURIComponent(this.loggedInUser.email);
    return this.httpService
      .post(`${USER_PROFILE_DATA_URLS.COMMUNICATION_PREFERENCES_CREATE_URL}?email=${encodedURI}`, createData)
      .pipe(retry(2));
  }
  reloadUserProfile(userEmail = this.loggedInUser.email) {
    this.getUserProfile(userEmail).subscribe(
      (response: Partial<UserProfile>) => {
        this.userProfile.next(adaptUserProfileValueForUI(response));
        this.updateMixPanelUserProfile(response);
        this.userActivityTrackingService.setMixPanelUserProfileCharacteristics({
          'Job Search Status': response.jobSearchStatus,
        });
      }
    );
  }

  setPropertiesToBeSetOnce(profile: Partial<UserProfile>) {
    const properties: any = {};
    if (profile.membership !== 'NONE') {
      properties.isAMember = true;
      properties.membership = profile.membership;
    }
    if (Object.keys(properties).length) {
      this.userActivityTrackingService.setMixPanelUserProfileOnce(properties);
    }
  }
  updateMixPanelUserProfile(profile: Partial<UserProfile>) {
    const propertiesToBeSetInUserProfile: Partial<UserProfile> = {};
    const keysToBeTracked = [
      {
        keyName: 'primarySkill',
        trackedAs: MIXPANEL_USER_PROPERTIES.PRIMARY_ROLE
      },
      {
        keyName: 'employer',
        trackedAs: MIXPANEL_USER_PROPERTIES.CURRENT_EMPLOYER
      },
      {
        keyName: 'location',
        trackedAs: MIXPANEL_USER_PROPERTIES.CURRENT_LOCATION
      },
      {
        keyName: 'yrsOfExp',
        trackedAs: MIXPANEL_USER_PROPERTIES.YEARS_OF_EXPERIENCE
      },
      {
        keyName: 'userState',
        trackedAs: MIXPANEL_USER_PROPERTIES.USER_STATE
      },
      {
        keyName: 'codeSubmitted',
        trackedAs: MIXPANEL_USER_PROPERTIES.CODE_SUBMITTED
      },
      {
        keyName: 'lastActionTime',
        trackedAs: MIXPANEL_USER_PROPERTIES.LAST_ACTION_TIME
      },
      {
        keyName: 'created',
        trackedAs: MIXPANEL_USER_PROPERTIES.CREATED_DATE
      },
      {
        keyName: 'score',
        trackedAs: MIXPANEL_USER_PROPERTIES.CREATED_DATE
      }
    ];
    for (const property of keysToBeTracked) {
      if (profile[property['keyName']]) {
        propertiesToBeSetInUserProfile[property.trackedAs] = profile[property.keyName]
      }
    }
    propertiesToBeSetInUserProfile['Primary Tech'] = profile['tech']['knowWell']?.map(technology => technology.name);
    this.userActivityTrackingService.setMixPanelUserProfileCharacteristics(propertiesToBeSetInUserProfile);
  }
  updateUserProfile(updatedUserProfile: Partial<UserProfile>, eventName = '') {
    updatedUserProfile.email = this.loggedInUser.email;
    const eventsToTrack = this.getChangedProperties(updatedUserProfile, this.userProfile.getValue(), eventName);
    return this.httpService
      .post(
        USER_PROFILE_DATA_URLS.UPDATE_USER_BASIC_PROFILE,
        serializeUserProfileDataForDB(updatedUserProfile, this.userProfile.getValue())
      )
      .pipe(
        tap({
          next: this.reloadUserProfileOnUpdateSuccess(eventsToTrack),
          error: this.showToastMessageAndLogError,
        })
      );
  }

  submitResume(formData: FormData): Observable<any> {
    const eventName = 'User Uploaded Resume';
    return this.httpService
      .post(
        `${USER_PROFILE_DATA_URLS.UPLOAD_USER_RESUME}${this.loggedInUser.email}`,
        formData
      )
      .pipe(
        tap({
          next: (response) => {
            this.userProfile.value.resumeS3Path = response.s3Path;
            this.userActivityTrackingService.trackMixPanelEvent(eventName);
          },
          error: this.showToastMessageAndLogError,
        })
      );
  }

  deleteResume() {
    const eventName = 'User Deleted Resume';
    return this.httpService
      .delete(
        `${USER_PROFILE_DATA_URLS.DELETE_USER_RESUME}${this.loggedInUser.email}`
      )
      .pipe(
        tap({
          next: () => {
            this.userProfile.value.resumeS3Path = '';
            this.userActivityTrackingService.trackMixPanelEvent(eventName);
          },
          error: this.showToastMessageAndLogError,
        })
      );
  }

  downloadResume() {
    const eventName = 'User downloaded resume';
    return this.httpService.get(GLOBAL_URLS.RESUME_DOWNLOAD_URL.replace(
      '{{user_email}}',
      this.loggedInUser.email
    )).subscribe((success: any) => {
      this.userActivityTrackingService.trackMixPanelEvent(eventName);
      fetch(success.s3ResumeURL).then(resp => resp.arrayBuffer()).then(resp => {
        const file = new Blob([resp], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = this.userProfile.value.resumeS3Path?.split('/')[4];
        link.click();
        setTimeout(() => { URL.revokeObjectURL(fileURL); });
      });
    }, err => {
    });
  }

  addAttachments(formData: FormData) {
    const eventsToTrack = [{ eventName: 'User Uploaded Attachment' }];
    return this.httpService
      .post(
        `${String(USER_PROFILE_DATA_URLS.USER_UPLOAD_ATTACHMENT).replace(
          '{{user_email}}',
          this.loggedInUser.email
        )}`,
        formData
      )
      .pipe(
        tap({
          next: this.reloadUserProfileOnUpdateSuccess(eventsToTrack),
          error: this.showToastMessageAndLogError,
        })
      );
  }

  getReferralLink() {
    return this.httpService
      .get(
        `${String(USER_PROFILE_DATA_URLS.GET_REFERRAL_LINK).replace(
          '{{user_email}}',
          this.loggedInUser.email
        )}`,
      )
      .pipe(
        tap({
          next: this.reloadUserProfileOnUpdateSuccess([]),
          error: this.showToastMessageAndLogError,
        })
      );
  }

  createReferrer(referrerData: CreateReferrerPayload, emailCampaign = '') {
    const eventName = MIXPANEL_FIELD_UPDATES.MESSAGES.REFERRAL.INITIATED_REFERRAL;
    const properties = {
      Type: 'Invite Sent',
      'Email ID of Referees': referrerData.referrals.map(referral => referral.email),
      'Email Source(emailCampaign)': emailCampaign,
    };
    return this.httpService
      .post(
        `${String(USER_PROFILE_DATA_URLS.CREATE_REFERRER)}`,
        referrerData
      )
      .pipe(
        tap({
          next: () => this.userActivityTrackingService.trackMixPanelEvent(eventName, properties),
          error: this.showToastMessageAndLogError,
        })
      );
  }

  deleteAttachments(attachmentType: string) {
    const eventsToTrack = [{ eventName: 'User Deleted Attachment' }];
    return this.httpService
      .delete(
        `${USER_PROFILE_DATA_URLS.USER_DELETE_ATTACHMENT.replace(
          '{{user_email}}',
          this.loggedInUser.email
        )}/${attachmentType}`
      )
      .pipe(
        tap({
          next: this.reloadUserProfileOnUpdateSuccess(eventsToTrack),
          error: this.showToastMessageAndLogError,
        })
      );
  }

  uploadProfileImage(formData: FormData) {
    const eventsToTrack = [{ eventName: 'User Uploaded Profile Image' }];
    return this.httpService
      .post(
        `${String(USER_PROFILE_DATA_URLS.USER_UPLOAD_PROFILE_IMAGE).replace(
          '{{user_email}}',
          this.loggedInUser.email
        )}`,
        formData
      )
      .pipe(
        tap({
          next: this.reloadUserProfileOnUpdateSuccess(eventsToTrack),
          error: this.showToastMessageAndLogError,
        })
      );
  }

  showToastMessageAndLogError = (error) => {
    const errorMessage = error?.error?.message ? error.error.message : 'Oops! Something went wrong';
    this.showToastMessage(errorMessage, 'error', 5000);
  }

  showToastMessage = (title: string, state: string, dismiss: number = 5000) => {
    this.toasterService.openToast({
      title,
      state,
      dismiss,
    });
  }

  reloadUserProfileOnUpdateSuccess =
    (mixPanelEvents: Array<any>) =>
      (successResponse: Response) => {
        if (['success'].includes(successResponse.message)) {
          this.reloadUserProfile();
          mixPanelEvents.forEach(({ eventName, properties = {} }) => {
            this.userActivityTrackingService.trackMixPanelEvent(
              eventName,
              properties || {}
            );
          });
          this.userActivityTrackingService.trackGAEvent(GA_EVENTS.PROFILE_UPDATE, GACategory.UserProfile);
          this.facebookPixelService.track(FA_EVENTS.PROFILE_UPDATE, GACategory.UserProfile);
        }
      }

  getChangedProperties(newValues, oldValues, eventName = 'User Profile Update') {
    return [{
      eventName,
      properties: getMixPanelPropertiesToBeTracked(newValues, oldValues)
    }];
  }

  userWillUpdateProfileLater() {
    this.userActivityTrackingService.trackMixPanelEvent(MIXPANEL_FIELD_UPDATES.MESSAGES.GENERAL_USER.PROFILE_UPDATION_DEFERRED, {
      'Stage of Onboarding': 'Dropped off.'
    });
  }

  getStats(): Observable<Stats> {
    return this.httpService
      .get(COMMON_URLS.GET_STATS);
  }
  updateUserLastLaunchTime() {
    this.httpService.post(`${String(USER_PROFILE_DATA_URLS.UPDATE_LAST_LAUNCH_DATE).replace(
      '{{user_email}}',
      this.loggedInUser.email
    )}`, {}).subscribe(success => {
      this.reloadUserProfile();
    });
  }
  runMatcherOnDemand(): Observable<any> {
    const regNo = this.userProfile.value.regNo;
    const email = this.authService.loggedInUser.email;
    const url = `v2/user/${email}/${regNo}/matcher/run/`;
    return this.httpService.get<any>(url);
  }
}


