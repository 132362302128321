import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'geektrust-help-center',
  templateUrl: './help-center.component.html',
  styleUrls: ['./help-center.component.scss']
})
export class GeektrustHelpCenterComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
