import { UserProfile } from './../models/user-profile.model';
import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { UserProfileService } from '../services/user-profile/user-profile.service';
import { map } from 'rxjs/operators';

@Injectable()
export class BasicProfileGuard implements CanActivate {
  constructor(private router: Router, private userProfileService: UserProfileService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
       return this.userProfileService
      .getUserProfile(this.userProfileService.loggedInUser.email)
      .pipe(
        map((userProfile: UserProfile) => {
          if (userProfile.emptyProfile) {
            this.router.navigateByUrl('/collect-user-details');
            return false;
          }
          return true;
        })
      );
  }
}
