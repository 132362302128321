import { NgModule } from '@angular/core';
import { DifferenceBetweenDatesDirective } from './date-difference.directive';

@NgModule({
  declarations: [DifferenceBetweenDatesDirective],
  exports: [DifferenceBetweenDatesDirective],
  providers: [  ],
})
export class DateDifferenceModule {
}
