<ng-container *ngIf="AvailableImageFills.includes(badgeConfiguration?.fill)">
    <div class="badge-layers  {{modifierClass}}">
        <div class="text-overlay">
            {{badgeConfiguration.content}}
        </div>
        <div class="image-background">
            <img class="default-badge-sizes" src="../../../../assets/images/{{ImageURLMap[badgeConfiguration?.fill]}}.png"/>
        </div>
    </div>
</ng-container>


