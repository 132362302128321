import { CodingChallengesService } from './../../../../core/services/coding-challenges/coding-challenges.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserProfileService } from 'geektrust-core-services';

@Component({
  selector: 'app-coding-challenge-card',
  templateUrl: './coding-challenge-card.component.html',
  styleUrls: ['./coding-challenge-card.component.scss'],
})
export class CodingChallengeCardComponent implements OnInit {
  @Input() item: any = {};
  @Input() index = 1;
  @Input() theme = 'light';
  @Input() detailsPath = 'coding/detailed';

  developerImages = [
    'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/v2/problems/common/multiple-developers/1.svg',
    'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/v2/problems/common/multiple-developers/2.svg',
    'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/v2/problems/common/multiple-developers/3.svg',
    'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/v2/problems/common/multiple-developers/4.svg',
    'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/v2/problems/common/multiple-developers/5.svg',
    'https://geektrust.sgp1.cdn.digitaloceanspaces.com/assets/v2/problems/common/multiple-developers/6.svg',
  ];
  minimumSolvedCount = 30;
  showCompaniesWatching = true;
  constructor(
    private router: Router,
    private codingChallengesService: CodingChallengesService,
    private userProfileService: UserProfileService
  ) {
    this.userProfileService.userProfileObservable$.subscribe((response) => {
      if (response?.codeSubmitted) {
        this.showCompaniesWatching = false;
      }
    });
    this.developerImages = this.shuffleArray(this.developerImages).slice(0, 3);
  }

  shuffleArray(array): Array<string> {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  }

  ngOnInit(): void {
    this.item.badgeCount = this.item.badgesAndScoring.badges.tableData.reduce(
      (acc, curr) => curr.badges.length + acc,
      0
    );
  }

  goToChallenge({ slug }) {
    this.router.navigateByUrl(`/${this.detailsPath}/${slug}`);
  }
}
