import { Injectable } from '@angular/core';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { NavigationEnd } from '@angular/router';
import { seoSitemap } from './seo-sitemap';

@Injectable({
  providedIn: 'root',
})
export class MetatagsService {
  constructor(private title: Title, private meta: Meta) {}

  private commonTitle = 'Geektrust | Job Platform for Developers | Code is the new resume';
  private commonDescription = 'Get easy access to handpicked opportunities at top tech companies with the new resume. Showcase your coding skills and let great jobs at amazing tech companies find you.';
  private commonTopic = 'Developer Jobs';

  private updateTags(tags: Array<MetaDefinition | null>) {
    tags.forEach((tag) => {
      this.meta.updateTag(tag);
    });
  }

  setMetaTags(event: NavigationEnd) {
    const tag = seoSitemap.find((i) => event.urlAfterRedirects === i.customUrl);
    if (!tag) {
      this.updateSEOTags(this.commonTitle, this.commonDescription, this.commonTopic);
      return;
    }
    this.updateSEOTags(tag.title, tag.description, tag.topic);
  }

  updateSEOTags(title: string, description: string, topic: string) {
    this.title.setTitle(title || this.commonTitle);
    this.updateTags([
      { name: 'description', content: description || this.commonDescription },
      { name: 'topic', content: topic || this.commonTopic },
    ]);
  }

  updateTag(args) {
    this.meta.updateTag(args);
  }

  addTag(args) {
    this.meta.addTag(args, true);
  }
}
