<div class="app-l-detailed-wrapper">
  <div class="app-sample-overviewLeft">
    <div [ngClass]="{'isExperimenting': isExperimenting}" class="app-c-content--descrp">
      <div class="section" *ngFor="let section of problemStatement?.contentSections">
        <div *ngIf="section.isStatement" class="app-c-text">
          <h2 class="app-heading-h4" *ngIf="section.hasHeading">{{section.heading}}</h2>
          <p *ngIf="section.statement !== null && !section.hasHTMLContent">{{ section.statement }}</p>
          <p *ngIf="section.statement !== null && section.hasHTMLContent" [innerHTML]="section.statement | safeHtml">
          </p>
          <div *ngIf="section.hasOrderedList">
            <ol>
              <li *ngFor="let listItem of section.orderedList; let index = index;"><em>{{index + 1}}. </em>{{listItem}}
              </li>
            </ol>
          </div>
          <div class="app-c-text" *ngIf="section.hasHyperLink">
            <a class="app-btn app-btn-lg app-btn-secondary" target="_blank"
              [href]="section.hyperLink">{{section.hyperLinkText}}</a>
          </div>
          <div *ngIf="section.hasUnorderedList">
            <ul>
              <li *ngFor="let listItem of section.unOrderedList;"><em>&#8226;</em>{{listItem}}</li>
            </ul>
          </div>
          <div *ngIf="section.hasNestedList">
            <ol>
              <li *ngFor="let listItem of section.listItems; let index = index;"><em>{{index + 1}}.
                </em>{{listItem.text}}
                <ul *ngIf="listItem.children">
                  <li *ngFor="let childListItem of listItem.children">
                    <em>&#8226;.&nbsp;</em>{{childListItem.text}}
                  </li>
                </ul>
              </li>
            </ol>
          </div>
          <div *ngIf="section.hasSubStatements">

            <ng-container *ngFor="let subStatement of section.subStatements">
              <br />
              <div class="app-heading-h4" *ngIf="subStatement.subStatementHasHeading">
                {{subStatement.subStatementHeading}}</div>
              <div *ngIf="subStatement.subStatement">{{subStatement.subStatement}}</div>
              <pre *ngIf="subStatement.subStatementIsJSON">
                  <code>
                    {{subStatement.subStatementJSON | json }}
                  </code>
                </pre>
              <a target="_blank" *ngIf="subStatement.substatementHasHyperLink"
                href={{subStatement.substatementHyperLink}}>{{subStatement.substatementHyperLinkText}}</a>
              <div *ngIf="subStatement.subStatementExamples">
                <ng-container *ngFor="let example of subStatement.subStatementExamples.split('\n')">
                  <div [innerText]="example"></div>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
        <div *ngIf="section.isTable" class="app-c-table--dynamic">
          <table class="app-c-table-problem-statement">
            <tr *ngFor="let dataRow of section.tableData" class="app-c-border-cstm">
              <ng-container *ngIf="dataRow.isHeaderRow">
                <th *ngFor="let header of dataRow.data | keyvalue" width="">{{header.value}}</th>
              </ng-container>
              <ng-container *ngIf="dataRow.isDataRow">
                <td *ngFor="let data of dataRow.data | keyvalue">{{data.value}}</td>
              </ng-container>
              <ng-container *ngIf="dataRow.isSpacerRow">
                <td class="less-padding" colspan="5">&nbsp;</td>
              </ng-container>
            </tr>
          </table>
        </div>
        <div *ngIf="section.isImage" class="sample-challange-image">
          <img src={{section.imageSource}} alt="" width="auto" height="auto" class="challangesPic" />
        </div>
      </div>
    </div>
  </div>
</div>