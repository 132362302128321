import { RequirementType } from '../../models/enums';

export const MIXPANEL_FIELD_UPDATES = {
  MESSAGES: {
    GENERAL_USER: {
      NEW_USER_SIGN_UP: 'New User Signed Up',
      USER_RESETS_THEIR_PASSWORD: 'User resetted their password',
      USER_VIA_GTADMIN_RESETS_THEIR_PASSWORD: 'User(Via: GTADMIN) resetted their password ', // When user was registered using black app
      ONBOARDING: {
        UPDATED_JOB_SEARCH_STATUS: 'Job search status chosen',
      },
      PROFILE_UPDATION_DEFERRED: 'Profile updation deferred',
      FORGOT_PASSWORD: 'Forgot Password',
    },
    ACTIVE_USER: {
      ONBOARDING: {
        FILLED_BASIC_DETAILS: 'Basic profile filled',
        FILLED_BASIC_DETAILS_POPUP: 'Basic profile filled via popup',
        UPDATED_VITAL_INFORMATION: 'Vital stats filled',
        UPDATED_EDUCATION_AND_EMPLOYMENT_HISTORY: 'Employment history filled',
        UPDATED_JOB_PREFERENCES: 'Job preferences filled',
        UPDATED_WORK_EXPECTATIONS: 'Work expectations filled',
        FILLED_SALARY_DETAILS: 'Salary details filled',
        FILLED_ALMOST_DONE: 'Almost done filled',
      },
    },
    PASSIVE_USER: {
      ONBOARDING: {
        FILLED_BASIC_DETAILS: 'Basic profile filled',
        UPDATED_OBJECTIVE: 'Joining GT reason filled ',
        UPDATED_JOB_PREFERENCES: 'Job preferences filled',
        UPDATED_WORK_EXPECTATIONS: 'Work expectations filled',
        LOOKING_FOR_GUIDANCE: 'Guidance expectations filled',
        LOOKING_FOR_CHALLENGE: 'Coding challenge Expectations filled',
      },
    },
    USER_PROFILE: {
      UPDATED_ABOUT_SECTION_DETAILS: 'Updated about section details',
      UPDATED_BASIC_DETAILS: 'Updated basic details',
      UPDATED_VITAL_STATS: 'Updated Coordinates',
      UPDATED_WORK_AND_EXPERIENCE: 'Updated work and experience',
      UPDATED_PREFERENCES: 'Updated preferences',
      UPDATED_EXPECTATIONS: 'Updated Expectations',
      UPDATED_COMMUNICATION_PREFERENCES: 'Updated Communication Preferences',
    },
    REFERRAL: {
      INITIATED_REFERRAL: 'Initiated Referral',
      SHARED_JOB_DESCRIPTION_VIA_SOCIAL_MEDIA: 'Shared Job Description through Social Media',
    },
    LOGGED_IN: 'User logged In',
  },
  UPDATE_KEYS: {
    primarySkill: 'Primary Skill',
    email: 'E-mail ID',
    currentlyRemote: 'Currently Remote',
    yrsOfExperience: 'Years of Experience',
    jobSearchStatus: 'Job Search Status',
    password: 'Password',
    location: 'Location',
    phoneCountryCode: 'Phone Country Code',
    phoneNumber: 'Phone Number',
    workPreferences: 'Work Preferences',
    preferredPrimaryRole: 'Preferred Primary Role',
    domain: {
      wantToWork: 'Interested Domain',
    },
    preferredLocations: 'Preferred Locations',
    workLifeExpectations: 'Work Life Expectations',
    employmentTimeline: 'Employment Timeline',
    educationTimeline: 'Education Timeline',
    objective: 'Objective',
    tech: {
      knowWell: 'Primary Skills',
      additional: 'Additional Skills',
      wantToWork: 'Want to Work on Technologies',
    },
    urls: {
      linkedIn: 'LinkedIn URL',
      opensource: 'OpenSource URL',
      others: 'Other URLS',
    },
    salary: {
      currentVariable: 'Current Variable Salary',
      current: 'Current Salary',
      expected: 'Expected Salary',
      remarks: 'Remarks On Salary',
    },
    shareProfileWithCompanies: 'Share Profile With Companies',
    whatsAppOptedIn: 'Whatsapp Opted In',
    noticePeriodInfo: {
      earliestJoiningDate: 'Earliest Joining Date',
      noticePeriod: 'Notice Period',
      servingNoticePeriod: 'Serving Notice Period',
    },
    communicationChannel: {
      preferredChannel: 'Communication Channel - Preferred Channel',
      countryCode: 'Communication Channel - Country Code',
      mobileNumber: 'Communication Channel - Mobile Number',
    },
  },
};

export const PRIMARY_SKILLS = [
  {
    value: 'Backend Development',
    role: 'Backend Engineer',
    requirementType: RequirementType.BACKEND,
  },
  {
    value: 'Frontend Development',
    role: 'Frontend Developer',
    requirementType: RequirementType.FRONTEND,
  },
  {
    value: 'Full Stack Development',
    role: 'Fullstack Developer',
    requirementType: RequirementType.FULLSTACK,
  },
  {
    value: 'Mobile App Development',
    role: 'Mobile Developer',
    requirementType: RequirementType.MOBILE,
  },
  {
    value: 'DevOps',
    role: 'DevOps Engineer',
    requirementType: RequirementType.DEVOPS,
  },
  {
    value: 'UI/UX Design',
    role: 'UI/UX Designer',
    requirementType: RequirementType.DESIGN,
  },
  {
    value: 'Testing',
    role: 'QA Engineer',
    requirementType: RequirementType.QA,
  },
  {
    value: 'Product Management',
    role: 'Product Manager',
    requirementType: RequirementType.PRODUCT,
  },
  {
    value: 'Data Science',
    role: 'Data Scientist',
    requirementType: RequirementType.DATASCIENCE,
  },
  {
    value: 'Data Engineering',
    role: 'Data Engineer',
    requirementType: RequirementType.DATAENGINEER,
  },
];

export const WORK_PREFRENCES = {
  HYBRID: 'Hybrid',
  REMOTE: 'Remote',
  WORK_FROM_OFFICE: 'Work from office',
};
