export const GA_EVENTS = {
  SIGNUP: 'GT coreapp Sign Up via ',
  LOGIN: 'GT coreapp Log In',
  LOGOUT: 'GT coreapp Log Out',
  SOCIAL_SIGNUP: 'GT Google/Github Sign Up',
  SOCIAL_SIGNIN: 'GT Google/Github Sign In',
  PROFILE_UPDATE: 'Updated user profile',
  APPLY_JOB: 'Applied for job',
  EVENT_REGISTER: 'Registered for event',
  OFFLINE: 'Offline',
  COMPLETE_REGISTRATION: 'CompleteRegistration',
  LEAD: 'Lead',
  APPLY_ACCESS_CLICK: 'Apply for access click',
  APPLY_ACCESS_SUBMIT: 'Apply for access submit',
  APPLY_RECRUITMENT_CLICK: 'Apply for recruitment click',
  APPLY_RECRUITMENT_SUBMIT: 'Apply for recruitment submit',
  SEARCH_CANDIDATE_POOL: 'Search candidates',
  OFFERINGS_LEARN_MORE: 'Offerings learn more',
};
