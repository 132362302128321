import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, public router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next
      .handle(request)
      .pipe(catchError((err) => this.handlerError(err)));
  }
  handlerError(error: HttpErrorResponse) {
    /*
    if (error.status === 401) {
      // TODO: redirect to login only if user is in a page that requires login
      this.authService.logout().subscribe(() => {
        this.router.navigateByUrl('/');
      });
    }
    */
    return throwError(error);
  }
}
