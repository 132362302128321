import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserActivityTrackingService } from '../../services/user-activity-tracking/user-activity-tracking.service';
import { NudgePopupConfiguration } from './nudge-user.interfaces';
import { NudgeUserService } from './nudge-user.service';

@Component({
  selector: 'geektrust-nudge-user',
  templateUrl: './nudge-user.component.html',
  styleUrls: ['./nudge-user.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('trigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translatex(300px)' }),
        animate('250ms', style({ opacity: 1, transform: 'translateX(0px)' })),
      ]),
      transition(':leave', [animate('250ms', style({ opacity: 0, transform: 'translateX(300px)' }))]),
    ]),
  ],
})
export class NudgeUserComponent implements OnInit {
  popupConfiguration: Partial<NudgePopupConfiguration> = {
    showPopup: false
  };
  constructor(private nudgeUserService: NudgeUserService, private userActivityService: UserActivityTrackingService) {}

  ngOnInit(): void {
      this.nudgeUserService.nudgeProps.subscribe((popup: NudgePopupConfiguration) => {
      if (Object.keys(popup).length !== 0){
        this.popupConfiguration = popup;
      }
    });
  }
  closeToast() {
    this.nudgeUserService.showPopupGlobally = false;
    this.popupConfiguration.showPopup = false;
  }
  displayEvent(i, event){
    if (this.popupConfiguration.showPopup){
      event.element.classList.add('stay-animation');
      if (this.popupConfiguration.dismiss){
        setTimeout(() => {
           this.popupConfiguration.showPopup = false;
        }, this.popupConfiguration.dismiss);
      }
    }
  }

  goToTargetPage(popupConfiguration) {
    const targetURLMap = {
      'CODE-SUBMISSION': '/code-challenges?scrollToChallenges=true'
    };
    this.userActivityService.trackMixPanelEvent("User clicked on a nudge", popupConfiguration);
    window.open(new URL(targetURLMap[popupConfiguration?.target], window.location.origin).href, '_blank');
  }
}
