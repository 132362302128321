import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CandidateStatus, CompanyJob, CompanyPageInfo, ConnectedState, JobDetails, MatchScore, PitchActionEligibility, ReApplicationEligibility, WithdrawJob } from '../models/jobs.model';
import { AuthService } from './auth.service';
import { HttpService } from './http.service';
import { NEW_APPLICATION_FLOW_URLS } from 'geektrust-core-constants';

@Injectable({
  providedIn: 'root'
})
export class JobService {

  constructor(
    private httpService: HttpService,
    private authService: AuthService
  ) { }

  getJobsByRole(role: string): Observable<CompanyJob[]> {
    return this.httpService.get<CompanyJob[]>(
      `v2/jobs?role=${role}`
    );
  }

  getCompanyPageInfo(companyTitle: string): Observable<CompanyPageInfo> {
    const email = this.authService.loggedInUser?.email || '';
    return this.httpService.get<CompanyPageInfo>(
      `v2/company?companyTitle=${companyTitle}&email=${encodeURIComponent(email)}`
    );
  }

  getJobDetails(companyRegNo: number, jobRoleID: string): Observable<JobDetails> {
    const email = this.authService.loggedInUser.email;
    return this.httpService.get<JobDetails>(
      `v2/jobs/details?email=${encodeURIComponent(email)}&companyRegNo=${companyRegNo}&jobRoleId=${encodeURIComponent(jobRoleID)}`
    );
  }

  updateCandidateStatus(companyRegNo: number, jobRoleID: string, candidateStatus: CandidateStatus[]): Observable<any> {
    const email = this.authService.loggedInUser.email;
    const url = `v2/candidate/status?email=${encodeURIComponent(email)}&companyRegNo=${companyRegNo}&jobRoleId=${encodeURIComponent(jobRoleID)}`;
    return this.httpService.put<any>(url
      , candidateStatus
    );
  }

  withdrawApplication(withdrawJob: WithdrawJob): Observable<any> {
    const email = this.authService.loggedInUser.email;
    return this.httpService.put<any>(
      `v2/jobs/withdraw?email=${encodeURIComponent(email)}`, withdrawJob
    );
  }

  getCandidatePendingActions(companyRegNo: number, jobRoleID: string): Observable<string[]> {
    const email = this.authService.loggedInUser.email;
    return this.httpService.get<string[]>(
      `v2/jobs/actions/pending?email=${encodeURIComponent(email)}&companyRegNo=${companyRegNo}&jobRoleId=${encodeURIComponent(jobRoleID)}`
    );
  }

  getConnectionDetails(companyRegNo: number): Observable<ConnectedState> {
    const email = this.authService.loggedInUser.email;
    return this.httpService.get<ConnectedState>(
      `v2/connection?email=${encodeURIComponent(email)}&companyRegNo=${companyRegNo}`
    );
  }

  getJobMatchScore(companyRegNo: number, jobRoleID: string): Observable<MatchScore> {
    const email = this.authService.loggedInUser.email;
    return this.httpService.get<MatchScore>(
      `v2/jobs/matchScore?email=${encodeURIComponent(email)}&companyRegNo=${companyRegNo}&jobRoleId=${encodeURIComponent(jobRoleID)}`
    );
  }
  getPitchActionEligibility(companyRegNo: number, jobRoleID: string): Observable<PitchActionEligibility> {
    const email = this.authService.loggedInUser.email;
    return this.httpService.get<PitchActionEligibility>(
      `v2/pitchActionEligibility?email=${encodeURIComponent(email)}&companyRegNo=${companyRegNo}&jobRoleId=${encodeURIComponent(jobRoleID)}`
    );
  }

  getReApplicationEligibility(companyRegNo: number, jobRoleID: string): Observable<ReApplicationEligibility> {
    const email = this.authService.loggedInUser.email;
    return this.httpService.get<ReApplicationEligibility>(
      `v2/reApplicationEligibility?email=${encodeURIComponent(email)}&companyRegNo=${companyRegNo}&jobRoleId=${encodeURIComponent(jobRoleID)}`
    );
  }

  upsertApplication(application: any): Observable<any> {
    const email = this.authService.loggedInUser.email;
    const url = NEW_APPLICATION_FLOW_URLS.UPSERT_APLLICATION.replace('{{user_email}}', email);
    return this.httpService.put<any>(url
      , application
    );
  }
}
