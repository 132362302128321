import { Component, Input, OnInit } from '@angular/core';
import { EvaluationReport } from 'src/app/core/models/common.models';

@Component({
  selector: 'app-no-verbosity-report',
  templateUrl: './no-verbosity-report.component.html',
  styleUrls: ['./no-verbosity-report.component.scss']
})
export class NoVerbosityReportComponent implements OnInit {
  @Input() report: EvaluationReport;

  constructor() { }

  ngOnInit(): void {
  }

}
