import { Component, OnInit } from '@angular/core';
import { NgxMasonryOptions } from 'ngx-masonry';

@Component({
  selector: 'app-card-skeleton-loader',
  templateUrl: './card-skeleton-loader.component.html',
  styleUrls: ['./card-skeleton-loader.component.scss'],
})
export class CardSkeletonLoaderComponent implements OnInit {
  constructor() {}

  masonryOptions: NgxMasonryOptions = {
    gutter: 0,
    horizontalOrder: true,
  };

  ngOnInit(): void {}
}
