import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-svg-icon',
  templateUrl: `svg-icon.component.html`,
  styleUrls: ['./svg-icon.component.scss'],
})
export class SvgIconComponent implements OnInit {
  @Input() name: string;

  @Input() className: string;

  @Input() focusable: string;

  @Input() ariaHidden: string;

  @Input() title: string;

  @Output() onClicked = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onClickedSvgIcon(event) {
    this.onClicked.emit(event);
  }
}
