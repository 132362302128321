import { UserActivityTrackingService } from 'geektrust-core-services';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProblemStatement } from 'src/app/core/models/coding-challenges.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { SidePanelToggleService } from '../../../../core/interactions/sidepanel-toggle/sidepanel-toggle.service';

@Component({
  selector: 'geektrust-problem-statement',
  templateUrl: './problem-statement.component.html',
  styleUrls: ['./problem-statement.component.scss']
})
export class GeektrustProblemStatementComponent implements OnInit {
  @Input() problemStatement: Partial<ProblemStatement> = {};
  @Input() allowDownloadStarterKit = true;
  @Input() detailedChallenge: Partial<any> = {};
  constructor() {
  }

  ngOnInit(): void {
  }
}
