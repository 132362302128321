import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-code-portfolio-badge',
  templateUrl: './code-portfolio-badge.component.html',
  styleUrls: ['./code-portfolio-badge.component.scss'],
})
export class CodePortfolioBadgeComponent implements OnInit {
  @Input() badgeConfiguration = {
    content: '00',
    fill: 'gold'
  };
  @Input() modifierClass = 'default-badge-sizes';
 
  ImageURLMap = {
    gold: 'GoldAdvanceBadge',
    silver: 'SilverIntermediateBadge',
    bronze: 'BronzeBasicBadge',
    'green-unearned': 'UnearnedBadge'
  };

  AvailableImageFills = Object.keys(this.ImageURLMap);
  constructor() { }

  ngOnInit(): void {
  }
}
