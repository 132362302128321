import { Component, Input, OnInit } from '@angular/core';
import { SampleInputOutput } from 'src/app/core/models/coding-challenges.model';

@Component({
  selector: 'geektrust-experimental-sample-io',
  templateUrl: './experimental-sample-io.component.html',
  styleUrls: ['./experimental-sample-io.component.scss']
})
export class ExperimentalSampleIoComponent implements OnInit {
  @Input() sampleInputOutput: Partial<SampleInputOutput> = {};
  @Input() logoURL = '';
  constructor() { }

  ngOnInit(): void {
  }

}
