import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToasterToggleService, UserProfileService } from 'geektrust-core-services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ModalDisplayService {
  showRedirectionModal$: ReplaySubject<boolean> = new ReplaySubject(1);
  showRedirectionModalObservable$ = this.showRedirectionModal$.asObservable();
  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private toasterService: ToasterToggleService,
    private modalService: BsModalService
  ) { }
  toggleRedirectionModal(displayModal = true) {
    this.showRedirectionModal$.next(displayModal);
    if (displayModal) {
      return this.runMatcher();
    }
  }
  runMatcher() {
    return this.userProfileService.runMatcherOnDemand()
      .pipe(
        tap({
          next: () => {
            setTimeout(() => {
              if (this.modalService.getModalsCount()) {
                this.toggleRedirectionModal(false);
                if (!['/job-search', '/dashboard'].some(route => this.router.url.startsWith(route))) {
                  this.router.navigateByUrl('/dashboard');
                }
              }
            }, 45000);
          },
          error: (error) => {
            if (error) {
              if (!['/job-search', '/dashboard'].some(route => this.router.url.startsWith(route))) {  
                this.toggleRedirectionModal(false);      
                this.router.navigateByUrl('/dashboard');
              }
              else {
                setTimeout(()=>{
                  this.toggleRedirectionModal(false);
                  this.toasterService.openToast({
                    title: `We're facing a technical glitch. Please try again in some time.`,
                    state: 'error',
                    dismiss: 3000,
                  });
                },3000)
             
              }
            }
          }
        })
      );
  }
}
