export default {
    cookie: {
        domain: window.location.host === 'localhost:2021' ? 'localhost' : window.location.host
    },
    position: 'bottom-right',
    theme: 'classic',
    palette: {
        popup: {
            background: '#47546c',
            text: '#f5f9ff',
            link: '#ffffff'
        },
        button: {
            background: '#fba201',
            text: '#13253a',
            border: 'transparent'
        },
        highlight: {
            background: '#5b6880',
            text: '#ffffff',
            border: 'transparent'
        }
    },
    type: 'opt-in',
    content: {
        message: 'We use cookies to ensure you get the best experience on our website.',
        dismiss: 'Got it!',
        deny: 'Refuse cookies',
        link: 'Learn more',
        href: window.location.protocol + '//' + window.location.host + '/legal/privacy',
        policy: 'Cookie Policy'
    }
};
