import { Component, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import {
  GAService,
  ToasterToggleService,
  UserActivityTrackingService,
  UserProfileService,
} from 'geektrust-core-services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie';
import { fromPairs } from 'ramda';
import { Subject, timer } from 'rxjs';
import { skipWhile, take, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as defaultLanguage from './../assets/i18n/en.json';
import { NudgeUserService } from './core/components/nudge-user/nudge-user.service';
import { CookieConsentChoice, PageSignupViaMapping, SocketIOEvents } from './core/models/enums';
import { UserProfile } from './core/models/user-profile.model';
import { AuthService } from './core/services/auth.service';
import { MetatagsService } from './core/services/metatags/metatags.service';
import { SocketService } from './core/services/socket.service';
import { FacebookPixelService } from './core/services/user-activity-tracking/facebook-pixel.service';
import { MouseflowService } from './core/services/user-activity-tracking/mouseflow.service';
import { ModalDisplayService } from './features/signup/shared/services/modal-display.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Geektrust-UI';
  showPrompt = true;
  showHead: boolean;
  @ViewChild('dashboardRedirectionModal') dashboardRedirectionModal: TemplateRef<any>;
  timeOutReferences: Array<NodeJS.Timeout> = [];
  applicationNeedsToBeReloaded = false;
  private ngUnsubscribe$: Subject<undefined> = new Subject();
  matcherIsRunning = false;
  currentUrl: string;
  get cookieConsent() {
    return this.cookieService.hasKey('cookieconsent_status')
      ? this.cookieService.get('cookieconsent_status')
      : undefined;
  }
  userBasicDetailsForm: FormGroup;

  get shareProfileWithCompanies() {
    return this.userBasicDetailsForm.get('shareProfileWithCompanies');
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private authService: AuthService,
    private translationService: TranslateService,
    private serviceWorker: SwUpdate,
    private facebookPixelService: FacebookPixelService,
    private userActivityService: UserActivityTrackingService,
    private modalDisplayService: ModalDisplayService,
    private modalService: BsModalService,
    private metatagsService: MetatagsService,
    private nudgeUsersService: NudgeUserService,
    private userProfileService: UserProfileService,
    private mouseflowService: MouseflowService,
    private gaService: GAService,
    private formBuilder: FormBuilder,
    private socketService: SocketService,
    private toasterService: ToasterToggleService
  ) {
    if (this.shouldTrack()) {
      this.mouseflowService.init();
      this.gaService.setupGoogleTagManger();
      // this.facebookPixelService.init();
    }
    // Putting refToken and emailCampaign in cookies
    this.route.queryParams.subscribe((queryParams) => {
      const inCookies = ['emailCampaign', 'refToken'];
      for (const paramName in queryParams) {
        if (inCookies.includes(paramName)) {
          this.cookieService.put(paramName, queryParams[paramName]);
        }
      }
    });
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        this.setSignupVia(event.url);
        if (environment.production) {
          this.serviceWorker.checkForUpdate();
        }
      } else if (event instanceof NavigationEnd) {
        this.campaignParams(event.url);
        this.currentUrl = event.url;
        this.metatagsService.setMetaTags(event);
        if (this.applicationNeedsToBeReloaded) {
          window.location.reload();
        }
      }
    });
    this.setSignupVia(window.location.pathname);
    this.translationService.setTranslation('en', defaultLanguage);
    this.translationService.setDefaultLang('en');
    this.translationService.use('en');
    this.serviceWorker.available.subscribe((response) => {
      this.serviceWorker.activateUpdate();
      this.applicationNeedsToBeReloaded = true;
    });
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      return false;
    });
    this.showModalAndRedirectToDashboard();
    this.userProfileService.userProfileObservable$
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((userProfile: Partial<UserProfile>) => {
        if (userProfile.codeSubmitted !== true) {
          this.nudgeUsersService.startNudgingUser();
        }
      });
  }

  ngOnInit() {
    this.initializeAllRequiredForms();
    this.populateAnyExistingValues();
    this.socketService
      .listen(SocketIOEvents.CompanyConnectionModified)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((response: any) => {
        this.matcherIsRunning = false;
        if (this.modalService.getModalsCount()) {
          if (!['/job-search', '/dashboard'].some((route) => this.currentUrl.startsWith(route))) {
            this.router.navigateByUrl('/dashboard');
          }
          this.modalDisplayService.toggleRedirectionModal(false);
        } else {
          this.modalDisplayService.showRedirectionModalObservable$
            .pipe(skipWhile(() => this.modalService.getModalsCount() > 0))
            .subscribe((value) => {
              this.showPrompt = !response.shareProfileWithCompanies;
              console.log({ showPrompt: this.showPrompt });
            });
        }
      });
  }

  isExponentialPage() {
    return window.location.pathname === '/xponential';
  }

  shouldTrack() {
    return (
      this.cookieConsent === CookieConsentChoice.Allow ||
      (!this.isExponentialPage() && this.cookieConsent !== CookieConsentChoice.Deny)
    );
  }

  campaignParams(url) {
    const campaignKeywords = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term'];
    const queryParams = this.route.snapshot.queryParams;
    if (campaignKeywords.some((key) => queryParams.hasOwnProperty(key))) {
      const firstTouchParams = fromPairs(
        campaignKeywords.map((keyword) => {
          return [[`First Touch - ${keyword}`], decodeURIComponent(queryParams[keyword])];
        })
      );
      const lastTouchParams = fromPairs(
        campaignKeywords.map((keyword) => {
          return [[`Last Touch - ${keyword}`], decodeURIComponent(queryParams[keyword])];
        })
      );
      this.userActivityService.setMixPanelUserProfileOnce(firstTouchParams);
      this.userActivityService.setMixPanelUserProfileCharacteristics(lastTouchParams);
      this.userActivityService.registerMixPanelUserProfile(lastTouchParams);
    }
  }

  private setSignupVia(url: string) {
    const via = PageSignupViaMapping.find((mapping) => url.startsWith(mapping.url))?.trackBy;
    if (via) {
      this.authService.signupVia = via;
    }
  }

  showModalAndRedirectToDashboard() {
    this.modalDisplayService.showRedirectionModalObservable$.subscribe((showModal) => {
      if (showModal && this.modalService.getModalsCount() === 0) {
        this.openModal(this.dashboardRedirectionModal);
      } else if (!showModal) {
        this.modalService.hide();
      }
    });
  }
  openModal(template: TemplateRef<any>) {
    this.modalService.show(template, {
      class: 'modal-dialog-centered app-l-delete-modal modal-lg redirection-modal-container modal-center',
      backdrop: 'static',
      keyboard: false,
    });
  }

  showsCompaniesWatching(url) {
    return ['/dashboard', '/coding/challenges', '/code-challenges', '/challenge', '/coding/detailed'].some((u) =>
      url.startsWith(u)
    );
  }

  stopTimeout() {
    this.timeOutReferences.forEach((timeoutReference) => clearTimeout(timeoutReference));
    this.timeOutReferences = [];
  }

  onTooglePublicPreference(event) {
    this.shareProfileWithCompanies.patchValue(event);
    this.userProfileService.updateUserProfile(this.userBasicDetailsForm.value).subscribe();
  }

  initializeAllRequiredForms() {
    this.userBasicDetailsForm = this.formBuilder.group({
      shareProfileWithCompanies: [null],
    });
  }
  populateAnyExistingValues() {
    this.userProfileService.userProfileObservable$.subscribe((response: Partial<UserProfile>) => {
      if (!response?.email) {
        return;
      }
      if (!this.modalService.getModalsCount()) {
        this.showPrompt = !response.shareProfileWithCompanies;
      } else {
        this.modalDisplayService.showRedirectionModalObservable$
          .pipe(skipWhile(() => this.modalService.getModalsCount() > 0))
          .subscribe((value) => {
            this.showPrompt = !response.shareProfileWithCompanies;
          });
      }
      this.shareProfileWithCompanies.patchValue(response.shareProfileWithCompanies);
    });
  }

  @HostListener('window:beforeprint')
  onBeforePrint() {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.stopTimeout();
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
