import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { map } from 'rxjs/operators';
import { UserProfile } from 'src/app/core/models/user-profile.model';
import { UserProfileService } from 'src/app/core/services/user-profile/user-profile.service';
import { checkIfBasicDetailsExist } from 'src/app/core/utilities';

@Injectable()
export class ProfileNotEmptyCheck implements CanActivate {
  constructor(private router: Router, private userProfileService: UserProfileService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.userProfileService
      .getUserProfile(this.userProfileService.loggedInUser.email)
      .pipe(
        map((userProfile: Partial<UserProfile>) => {
          if (checkIfBasicDetailsExist(userProfile)) { return true; }
          this.router.navigateByUrl('tellus/1');
          return false;
        }
      ));
  }
}
