import { AUTH_URLS } from './authorization.urls';
import { GLOBAL_URLS } from './global.urls';
import { USER_PROBLEM_URLS } from './problems.url';
import { PROFILE_URLS, USER_PROFILE_DATA_URLS } from './profile.urls';

const COMMON_URLS = Object.freeze({
  GET_TECHNOLOGIES_FOR_USER_ROLE: `v2/technologies/role/`, // GET, Dynamic Attribute - User Role, "v2/technologies/role/{{user_role}}"
  GET_ALL_EMPLOYERS: `v2/companies/employers/all`, // GET, No Dynamic Attributes
  GET_STATS: `v2/stats`, // GET, No Dynamic Attributes
  TRACK_USER_EVENT_URL: `events/useraction/track`, // POST
  CANDIDATE_ENGAGEMENT_URL: `v2/candidateEngagement`, // PUT
  NEXT_STEPS_URL: 'v2/{{user_email}}/dashboard/banner/steps'
});

export { AUTH_URLS, PROFILE_URLS, USER_PROFILE_DATA_URLS, USER_PROBLEM_URLS, COMMON_URLS, GLOBAL_URLS };
